<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="12px"
  class="container"
>
  <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
  <span>{{ data.title }}</span>
  <img src="/assets/img/lock.png" alt="" width="58px" />
</div>

<ng-template #closeBtn>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="end">
    <button (click)="componentMatDialogRef.close()" mat-button>
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
</ng-template>
