<span
  *ngIf="label"
  class="label bold"
  [class.participant]="theme === 'participant'"
>
  {{ label }}
</span>

<mat-form-field
  appearance="fill"
  class="select-form"
  [style.width.px]="width"
  [class.default]="theme === 'default'"
  [class.participant]="theme === 'participant'"
>
  <mat-select
    #select
    [disabled]="disabled"
    [placeholder]="placeholder"
    [value]="value"
    [panelClass]="{ participant: theme === 'participant' }"
    [disableOptionCentering]="theme === 'participant'"
    (selectionChange)="onSelectionChanged($event.value)"
  >
    <mat-select-trigger
      *ngIf="optionsMap[value]?.label as label"
      fxLayoutAlign="start center"
    >
      <ng-container *ngIf="iconPosition === 'after'">
        <ng-container *ngTemplateOutlet="labelTemplate; context: { label }">
        </ng-container>

        <ng-container
          *ngTemplateOutlet="
            iconTemplate;
            context: {
              svgIcon: optionsMap[value]?.svgIcon,
              materialIcon: optionsMap[value]?.materialIcon,
              iconTooltip: optionsMap[value]?.iconTooltip
            }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="iconPosition === 'before'">
        <ng-container
          *ngTemplateOutlet="
            iconTemplate;
            context: {
              svgIcon: optionsMap[value]?.svgIcon,
              materialIcon: optionsMap[value]?.materialIcon,
              iconTooltip: optionsMap[value]?.iconTooltip
            }
          "
        ></ng-container>
        <ng-container *ngTemplateOutlet="labelTemplate; context: { label }">
        </ng-container>
      </ng-container>
    </mat-select-trigger>
    <mat-option *ngIf="showArrowUp && theme === 'participant'" class="arrow-select">
      <mat-icon [svgIcon]="'arrow-left'" style="rotate: 90deg"></mat-icon>
    </mat-option>
    <mat-option
      *ngFor="let option of options; trackBy: trackBy"
      [value]="option?.value"
    >
      <ng-container *ngIf="iconPosition === 'after'">
        <ng-container
          *ngTemplateOutlet="labelTemplate; context: { label: option?.label }"
        >
        </ng-container>
        <ng-container
          *ngTemplateOutlet="
            iconTemplate;
            context: {
              svgIcon: option?.svgIcon,
              materialIcon: option?.materialIcon,
              iconTooltip: option?.iconTooltip
            }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="iconPosition === 'before'">
        <ng-container
          *ngTemplateOutlet="
            iconTemplate;
            context: {
              svgIcon: option?.svgIcon,
              materialIcon: option?.materialIcon,
              iconTooltip: option?.iconTooltip
            }
          "
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="labelTemplate; context: { label: option?.label }"
        >
        </ng-container>
      </ng-container>
    </mat-option>
    <mat-option
      *ngIf="showArrowDown && theme === 'participant'"
      class="arrow-select"
      style="bottom: -8px; margin-bottom: -8px"
    >
      <mat-icon
        style="rotate: -90deg" [svgIcon]="'arrow-left'"
      ></mat-icon>
    </mat-option>
  </mat-select>
</mat-form-field>

<ng-template #labelTemplate let-label="label">
  <span class="option-label">
    {{ label }}
  </span>
</ng-template>

<ng-template
  #iconTemplate
  let-svgIcon="svgIcon"
  let-materialIcon="materialIcon"
  let-iconTooltip="iconTooltip"
>
  <mat-icon
    *ngIf="svgIcon"
    [svgIcon]="svgIcon"
    [matTooltip]="iconTooltip"
    [matTooltipPosition]="'above'"
  ></mat-icon>
  <mat-icon
    *ngIf="materialIcon"
    [matTooltip]="iconTooltip"
    [matTooltipPosition]="'above'"
  >
    {{ materialIcon }}
  </mat-icon>
</ng-template>
