import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { NumberInputDialogConfig } from "@tellsy/common/services/dialog.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'tellsy-number-edit-dialog',
  templateUrl: './number-edit-dialog.component.html',
  styleUrls: ['./number-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberEditDialogComponent {
  value: number
  title: string
  submitBtnName: string
  errMessage: string
  isValid: boolean = true
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: NumberInputDialogConfig,
    private dialogRef: MatDialogRef<NumberEditDialogComponent>,
  ) {
    this.title = this.data.title;
    this.submitBtnName = this.data.submitBtnName;
    this.errMessage = this.data.errMessage;
  }

  submit() {
    this.dialogRef.close(this.value);
  }

  close() {
    this.dialogRef.close('cancel');
  }

  setQuantity($event: number) {
    this.value = $event
    this.isValid = this.value >= 1 && this.value <= 15;
  }
}