import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";

export interface ContentClosedData {
  title: string;
}

@Component({
  selector: "app-content-closed-dialog",
  templateUrl: "./content-closed-dialog.component.html",
  styleUrls: ["./content-closed-dialog.component.scss"],
})
export class ContentClosedDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ContentClosedData,
    private componentMatDialogRef: MatDialogRef<ContentClosedDialogComponent>,
  ) {}

  ngOnInit() {}
}
