import { HttpClient, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  fileUpload(fileItem: File, apiUrl: string, extraData?: any): Observable<any> {
    const formData: FormData = new FormData();

    formData.append("file", fileItem, fileItem.name);
    if (extraData) {
      for (const key in extraData) {
        if (extraData.hasOwnProperty(key)) {
          formData.append(key, extraData[key]);
        }
      }
    }

    const req = new HttpRequest("POST", apiUrl, formData, {
      reportProgress: true,
    });
    return this.http.request(req).pipe(
      filter((resp: HttpResponse<any>) => resp.body),
      map((r) => r.body),
    );
  }
}
