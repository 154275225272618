<label
  fxLayoutAlign="start center"
  fxLayoutGap="5px"
  [fxLayout]="labelPosition === 'left' ? 'row' : 'row-reverse'"
>
  <span class="label">
    <ng-content></ng-content>
  </span>
  <input
    #input
    type="number"
    [class.arrows-hidden]="hideArrows"
    [class.error]="error"
    [style.width.px]="width"
    [style.height.px]="height"
    [placeholder]="placeholder ?? ''"
    [attr.min]="min"
    [attr.max]="max"
    [disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="onInput($event)"
    (click)="onClick($event.target)"
    (focus)="focus.emit()"
    (blur)="blur.emit()"
    (keyup.enter)="submitted.emit(value)"
  />
</label>
