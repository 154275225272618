import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AutoFocusDirective } from "./autofocus/auto-focus.directive";
import { ColorThemeApplierDirective } from "./color-theme-applier/color-theme-applier.directive";
import { DropzoneDirective } from "./dropzone/dropzone.directive";
import { FlowButtonDirective } from "./flow-button/flow-button.directive";
import { InfiniteScrollDirective } from "./infinite-scroll/infinite-scroll.directive";
import { LockDirectivesModule } from "./lock-if-not-captain-directive/lock-directives.module";
import { NoSpaceDirectiveDirective } from "./no-space-directive/no-space-directive.directive";
import { SelectFileDirective } from "./select-files/select-files.directive";
import { SwapToTemplateWhenNotVisibleDirective } from "./swap-to-template-when-not-visible/swap-to-template-when-not-visible.directive";
import { ViewportVisibilityDirective } from "./viewport-visibility/viewport-visibility.directive";
import { WrapPretextDirective } from "./wrap-pretext/wrap-pretext.directive";

@NgModule({
  declarations: [
    AutoFocusDirective,
    ColorThemeApplierDirective,
    DropzoneDirective,
    SelectFileDirective,
    WrapPretextDirective,
    SwapToTemplateWhenNotVisibleDirective,
    ViewportVisibilityDirective,
    FlowButtonDirective,
    InfiniteScrollDirective,
    NoSpaceDirectiveDirective,
  ],
  imports: [CommonModule, LockDirectivesModule],
  exports: [
    AutoFocusDirective,
    ColorThemeApplierDirective,
    DropzoneDirective,
    LockDirectivesModule,
    SelectFileDirective,
    WrapPretextDirective,
    SwapToTemplateWhenNotVisibleDirective,
    ViewportVisibilityDirective,
    FlowButtonDirective,
    InfiniteScrollDirective,
    NoSpaceDirectiveDirective,
  ],
})
export class DirectivesModule {}
