import { Event } from "@app/store/event/model";

export function sortEventsByDateCreated(
  events: Event[],
  sortType: "ASC" | "DESC" = "DESC",
): Event[] {
  return events.sort(eventsCreationDateComparator(sortType));
}

export function eventsCreationDateComparator(type: "ASC" | "DESC" = "DESC") {
  return function (a: Event, b: Event): number {
    return type === "DESC"
      ? new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
      : new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime();
  };
}
