import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { AuthFacade, AuthStorage } from "@tellsy/auth-facade";
import { ParticipantAuthActions } from "libs/auth-facade/src/lib/store/participant-auth.actions";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { EventsFacade } from "@app/store/events/facades";

@Injectable({
  providedIn: "root"
})
export class NavigateToEventByQueryParamsGuard {
  constructor(
    private authFacade: AuthFacade,
    private eventsFacade: EventsFacade,
    private authStorage: AuthStorage,
    private store: Store
  ) {
  }

  canActivate({
                queryParams
              }: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const eventCode = queryParams.eventCode;
    const username = queryParams.username;
    const subEventId = queryParams.subEventId;

    if (eventCode && username) {
      this.authStorage.removeParticipantCredentialsForEvent(
        queryParams.eventCode
      );
      this.authStorage.removeParticipantCurrentCredentials();

      this.authFacade.loginParticipantAndNavigate(
        queryParams.eventCode,
        queryParams.username,
        queryParams.subEventId
      );
    }

    if (eventCode) {
      this.eventsFacade.getEventNameByEventCode(eventCode)
        .subscribe((response) => this.store.dispatch(
            ParticipantAuthActions.saveInStoreEventName({ eventName: response.eventName })
          )
        );
      this.store.dispatch(
        ParticipantAuthActions.saveInStoreFieldValues({
          eventCode,
          username,
          subEventId,
          navigatedByLink: true,
          navigatedByLinkWithLogin: !!username
        })
      );
    }

    return this.authFacade.getPending$().pipe(
      filter((pending) => !pending),
      map(() => true)
    );
  }
}
