export const supportedImagesFormats: string[] = [
  ".png",
  ".gif",
  ".jpg",
  ".jpeg",
  ".svg",
];

export function isSupportedImageFormat(fileName: string): boolean {
  if (!fileName) return false;

  return endsWithSuffixes(supportedImagesFormats, fileName.toLowerCase());
}

function endsWithSuffixes(suffixes: string[], str: string): boolean {
  return suffixes.some((suffix) => str.endsWith(suffix));
}

export function numbersArrayToStringArray(
  array: (number | string)[],
): string[] {
  return array?.map((v: number | string) => `${v}`);
}

export function isArrayValuesUnique(array: number[] | string[]): boolean {
  return new Set(numbersArrayToStringArray(array)).size === array?.length;
}

/**
 * Check if a mime type matches the set given in accept
 *
 * @param type the mime type to test, ex image/png
 * @param accept the mime types to accept, ex audio/*,video/*,image/png
 * @returns true if the mime is accepted, false otherwise
 */
export function verifyFileTypeAccept(type: string, accept: string): boolean {
  const allowed = accept.split(",").map((x) => x.trim());
  return allowed.includes(type) || allowed.includes(type.split("/")[0] + "/*");
}
