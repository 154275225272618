<div mat-dialog-content fxLayout="column" fxLayoutAlign="start center">
  <!--close-->
  <button tabindex="-1" mat-dialog-close mat-icon-button class="close-button">
    <mat-icon color="primary">close</mat-icon>
  </button>

  <p class="bold title uppercase">{{ title }}</p>

  <p class="subtitle" *ngIf="subtitle">{{ subtitle }}</p>

  <tellsy-text-editor
    #input
    matInput
    autocomplete="off"
    spellcheck="false"
    [placeholder]="placeholder"
    [attr.maxLength]="maxLength ? maxLength : ''"
    [(ngModel)]="content"
  >
  </tellsy-text-editor>
  <div fxLayoutAlign="end">
    <span *ngIf="maxLength" class="character-counter">
      {{ (input.value?.length || 0) + "/" + maxLength }}
    </span>
  </div>
</div>
<mat-dialog-actions>
  <tellsy-button
    class="btn"
    theme="light"
    [disabled]="!content.length"
    (clicked)="submit()"
  >
    {{ submitBtnText }}
  </tellsy-button>
</mat-dialog-actions>
