import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, ValueProvider } from "@angular/core";
import { API } from "@app/configs/api.config";
import { ApiResponse } from "@app/models/common.models";
import { AuthSuccessResponse, EventSecurityConfig, User } from "@app/store/auth/model";
import {
  FullCreationModerator,
  Moderator,
  ModeratorParamsChangeRequest,
  RegisterInfo,
  Tariff
} from "@app/store/moderator/models/moderator-model";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(private http: HttpClient) {
  }

  loginModerator(
    username: string,
    password: string
  ): Observable<AuthSuccessResponse> {
    const body = new HttpParams()
      .set("username", username)
      .set("password", password)
      .set("grant_type", "password");

    return this.sendLoginRequest(body, "moderator");
  }

  loginParticipant(
    eventCode: string,
    username?: string
  ): Observable<AuthSuccessResponse> {
    let body = new HttpParams()
      .set("eventCode", eventCode)
      .set("role", "PARTICIPANT")
      .set("grant_type", "password");

    if (username) {
      body = body.set("username", username);
    }

    return this.sendLoginRequest(body, "participant");
  }

  getUser(accessToken: string): Observable<User> {
    const url = API.auth.getUser;
    return this.http.get<User>(url, {
      headers: new HttpHeaders({
        Authorization: "Bearer " + accessToken
      })
    });
  }

  refreshAuthToken(
    refreshToken: string,
    userRole: "moderator" | "participant"
  ): Observable<AuthSuccessResponse> {
    const body = new HttpParams()
      .set("grant_type", "refresh_token")
      .set("refresh_token", refreshToken);

    return this.sendLoginRequest(body, userRole);
  }

  loadSecurityConfig(eventId: string): Observable<EventSecurityConfig> {
    return this.http.get<EventSecurityConfig>(
      API.auth.loadEventSecurityConfig(),
      {
        params: { eventId }
      }
    );
  }

  setAuthenticateOnLoginAttempt(
    eventId: string,
    authenticateOnLoginAttempt: boolean
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      API.auth.setAuthenticateOnLoginAttempt(eventId),
      {
        authenticateOnLoginAttempt
      }
    );
  }

  setAllowOnlyOneAuthAtATime(
    eventId: string,
    allowOnlyOneAuthAtATime: boolean
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      API.auth.setAllowOnlyOneAuthAtATime(eventId),
      {
        allowOnlyOneAuthAtATime
      }
    );
  }

  logoutAllParticipants(eventId: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      API.auth.logoutAllParticipants(eventId),
      {}
    );
  }

  logoutParticipant(participantId: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      API.auth.logoutParticipant(participantId),
      {}
    );
  }

  getEventNameByEventCode(eventCode: string): Observable<string> {
    return this.http.get<string>(API.auth.logoutParticipant(eventCode));
  }

  deleteModerator() {
    return this.http.delete<ApiResponse>(API.auth.deleteModerator());
  }

  resetFailuresCount(moderatorId: string) {
    return this.http.post<ApiResponse>(
      API.auth.resetFailuresCount(moderatorId),
      {}
    );
  }

  addModerator(fullModerator: FullCreationModerator): Observable<Moderator> {
    const {
      tariffName,
      moderatorName,
      email,
      password,
      role,
      companyName,
      licenseStart: licenseStartDate,
      licenseExpiration,
      participantsLimit,
      participantsTotalDayLimit,
      participantsTotalMonthLimit
    } = fullModerator.moderator;

    const { customTariff } = fullModerator;

    return this.http
      .post<{ id: string }>(API.auth.createModerator, {
        tariffName,
        moderatorName,
        email,
        password,
        role,
        participantsLimit,
        participantsTotalDayLimit,
        participantsTotalMonthLimit
      })
      .pipe(
        switchMap((res) =>
          this.http.post<Moderator>(
            API.moderator.setModeratorSettings(res.id),
            {
              tariffName,
              moderatorName,
              companyName,
              licenseStartDate,
              licenseExpiration,
              customTariff
            }
          )
        )
      );
  }

  changeCompanyName(
    moderatorId: string,
    user: ModeratorParamsChangeRequest
  ): Observable<Moderator> {
    return this.http.post<Moderator>(
      API.moderator.setCompanyNameById(moderatorId),
      user
    );
  }

  deleteModeratorById(moderatorId: string): Observable<void> {
    return this.http.delete<void>(API.auth.deleteModeratorById(moderatorId));
  }

  changeUser(
    moderatorId: string,
    user: ModeratorParamsChangeRequest
  ): Observable<Moderator> {
    return this.http
      .post<Moderator>(API.auth.changeModeratorUser(moderatorId), user)
      .pipe(map((res) => res));
  }

  setPasswordAfterRegistration(
    securityToken: string,
    newPassword: string
  ): Observable<ApiResponse> {
    return this.http
      .post<ApiResponse>(API.auth.setPasswordAfterRegistration(), {
        securityToken,
        newPassword
      })
      .pipe(map((res) => res));
  }

  getRegisterInfo(securityToken: string): Observable<RegisterInfo> {
    return this.http
      .get<RegisterInfo>(API.auth.getRegisterInfo(securityToken))
      .pipe(map((res) => res));
  }

  getAllTariffs(): Observable<Tariff[]> {
    return this.http.get<Tariff[]>(API.moderator.getAllTariffs());
  }

  private sendLoginRequest(
    body: HttpParams,
    role: "moderator" | "participant"
  ): Observable<AuthSuccessResponse> {
    const url = API.auth.requestAuthToken;

    return this.http.post<AuthSuccessResponse>(url, body.toString(), {
      headers: new HttpHeaders({
        "Authorization": "Basic " + this.encodeToBase46(role + ":secret"),
        "Content-Type": "application/x-www-form-urlencoded"
      })
    });
  }

  private encodeToBase46(text: string) {
    return btoa(text);
  }
}

const AuthServiceMock = {};

export const AuthServiceMockProvider: ValueProvider = {
  provide: AuthService,
  useValue: AuthServiceMock
};
