<div
  class="container"
  fxLayout="column"
  [class.disabled]="disabled"
  [class.has-hint]="hint || showMaxLength"
>
  <mat-form-field>
    <mat-label *ngIf="!value || value.length === 0">
      {{ placeholder }}
    </mat-label>
    <input
      #input
      matInput
      [type]="type"
      [disabled]="disabled"
      [autocomplete]="autocomplete"
      [class.uppercase]="uppercase"
      [class.bold]="bold"
      [class.has-icon]="(materialIcon || svgIcon) && iconPosition === 'before'"
      [attr.maxLength]="maxLength"
      [ngModel]="value"
      (keyup.enter)="onEnter()"
      (blur)="onBlur()"
      (focus)="onFocus()"
      (ngModelChange)="writeValue($event)"
    />

    <button
      *ngIf="clearButton && !!input.value.length"
      class="clear-btn"
      mat-icon-button
      (click)="writeValue('')"
    >
      <mat-icon inline svgIcon="close"></mat-icon>
    </button>

    <mat-hint
      *ngIf="hint || showMaxLength"
      class="hint"
      @fadeInOut
      fxFlex="100"
      fxLayout="row"
      fxLayoutAlign="space-between start"
    >
      <div class="hint-text">
        {{ hint && !error && focus ? hint : "" }}
      </div>
      <div *ngIf="showMaxLength" class="max-length">
        {{ value?.length || 0 }}/{{ maxLength }}
      </div>
    </mat-hint>

    <span matPrefix>
      <ng-container
        *ngIf="iconPosition === 'before' && (materialIcon || svgIcon)"
        [ngTemplateOutlet]="icon"
      >
      </ng-container>
    </span>

    <span matSuffix>
      <ng-container
        *ngIf="iconPosition === 'after' && (materialIcon || svgIcon)"
        [ngTemplateOutlet]="icon"
      >
      </ng-container>
    </span>
  </mat-form-field>

  <div *ngIf="error" class="error-text regular" @fadeInOut>
    {{ error }}
  </div>
</div>

<ng-template #icon>
  <div fxLayoutAlign="center center" class="icon-container">
    <mat-icon
      [style.font-size.px]="iconSize"
      fxFlex="32px"
      [svgIcon]="svgIcon ? svgIcon : null"
    >
      {{ materialIcon ? materialIcon : null }}
    </mat-icon>
  </div>
</ng-template>
