import packageInfo from "../../../../../package.json";

export interface IEnvironment {
  production: boolean;
  envName: string;
  host: string;
  wsHost: string;
  wsRelayHost: string;
  VERSION: string;
}

export type ApiScope = "stagingapi" | "api" | "back" | "load-test";
export type EnvironmentName =
  | "dev"
  | "staging"
  | "selectel"
  | "prod"
  | "loadtest"
  | "dev-prod";

export class Environment implements IEnvironment {
  production: boolean;
  envName: EnvironmentName;
  host: string;
  wsHost: string;
  wsRelayHost: string;
  VERSION: string;

  constructor(production: boolean, envName: EnvironmentName, scope: ApiScope) {
    this.production = production;
    this.envName = envName;
    this.host = this.getHost(scope);
    this.wsHost = this.getWsHost(scope);
    this.wsRelayHost = this.getWsRelayHost(scope);
    this.VERSION = packageInfo?.version;
  }

  private getHost = (scope: ApiScope): string => `https://${scope}.tellsy.ru`;
  private getWsHost = (scope: ApiScope): string => `wss://${scope}.tellsy.ru`;
  private getWsRelayHost = (scope: ApiScope): string =>
    `wss://${scope}.tellsy.ru/wsRelay`;
}
