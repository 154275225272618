import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { User, UserRole } from "@app/store/auth/model";
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent,
} from "ngx-cookieconsent";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

export function promptCookies(
  ccService: NgcCookieConsentService,
  destroy$: Observable<void>,
) {
  if (!ccService || !ccService.statusChange$) {
    return;
  }
  const isCookieAllowedLocalStorageKey = "IS_COOKIE_ALLOWED";
  const isCookieAllowed = localStorage.getItem(isCookieAllowedLocalStorageKey);

  if (isCookieAllowed === "true") {
    ccService.close(false);
  } else {
    ccService.open();
    ccService.statusChange$
      .pipe(takeUntil(destroy$))
      .subscribe((event: NgcStatusChangeEvent) => {
        if (event.status === "allow") {
          localStorage.setItem(isCookieAllowedLocalStorageKey, "true");
          ccService.destroy();
        } else {
          localStorage.removeItem(isCookieAllowedLocalStorageKey);
        }
      });
  }
}

export function noTokenOrUnauthorized(
  errorResponse: HttpErrorResponse,
): boolean {
  return (
    errorResponse.error?.error === "unauthorized" ||
    errorResponse.status === HttpStatusCode.Unauthorized
  );
}

export function accessTokenExpired(errorResponse: HttpErrorResponse): boolean {
  return (
    errorResponse.error?.error === "invalid_token" &&
    errorResponse.error?.error_description
  );
}

export function refreshTokenExpired(errorResponse: HttpErrorResponse): boolean {
  return (
    errorResponse.error.error === "invalid_grant" &&
    errorResponse.error.error_description?.startsWith("Invalid refresh token")
  );
}

export function isAuthenticatedAsParticipant(user: User): boolean {
  if (!user) {
    return false;
  }

  return (
    user.authenticated &&
    !!user.authorities.find((a) => a.authority === UserRole.ROLE_PARTICIPANT)
  );
}

export function unicodeToAscii(str: string): string {
  let reserved = "";
  const code = str.match(/&#(d+);/g);

  if (code === null) {
    return str;
  }

  for (const char of code) {
    reserved += String.fromCharCode(+char.replace(/[&#;]/g, ""));
  }

  return reserved;
}
