import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import "@angular/common/locales/global/ru";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CustomRouterSerializer } from "@app/configs/custom-router-serializer";
import { AuthInputComponent } from "@app/modules/auth/input/auth-input.component";
import { NotFoundComponent } from "@app/not-found/not-found.component";
import { AuthInterceptor } from "@app/services/interceptors/auth.interceptor";
import { ErrorInterceptor } from "@app/services/interceptors/error.interceptor";
import { AuthStoreModule } from "@app/store/auth/auth-store.module";
import { EventStoreModule } from "@app/store/event/event-store.module";
import { LogoutEffects } from "@app/store/logout/logout.effects";
import { metaLogout } from "@app/store/logout/logout.meta";
import {
  USER_STATE_ID,
  userReducer,
} from "@app/store/user/reducers/user.reducer";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule, routerReducer } from "@ngrx/router-store";
import { ActionReducer, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ConnectionStatusComponent } from "@shared/connection-status/connection-status.component";
import { ConfirmationDialogComponent } from "@shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ContentClosedDialogComponent } from "@shared/dialogs/content-closed-dialog/content-closed-dialog.component";
import { EventColorThemeDialogComponent } from "@shared/dialogs/event-color-theme-dialog/event-color-theme-dialog.component";
import { ImageDialogComponent } from "@shared/dialogs/image-dialog/image-dialog.component";
import { LongContentDialogComponent } from "@shared/dialogs/long-content-dialog/long-content-dialog.component";
import { ModeratorAccessManagementDialogComponent } from "@shared/dialogs/moderator-access-management-dialog/moderator-access-management-dialog.component";
import { ModeratorManagementDialogComponent } from "@shared/dialogs/moderator-management-dialog/moderator-management-dialog.component";
import { MultiSelectDialogWithChipsComponent } from "@shared/dialogs/multi-select-dialog-with-chips/multi-select-dialog-with-chips.component";
import { MultipleChoiceDialogComponent } from "@shared/dialogs/multiple-select-dialog/multiple-choice-dialog.component";
import { NewEventDialogComponent } from "@shared/dialogs/new-event-dialog/new-event-dialog.component";
import { PermissionsDialogComponent } from "@shared/dialogs/permissions-dialog/permissions-dialog.component";
import { SelectDialogComponent } from "@shared/dialogs/select-dialog/select-dialog.component";
import { TextDialogComponent } from "@shared/dialogs/text-dialog/text-dialog.component";
import { TextEditDialogComponent } from "@shared/dialogs/text-edit-dialog/text-edit-dialog.component";
import { TextInputDialogComponent } from "@shared/dialogs/text-input-dialog/text-input-dialog.component";
import { NotificatorComponent } from "@shared/notificator/notificator.component";
import { ShowRandomizationDialogModule } from "@shared/show-randomization/show-randomization-dialog.module";
import { AuthFacadeModule } from "@tellsy/auth-facade";
import { BugReportBtnModule } from "@tellsy/bug-report-btn";
import { TellsyCommonModule } from "@tellsy/common/common.module";
import { WINDOW_PROVIDERS } from "@tellsy/common/services/browser-window.ref";
import { DateFormatService } from "@tellsy/common/services/date-format.service";
import { DialogService } from "@tellsy/common/services/dialog.service";
import { EnvironmentService } from "@tellsy/common/services/environment.service";
import { environment } from "@tellsy/environments/environment";
import { SimpleNotificationsModule } from "@tellsy/notifications";
import { ThemeModule } from "@tellsy/theme";
import { MessageDialogComponent } from "@tellsy/theme/dialogs/message-dialog/message-dialog.component";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavigateToEventByQueryParamsGuard } from "./guard/navigate-to-event-by-query-params.guard";
import { AuthFooterComponent } from "./modules/auth/auth-footer/auth-footer.component";
import { ModeratorAuthComponent } from "./modules/auth/moderator-auth/moderator-auth.component";
import { ParticipantAuthComponent } from "./modules/auth/participant-auth/participant-auth.component";
import { SetPasswordComponent } from "./modules/auth/set-password/set-password.component";
import { ParticipantToolbarModule } from "./modules/participant/sidenav/participant-toolbar/participant-toolbar.module";
import {
  TranslateCacheModule,
  TranslateCacheService,
  TranslateCacheSettings,
} from "./translate-cache";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "/assets/i18n/",
    ".json?version=" + environment.VERSION,
  );
}

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.debug("state", state);
    console.debug("action", action);

    return reducer(state, action);
  };
}

export const cookieConfig: NgcCookieConsentConfig = {
  autoOpen: false,
  cookie: {
    domain: "tellsy.ru", // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: "#fff",
      text: "#424258",
    },
    button: {
      background: "#fff",
      text: "#201367",
      border: "#201367",
    },
  },
  content: {
    dismiss: "",
    link: "",
    deny: "",
  },
  theme: "classic",
  type: "opt-out",
};

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
        [USER_STATE_ID]: userReducer,
      },
      {
        metaReducers: [metaLogout],
      },
    ),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterSerializer,
    }),
    EffectsModule.forRoot([LogoutEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 35,
      name: `Tellsy ${environment?.production ? "production" : "beta"}`,
    }),
    TranslateModule.forRoot({
      defaultLanguage: "ru",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: (translateService, translateCacheSettings) =>
          new TranslateCacheService(translateService, translateCacheSettings),
        deps: [TranslateService, TranslateCacheSettings],
      },
    }),
    BugReportBtnModule,
    ShowRandomizationDialogModule,
    AuthFacadeModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    SimpleNotificationsModule.forRoot(),
    ThemeModule,
    ParticipantToolbarModule,
    AuthStoreModule,
    EventStoreModule,
    TellsyCommonModule,
  ],
  declarations: [
    AppComponent,
    AuthInputComponent,
    ModeratorAuthComponent,
    ParticipantAuthComponent,
    AuthFooterComponent,
    SetPasswordComponent,
    NotificatorComponent,
    NotFoundComponent,
    ConnectionStatusComponent,
    NewEventDialogComponent,
    TextInputDialogComponent,
    ConfirmationDialogComponent,
    LongContentDialogComponent,
    ImageDialogComponent,
    MultipleChoiceDialogComponent,
    MultiSelectDialogWithChipsComponent,
    PermissionsDialogComponent,
    SelectDialogComponent,
    ContentClosedDialogComponent,
    TextDialogComponent,
    MessageDialogComponent,
    EventColorThemeDialogComponent,
    TextEditDialogComponent,
    ModeratorManagementDialogComponent,
    ModeratorAccessManagementDialogComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DialogService,
    DateFormatService,
    NavigateToEventByQueryParamsGuard,
    WINDOW_PROVIDERS,
    EnvironmentService,
    { provide: "Window", useValue: window },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
