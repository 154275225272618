import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap } from "rxjs";
import { AuthActions } from "../actions";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthEffects {
  logoutParticipant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutParticipant),
        switchMap(({ participantId }) =>
          this.authService.logoutParticipant({ participantId }),
        ),
      ),
    { dispatch: false },
  );
  logoutAllParticipants$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutAllParticipants),
        switchMap(({ eventId }) =>
          this.authService.logoutAllParticipants(eventId),
        ),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private authService: AuthService) {}
}
