import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import {ActivityListService} from "@moderator/event/activity-details-page/services/activity-list.service";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public activityListService: ActivityListService,
  ) {}

  ngOnInit() {}

  confirm() {
    this.dialogRef.close(true);
    this.activityListService.hideCheckbox();
  }

  closeCheckboxPane() {
    this.activityListService.hideCheckbox();
  }
}

export interface ConfirmationDialogData {
  confirmationQuestion: string;
  placeholder?: string;
  confirmButtonText: string;
}
