import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import {
  WorkspaceActions,
  WorkspaceErrorActions,
  WorkspaceSuccessActions,
} from "../actions";
import { EventService } from "../services/event.service";

@Injectable()
export class WorkspaceEffects {
  addDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.addDocument),
      switchMap(({ eventId, file }) =>
        this.eventService.addDocument({ eventId, file }).pipe(
          map((document) => WorkspaceSuccessActions.addDocument({ document })),
          catchError((error: string) =>
            of(
              WorkspaceErrorActions.addDocument({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );
  deleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.deleteDocument),
      switchMap(({ eventId, documentId }) =>
        this.eventService.deleteDocument({ eventId, documentId }),
      ),
      map(({ eventId, documentId }) =>
        WorkspaceSuccessActions.deleteDocument({ eventId, documentId }),
      ),
    ),
  );
  renameDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.renameDocument),
      switchMap((action) => this.eventService.renameDocument(action)),
      map((action) => WorkspaceSuccessActions.renameDocument(action)),
    ),
  );
  replaceDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.replaceDocument),
      switchMap((action) => this.eventService.replaceDocument(action)),
      map((action) => WorkspaceSuccessActions.replaceDocument(action)),
    ),
  );
  setDocumentProtected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.setDocumentProtected),
      switchMap((action) => this.eventService.setDocumentProtected(action)),
      map((action) => WorkspaceSuccessActions.setDocumentProtected(action)),
    ),
  );
  setDocumentHidden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.setDocumentHidden),
      switchMap((action) => this.eventService.setDocumentHidden(action)),
      map((action) => WorkspaceSuccessActions.setDocumentHidden(action)),
    ),
  );
  setAllDocumentsProtected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.setAllDocumentsProtected),
      switchMap((action) => this.eventService.setAllDocumentsProtected(action)),
      map((action) => WorkspaceSuccessActions.setAllDocumentsProtected(action)),
    ),
  );
  setAllDocumentsHidden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.setAllDocumentsHidden),
      switchMap((action) => this.eventService.setAllDocumentsHidden(action)),
      map((action) => WorkspaceSuccessActions.setAllDocumentsHidden(action)),
    ),
  );
  setDocumentsOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.setDocumentsOrders),
      switchMap((action) => this.eventService.setDocumentsOrders(action)),
      map((action) => WorkspaceSuccessActions.setDocumentsOrders(action)),
    ),
  );
  setSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.setSchedule),
      switchMap((action) =>
        this.eventService.setSchedule(action).pipe(
          map((schedule) => WorkspaceSuccessActions.setSchedule(schedule)),
          catchError((error: string) =>
            of(
              WorkspaceErrorActions.setSchedule({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  deleteSchedule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.deleteSchedule),
      switchMap(({ eventId }) => this.eventService.deleteSchedule(eventId)),
      map(() => WorkspaceSuccessActions.deleteSchedule()),
    ),
  );
  setScheduleHidden$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceActions.setScheduleHidden),
      switchMap((action) => this.eventService.setScheduleHidden(action)),
      map((action) => WorkspaceSuccessActions.setScheduleHidden(action)),
    ),
  );

  constructor(private actions$: Actions, private eventService: EventService) {}
}
