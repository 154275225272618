import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { Event, Randomization, SubEventsLink } from "../model";
import {
  EventSettingsDto,
  LoadEventDto,
  SetHelpPhoneDto,
  SetInfoForParticipantDto,
  SetLogoDto,
  SetParticipantsActivityTracking,
  SetSharedIframeUrlDto,
  SetThemeDto,
  SetWebinarDto,
  StartRandomizationDto,
} from "../model/dto";

export const EventActions = createActionGroup({
  source: "Event",
  events: {
    "Load Event": props<{ eventId: string }>(),
    "Change Settings": props<{
      eventId: string;
      request: EventSettingsDto;
    }>(),
    "Set Info For Participant": props<SetInfoForParticipantDto>(),
    "Set Help Phone": props<SetHelpPhoneDto>(),
    "Set Shared Iframe Url": props<SetSharedIframeUrlDto>(),
    "Set Theme": props<SetThemeDto>(),
    "Set Webinar": props<SetWebinarDto>(),

    "Set Participant Activity Tracking":
      props<SetParticipantsActivityTracking>(),

    "Download Report": props<{ eventIds: string[] }>(),

    "Start Randomization": props<StartRandomizationDto>(),
    "Close Randomization": props<{ eventId: string }>(),

    "Load Information For Participant": props<{ eventId: string }>(),
  },
});

export const EventSuccessActions = createActionGroup({
  source: "Event Success",
  events: {
    "Load Event": props<{ dto: LoadEventDto }>(),
    "Change Settings": props<{
      event: Event;
    }>(),

    "Set Loading": props<{ loading: boolean }>(),
    "Set Info For Participant": props<{ infoForParticipantHtml: string }>(),
    "Set Help Phone": props<SetHelpPhoneDto>(),
    "Set Shared Iframe Url": props<SetSharedIframeUrlDto>(),
    "Set Theme": props<SetThemeDto>(),
    "Set Webinar": props<SetWebinarDto>(),

    "Set Participant Activity Tracking":
      props<SetParticipantsActivityTracking>(),

    "Clear Randomization": emptyProps(),
    "Download Report": emptyProps(),
    "Clear State": emptyProps(),
  },
});

export const EventErrorActions = createActionGroup({
  source: "Event Error",
  events: {
    "Download Report": props<{ error: string }>(),
    "Change Settings": props<{
      error: string;
    }>(),
  },
});

export const EventWebsocketActions = createActionGroup({
  source: "Event ~ Websocket",
  events: {
    "Set Name": props<{ name: string }>(),
    "Set Webinar": props<SetWebinarDto>(),
    "Set Shared Iframe Url": props<SetSharedIframeUrlDto>(),
    "Set Info For Participant": props<{ infoForParticipantHtml: string }>(),
    "Set Participants Activity Tracking": props<{
      trackParticipantTab: boolean;
    }>(),

    "Set SubEvents Links": props<{
      name: string;
      subEventsLinks: SubEventsLink[];
    }>(),

    "Set Theme": props<SetThemeDto>(),
    "Set Logo": props<SetLogoDto>(),

    "Set Randomization": props<{ randomization: Randomization }>(),
  },
});
