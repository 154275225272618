import { Injectable } from "@angular/core";
import { Participant, Team } from "@app/models/participant.model";
import { Activity } from "@app/store/event/model";
import { TeamsActions } from "@app/store/teams/teams.actions";
import { TeamsSelectors } from "@app/store/teams/teams.selectors";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TeamsFacade {
  constructor(private store: Store) {}

  loadTeams(parentEventId: string): void {
    this.store.dispatch(
      TeamsActions.loadTeams({
        parentEventId,
      }),
    );
  }

  setTeams(teams: Team[], safeLink?: boolean): void {
    this.store.dispatch(
      TeamsActions.setTeams({
        teams,
        safeLink,
      }),
    );
  }

  setTeam(team: Team): void {
    this.store.dispatch(
      TeamsActions.setTeam({
        team,
      }),
    );
  }

  changeTeamCaptain(
    teamId: string,
    captainId: string,
    captainIdentifier: string,
  ): void {
    this.store.dispatch(
      TeamsActions.changeTeamCaptain({
        teamId,
        captainId,
        captainIdentifier,
      }),
    );
  }

  randomlyChangeParticipantsTeams(
    numberOfTeamsToCreate: number,
    eventId: string,
  ): void {
    this.store.dispatch(
      TeamsActions.randomlyChangeParticipantsTeams({
        numberOfTeamsToCreate,
        eventId,
      }),
    );
  }

  // selectors

  getAllTeams$(): Observable<Team[]> {
    return this.store.select(TeamsSelectors.selectAllTeams);
  }

  isCaptain$(participant: Participant): Observable<boolean> {
    return this.store.select(TeamsSelectors.selectIsCaptain(participant));
  }

  getTeamsFromSelectedActivity$(activityId: string): Observable<Team[]> {
    return this.store.select(
      TeamsSelectors.selectTeamsFromSelectedActivity(activityId),
    );
  }

  getTeamsFromActivity$(activity: Activity): Observable<Team[]> {
    return this.store.select(TeamsSelectors.selectTeamsFromActivity(activity));
  }
}
