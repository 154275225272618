import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@app/configs/api.config";
import { ApiResponse } from "@app/models/common.models";
import { TagCloudDataForReport } from "@tellsy/theme/tag-cloud/model";
import { map } from "rxjs";
import {
  ActivateActivityDto,
  Activity,
  ChangeActivityForTeamsDto,
  ChangeActivityOrderDto,
  CreateActivityDto,
  DeleteActivityDto,
  DocumentDto,
  DocumentIdDto,
  DownloadReportDto,
  DuplicateActivityDto,
  Event,
  EventDocument,
  EventSchedule,
  EventSettingsDto,
  LoadEventDto,
  RenameActivityDto,
  RenameDocumentDto,
  ReplaceDocumentDto,
  SetAllDocumentsHiddenDto,
  SetAllDocumentsProtectedDto,
  SetDocumentHiddenDto,
  SetDocumentProtectedDto,
  SetDocumentsOrderDto,
  SetHelpPhoneDto,
  SetInfoForParticipantDto,
  SetParticipantsActivityTracking,
  SetProjectorActivityDto,
  SetProjectorFullscreenTimerDto,
  SetScheduleDto,
  SetScheduleIsHiddenDto,
  SetSharedIframeUrlDto,
  SetTeamsForActivityDto,
  SetWebinarDto,
  StartRandomizationDto,
  TimerDto,
} from "../model";
import { Session_lightDtoModel } from "@app/store/event/model/dto/session_light.dto.model";

@Injectable()
export class EventService {
  constructor(private http: HttpClient) {}

  loadEvent(eventId: string) {
    return this.http.get<LoadEventDto>(API.event.loadEvent(eventId));
  }

  changeSettings(eventId: string, request: EventSettingsDto) {
    return this.http.post<Event>(
      API.events.changeEventSettings(eventId),
      request,
    );
  }

  setHelpPhone({ eventId, helpPhone }: SetHelpPhoneDto) {
    return this.http
      .post<ApiResponse>(API.event.setHelpPhone(eventId), { helpPhone })
      .pipe(map(() => ({ eventId, helpPhone })));
  }

  setWebinar({ eventId, webinar }: SetWebinarDto) {
    return this.http.post<ApiResponse>(API.event.setWebinar(eventId), {
      webinar,
    });
  }

  setSharedIframeUrl({ eventId, url }: SetSharedIframeUrlDto) {
    return this.http.post<ApiResponse>(API.event.setSharedIframeUrl(eventId), {
      url,
    });
  }

  setInfoForParticipant({ eventId, information }: SetInfoForParticipantDto) {
    return this.http
      .post<SetInfoForParticipantDto>(
        API.event.setInfoForParticipant(eventId),
        { information },
      )
      .pipe(map((res) => ({ information: res.information })));
  }

  getTagCloudActivitiesForReport({ eventIds }) {
    return this.http.get<TagCloudDataForReport[]>(
      API.event.getTagCloudActivitiesForReport(),
      { params: { eventIds } },
    );
  }

  downloadReport({ eventIds, imagesByEvent }: DownloadReportDto) {
    return this.http.post(
      API.event.downloadReport(eventIds),
      { imagesByEvent },
      { responseType: "arraybuffer" },
    );
  }

  setParticipantsActivityTracking({
    eventId,
    trackParticipantTab,
  }: SetParticipantsActivityTracking) {
    return this.http
      .put<ApiResponse>(
        API.event.setParticipantsActivityTracking(eventId),
        {},
        { params: { isEnabled: trackParticipantTab } },
      )
      .pipe(map(() => ({ eventId, trackParticipantTab })));
  }

  // Activity
  createActivity({
    eventId,
    activityType,
    previousActivityId,
  }: CreateActivityDto) {
    return this.http.post<Activity>(
      API.event.activity.createActivity(eventId),
      { type: activityType, previousActivityId },
    );
  }

  deleteActivity({ eventId, activityId }: DeleteActivityDto) {
    return this.http
      .delete<ApiResponse>(
        API.event.activity.deleteActivity(eventId, activityId),
        {},
      )
      .pipe(map(() => ({ eventId, activityId })));
  }

  deleteMultiActivity({ eventId, activityId }: DeleteActivityDto) {
    return this.http
      .delete<ApiResponse>(
        API.event.activity.deleteMultiActivity(eventId, activityId),
        {},
        )
       .pipe(map(() => ({ eventId, activityId })));
  }

  duplicateAcivity({ eventId, eventIds, activityId }: DuplicateActivityDto) {
    return this.http.post<Activity[]>(
      API.event.activity.duplicateActivity(eventId),
      { eventIds, activityId },
    );
  }

  renameActivity({ eventId, activityId, name: newName }: RenameActivityDto) {
    return this.http
      .post<ApiResponse>(
        API.event.activity.renameActivity(eventId, activityId),
        { newName },
      )
      .pipe(map(() => ({ activityId, name: newName })));
  }

  setActivityOrder({ eventId, activityId, order }: ChangeActivityOrderDto) {
    return this.http
      .post<Activity[]>(
        API.event.activity.setActivityOrder(eventId, activityId),
        { order },
      )
      .pipe(map((activities) => ({ activities })));
  }

  activateActivity({ eventId, activityId }: ActivateActivityDto) {
    return this.http
      .post<ApiResponse>(
        API.event.activity.activateActivity(eventId, activityId),
        {},
      )
      .pipe(map(() => ({ eventId, activityId })));
  }

  deactivateActivity(eventId: string) {
    return this.http.post<ApiResponse>(
      API.event.activity.deactivateActivity(eventId),
      {},
    );
  }

  activateNextActivity(eventId: string) {
    return this.http.post<{ activity: Activity }>(
      API.event.activity.activateNextActivity(eventId),
      {},
    );
  }

  activatePrevActivity(eventId: string) {
    return this.http.post<{ activity: Activity }>(
      API.event.activity.activatePrevActivity(eventId),
      {},
    );
  }

  // Activity teams
  setTeamsForActivity({
    eventId,
    activityId,
    teamIds,
  }: SetTeamsForActivityDto) {
    return this.http
      .post<ApiResponse>(
        API.event.activity.teams.setTeamsForActivity(eventId, activityId),
        teamIds,
      )
      .pipe(map(() => ({ eventId, activityId, teamIds })));
  }

  activateActivitiesForTeams({
    eventId,
    teamActivities,
  }: ChangeActivityForTeamsDto) {
    return this.http
      .post<ApiResponse>(
        API.event.activity.teams.activateActivitiesForTeams(eventId),
        { teamActivities },
      )
      .pipe(map(() => ({ eventId, teamActivities })));
  }

  deactivateActivitiesForTeams({
    eventId,
    teamActivities,
  }: ChangeActivityForTeamsDto) {
    return this.http
      .post<ApiResponse>(
        API.event.activity.teams.deactivateActivitiesForTeams(eventId),
        { teamActivities },
      )
      .pipe(map(() => ({ eventId, teamActivities })));
  }

  //Session Light
  startSessionLight(eventId: string,) {
    return this.http.put<Session_lightDtoModel[]>(API.event.sessionLight.startSessionLight(eventId), {
      eventId
    });
  }

  finishSessionLight(eventId: string,) {
    return this.http.put<Session_lightDtoModel[]>(API.event.sessionLight.finishSessionLight(eventId), {
      eventId
    });
  }

  remainingSeconds(eventId: string,) {
    return this.http.get<number>(API.event.sessionLight.remainingSeconds(eventId));
  }

  // Timer
  loadTimer(eventId: string) {
    return this.http.get<TimerDto[]>(API.event.timer.load(eventId));
  }

  startTimer(eventIds: string[], seconds: number) {
    return this.http.post<TimerDto[]>(API.event.timer.start(), {
      eventIds,
      seconds,
    });
  }

  stopTimer(eventIds: string[]) {
    return this.http.post<TimerDto[]>(API.event.timer.stop(), {
      eventIds,
    });
  }

  setTimerCanBeNegative(eventIds: string[], canBeNegative: boolean) {
    return this.http.post<TimerDto[]>(API.event.timer.setCanBeNegative(), {
      eventIds,
      canBeNegative,
    });
  }

  // Projector
  setProjectorActivity(action: SetProjectorActivityDto) {
    return this.http.post<ApiResponse>(
      API.event.projector.setProjectorActivity(action.eventId),
      action,
    );
  }

  setProjectorTimerFullscreen({
    eventId,
    showFullscreenTimer,
  }: SetProjectorFullscreenTimerDto) {
    return this.http.post<ApiResponse>(
      API.event.projector.setProjectorTimerFullscreen(eventId),
      {},
      { params: { eventId, is_fullscreen: showFullscreenTimer } },
    );
  }

  // Workspace
  addDocument({ eventId, file }: DocumentDto) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.http.post<EventDocument>(
      API.event.workspace.documents.addDocument(eventId),
      formData,
      { reportProgress: true },
    );
  }

  deleteDocument({ eventId, documentId }: DocumentIdDto) {
    return this.http
      .delete<ApiResponse>(
        API.event.workspace.documents.deleteDocument(eventId, documentId),
        {},
      )
      .pipe(map(() => ({ eventId, documentId })));
  }

  renameDocument({ eventId, documentId, fileName }: RenameDocumentDto) {
    return this.http
      .post<ApiResponse>(
        API.event.workspace.documents.renameDocument(eventId, documentId),
        { fileName },
      )
      .pipe(map(() => ({ eventId, documentId, fileName })));
  }

  replaceDocument({ eventId, documentId, file }: ReplaceDocumentDto) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.http
      .put<EventDocument>(
        API.event.workspace.documents.replaceDocument(eventId, documentId),
        formData,
        { reportProgress: true },
      )
      .pipe(map((document) => ({ eventId, documentId, document })));
  }

  setDocumentProtected({
    eventId,
    documentId,
    isProtected,
  }: SetDocumentProtectedDto) {
    return this.http
      .post<ApiResponse>(
        API.event.workspace.documents.setDocumentHidden(eventId, documentId),
        { isProtected },
      )
      .pipe(map(() => ({ eventId, documentId, isProtected })));
  }

  setDocumentHidden({ eventId, documentId, isHidden }: SetDocumentHiddenDto) {
    return this.http
      .post<ApiResponse>(
        API.event.workspace.documents.setDocumentHidden(eventId, documentId),
        { isHidden },
      )
      .pipe(map(() => ({ eventId, documentId, isHidden })));
  }

  setAllDocumentsProtected({
    eventId,
    documentsProtected,
  }: SetAllDocumentsProtectedDto) {
    return this.http
      .post<ApiResponse>(
        API.event.workspace.documents.setAllDocumentsProtected(eventId),
        { documentsProtected },
      )
      .pipe(map(() => ({ eventId, documentsProtected })));
  }

  setAllDocumentsHidden({
    eventId,
    documentsHidden,
  }: SetAllDocumentsHiddenDto) {
    return this.http
      .post<ApiResponse>(
        API.event.workspace.documents.setAllDocumentsHidden(eventId),
        { documentsHidden },
      )
      .pipe(map(() => ({ eventId, documentsHidden })));
  }

  setDocumentsOrders({
    eventId,
    documentsIds,
    documentsOrders,
  }: SetDocumentsOrderDto) {
    return this.http
      .post<ApiResponse>(
        API.event.workspace.documents.setDocumentsOrders(eventId),
        { documentsIds, documentsOrders },
      )
      .pipe(map(() => ({ eventId, documentsIds, documentsOrders })));
  }

  setSchedule({ eventId, file }: SetScheduleDto) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.http.post<{ schedule: EventSchedule }>(
      API.event.workspace.schedule.setSchedule(eventId),
      formData,
    );
  }

  deleteSchedule(eventId: string) {
    return this.http.delete<ApiResponse>(
      API.event.workspace.schedule.deleteSchedule(eventId),
      {},
    );
  }

  setScheduleHidden({ eventId, isHidden }: SetScheduleIsHiddenDto) {
    return this.http
      .post<ApiResponse>(
        API.event.workspace.schedule.setScheduleHidden(eventId),
        { isHidden },
      )
      .pipe(map(() => ({ eventId, isHidden })));
  }

  // Randomization
  startRandomization({
    eventId,
    delayTimeInSeconds,
    isTeamsVariant,
  }: StartRandomizationDto) {
    return this.http.post<ApiResponse>(
      API.miniActivities.startRandomization(eventId),
      { delayTimeInSeconds, isTeamsVariant },
    );
  }

  closeRandomization(eventId: string) {
    return this.http.post<ApiResponse>(
      API.miniActivities.closeRandomization(eventId),
      {},
    );
  }
}
