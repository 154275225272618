<div mat-dialog-content>
  <button tabindex="-1" mat-dialog-close mat-icon-button class="close-button">
    <mat-icon color="primary">close</mat-icon>
  </button>

  <div class="middle-text bold title uppercase">{{ title }}</div>

  <div
    class="text-wrapper ql-editor"
    [innerHTML]="content | safe: 'html'"
  ></div>
</div>
