import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AuthFacade, promptCookies } from "@tellsy/auth-facade";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-moderator-auth",
  templateUrl: "./moderator-auth.component.html",
  styleUrls: ["./moderator-auth.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModeratorAuthComponent implements OnInit, OnDestroy {
  form: FormGroup<{
    username: FormControl<string>;
    password: FormControl<string>;
  }>;
  username = new FormControl<string>("", [Validators.required]);
  password = new FormControl<string>("", [Validators.required]);

  errorMessage: string;
  pending: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private authFacade: AuthFacade,
    private ref: ChangeDetectorRef,
    private translate: TranslateService,
    private ccService: NgcCookieConsentService,
  ) {}

  ngOnInit() {
    promptCookies(this.ccService, this.destroy$);
    this.form = this.fb.group({
      username: this.username,
      password: this.password,
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  attemptLogin(): void {
    if (this.form.invalid) {
      return;
    }
    const { username, password } = this.form.value;
    this.pending = true;

    this.authFacade
      .loginModerator(username, password)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.pending = false;
        },
        (res: HttpErrorResponse) => {
          let errorText: string;
          const { error } = res;

          switch (res.status) {
            case 0:
              errorText = this.translate.instant(
                "auth.moderator.input.errors.noInternet",
              );
              break;
            case 400:
              errorText = this.translate.instant(
                "auth.moderator.input.errors.wrongUserOrPassword",
              );
              if (error.attemptsRemaining !== null) {
                errorText +=
                  " " +
                  this.translate.instant(
                    "auth.moderator.input.errors.attemptsRemaining",
                    { value: error.attemptsRemaining },
                  );
              }
              break;
            case 401:
              errorText = this.translate.instant(
                "auth.moderator.input.errors.limitExceeded",
              );
              break;
            default:
              errorText = this.translate.instant(
                "auth.moderator.input.errors.unknownError",
              );
          }

          this.pending = false;
          this.errorMessage = errorText;
          this.ref.markForCheck();
          return of(null);
        },
      );
  }

  onFormControlChange(control: FormControl<string>, change: string): void {
    this.errorMessage = null; // remove underline error when user change form values
    control.patchValue(change);
    control.markAsDirty();
  }
}
