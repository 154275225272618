import { Component, Inject, OnInit } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";

export class MultipleChoiceDialogItem {
  constructor(
    public text: string,
    public value: string,
    public type: MultipleChoiceDialogItemType,
  ) {}
}

export class MultipleChoiceDialogResult {
  constructor(
    public value: string | string[],
    public type: MultipleChoiceDialogItemType,
  ) {}
}

export enum MultipleChoiceDialogItemType {
  MENU,
  CHECKBOX,
}

export class DialogData {
  title: string;
  variants: MultipleChoiceDialogItem[];
  chooseNoOneVariant: string;
}

@Component({
  selector: "app-multiple-select-dialog",
  templateUrl: "./multiple-choice-dialog.component.html",
  styleUrls: ["./multiple-choice-dialog.component.scss"],
})
export class MultipleChoiceDialogComponent implements OnInit {
  variants: MultipleChoiceDialogItem[];
  title: string;
  selected: string[] = [];
  types = MultipleChoiceDialogItemType;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private componentMatDialogRef: MatDialogRef<MultipleChoiceDialogComponent>,
  ) {
    this.variants = data.variants;
    this.title = data.title;
  }

  ngOnInit() {}

  sendCheckBoxVariants(selected: string[]) {
    this.componentMatDialogRef.close(
      new MultipleChoiceDialogResult(
        selected,
        MultipleChoiceDialogItemType.CHECKBOX,
      ),
    );
  }

  sendMenuVariants(variant: string) {
    this.componentMatDialogRef.close(
      new MultipleChoiceDialogResult(
        variant,
        MultipleChoiceDialogItemType.MENU,
      ),
    );
  }
}
