import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ArrayFromNumberMinMaxPipe } from "./array-from-number-min-max/array-from-number-min-max.pipe";
import { ArrayFromNumberPipe } from "./array-from-number.pipe";

@NgModule({
  declarations: [ArrayFromNumberPipe, ArrayFromNumberMinMaxPipe],
  exports: [ArrayFromNumberPipe, ArrayFromNumberMinMaxPipe],
  imports: [CommonModule],
})
export class ArrayFromNumberPipeModule {}
