<ng-container *ngrxLet="connectionStatus$ as connectionStatus">
  <div class="status-wrapper">
    <mat-spinner [diameter]="24" *ngIf="connectionStatus === 0"></mat-spinner>
    <mat-icon *ngIf="connectionStatus === 2 || connectionStatus === 3">
      signal_cellular_null
    </mat-icon>
    <mat-icon *ngIf="connectionStatus === 1" color="primary">
      signal_cellular_4_bar
    </mat-icon>
  </div>
</ng-container>
