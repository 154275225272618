import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@app/configs/api.config";
import { Role } from "@app/store/auth/model";
import { AuthStorage } from "@tellsy/auth-facade";
import { combineLatest, iif, Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

export interface HasBeforeLogoutCallback {
  readonly logoutCallback: Observable<void>;
  addBeforeLogoutCallback(): Set<Observable<any>>;
  removeBeforeLogoutCallback(): boolean;
}

@Injectable({
  providedIn: "root",
})
export class LogoutService {
  beforeLogoutObservables: Set<Observable<unknown>> = new Set();

  constructor(private http: HttpClient, private authStorage: AuthStorage) {}

  logout(
    removeCredentials: boolean,
    role: Role,
    eventCode: string,
  ): Observable<void> {
    return iif(
      () => this.beforeLogoutObservables.size > 0,

      combineLatest([this.beforeLogoutObservables]).pipe(
        switchMap(() =>
          removeCredentials
            ? this.logoutAndRemoveCredentials(role, eventCode)
            : this.logoutRequest(),
        ),
      ),
      of(null).pipe(
        switchMap(() =>
          removeCredentials
            ? this.logoutAndRemoveCredentials(role, eventCode)
            : this.logoutRequest(),
        ),
      ),
    );
  }

  revokeToken(): Observable<any> {
    return this.revokeTokenRequest();
  }

  addBeforeLogoutCallback(callback: Observable<any>): Set<Observable<any>> {
    return this.beforeLogoutObservables.add(callback);
  }

  removeBeforeLogoutCallback(callback: Observable<any>): boolean {
    return this.beforeLogoutObservables.delete(callback);
  }

  removeParticipantCurrentCredentials() {
    this.authStorage.removeParticipantCurrentCredentials();
  }

  private logoutAndRemoveCredentials(
    role: Role,
    eventCode: string,
  ): Observable<any> {
    return this.logoutRequest().pipe(
      tap(() => this.removeCredentials(role, eventCode)),
    );
  }

  private removeCredentials(role: Role, eventCode: string): void {
    if (role === "participant") {
      this.authStorage.removeParticipantCredentialsForEvent(eventCode);
      this.authStorage.removeParticipantCurrentCredentials();
    }
    if (role === "moderator") {
      this.authStorage.removeModeratorCredentials();
    }
  }

  private logoutRequest(): Observable<any> {
    return this.http.post(API.auth.logout, "");
  }

  private revokeTokenRequest(): Observable<any> {
    return this.http.post(API.auth.revokeToken, "");
  }
}
