import { Event } from "@app/store/event/model";
import {
  EventsActions,
  EventsActionsTypes,
} from "@app/store/events/actions/events.actions";
import { eventsCreationDateComparator } from "@app/store/events/events-utils/events-utils";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { AdminActions } from "@app/store/admin/actions";

export interface EventsState {
  loading: boolean;
  duplicateActivitySuccess: boolean;
  currentEvents: EventsListState;
  expiredEvents: EventsListState;
  foundBySearchEvents: EventsListState;
  searchParams?: SearchParamsState;
}

export interface EventsListState extends EntityState<Event> {
  firstPageLoaded: boolean;
  fullyLoaded: boolean;
  pageNumber: number;
}

export interface SearchParamsState {
  isCurrent: boolean;
  amountOfEventsToLoad: number;
  searchValue: string;
  showEventsOnlyForCurrentModerator: boolean;
}

const foundBySearchEventsGroup = "foundBySearchEvents";

export const initialEventsListState = {
  firstPageLoaded: false,
  fullyLoaded: false,
  pageNumber: null,
};

export const currentEventsAdapter: EntityAdapter<Event> =
  createEntityAdapter<Event>({ sortComparer: eventsCreationDateComparator() });
const currentEventsInitialState: EventsListState =
  currentEventsAdapter.getInitialState(initialEventsListState);

export const expiredEventsAdapter: EntityAdapter<Event> =
  createEntityAdapter<Event>({ sortComparer: eventsCreationDateComparator() });
const expredEventsInitialState: EventsListState =
  expiredEventsAdapter.getInitialState(initialEventsListState);

export const foundBySearchEventsAdapter: EntityAdapter<Event> =
  createEntityAdapter<Event>({
    sortComparer: eventsCreationDateComparator(),
  });

const foundBySearchEventsInitialState: EventsListState =
  foundBySearchEventsAdapter.getInitialState(initialEventsListState);

export const eventsInitialState: EventsState = {
  loading: false,
  duplicateActivitySuccess: false,
  currentEvents: currentEventsInitialState,
  expiredEvents: expredEventsInitialState,
  foundBySearchEvents: foundBySearchEventsInitialState,
};

const getAdapterByExpired = (expired: boolean): EntityAdapter<Event> =>
  expired ? expiredEventsAdapter : currentEventsAdapter;

const getEventsGroupByExpired = (expired: boolean): string =>
  expired ? "expiredEvents" : "currentEvents";

const eventIsEqualSearchValue = (
  event: Event,
  searchValue: string | undefined,
) => {
  if (!searchValue || searchValue.trim() === "") {
    return true;
  }
  if (
    event.name.toLowerCase().indexOf(searchValue.toLowerCase().trim()) > -1 ||
    event.eventCode.toLowerCase().indexOf(searchValue.toLowerCase().trim()) > -1
  ) {
    return true;
  } else {
    return false;
  }
};

const addEventSuccess = (state: EventsState, event: Event): EventsState => {
  if (!event) {
    return { ...state };
  }
  const adapter = getAdapterByExpired(event.expired);
  const eventsGroup = getEventsGroupByExpired(event.expired);
  const eventsGroupState = state[eventsGroup];

  return state.searchParams?.isCurrent &&
    eventIsEqualSearchValue(event, state.searchParams.searchValue)
    ? {
        ...state,
        [eventsGroup]: adapter.addOne(event, eventsGroupState),
        foundBySearchEvents: foundBySearchEventsAdapter.addOne(
          event,
          state[foundBySearchEventsGroup],
        ),
        loading: false,
      }
    : {
        ...state,
        [eventsGroup]: adapter.addOne(event, eventsGroupState),
        loading: false,
      };
};

const loadPageableEventsSuccess = (
  state: EventsState,
  events: Event[],
  pageNumber: number,
  last: boolean,
  expired: boolean,
): EventsState => {
  const adapter: EntityAdapter<Event> = getAdapterByExpired(expired);
  const eventsGroup = getEventsGroupByExpired(expired);
  const newEventsGroupState: EventsListState = {
    ...state[eventsGroup],
    pageNumber,
    firstPageLoaded: true,
    fullyLoaded: last,
  };

  return {
    ...state,
    loading: false,
    [eventsGroup]: adapter.addMany(events, newEventsGroupState),
  };
};

const loadPageableSearchEventsSuccess = (
  state: EventsState,
  events: Event[],
  pageNumber: number,
  last: boolean,
): EventsState => {
  const newEventsGroupState: EventsListState = {
    ...state.foundBySearchEvents,
    fullyLoaded: last,
    firstPageLoaded: true,
    pageNumber,
  };

  return {
    ...state,
    loading: false,
    foundBySearchEvents: foundBySearchEventsAdapter.addMany(
      events,
      pageNumber === 0
        ? foundBySearchEventsAdapter.removeAll(newEventsGroupState)
        : newEventsGroupState,
    ),
  };
};

const saveInStoreSearchEventsPageNumber = (
  state: EventsState,
  pageNumber: number | null,
): EventsState => {
  if (pageNumber === null) {
    const newEventsGroupState: EventsListState = {
      ...state.foundBySearchEvents,
      pageNumber,
      firstPageLoaded: false,
      fullyLoaded: false,
    };
    return {
      ...state,
      foundBySearchEvents:
        foundBySearchEventsAdapter.removeAll(newEventsGroupState),
    };
  } else {
    const newEventsGroupState: EventsListState = {
      ...state.foundBySearchEvents,
      pageNumber,
    };
    return {
      ...state,
      foundBySearchEvents: newEventsGroupState,
    };
  }
};

const saveInStoreLoading = (
  state: EventsState,
  isLoading: boolean,
): EventsState => ({
  ...state,
  loading: isLoading,
});

const saveInStoreDuplicateActivitySuccess = (
  state: EventsState,
  value: boolean,
): EventsState => ({
  ...state,
  duplicateActivitySuccess: value,
  loading: false,
});

const setExpireSuccess = (
  state: EventsState,
  id: string,
  expired: boolean,
): EventsState => {
  const adapterToAdd: EntityAdapter<Event> = getAdapterByExpired(expired);
  const groupToAdd = getEventsGroupByExpired(expired);
  const groupToRemove = getEventsGroupByExpired(!expired);

  if (!state[groupToRemove].entities[id]) {
    return { ...state };
  }
  return {
    ...state,
    [groupToAdd]: adapterToAdd.addOne(
      { ...state[groupToRemove].entities[id], expired },
      state[groupToAdd],
    ),
    [groupToRemove]: adapterToAdd.removeOne(id, state[groupToRemove]),
    foundBySearchEvents: foundBySearchEventsAdapter.removeOne(
      id,
      state[foundBySearchEventsGroup],
    ),
    loading: false,
  };
};

const changeEventSettingsSuccess = (
  state: EventsState,
  event: Event,
): EventsState => {
  const { id, expired } = event;
  const adapter: EntityAdapter<Event> = getAdapterByExpired(expired);
  const eventsGroup = getEventsGroupByExpired(expired);
  const eventsGroupState = state[eventsGroup];

  return {
    ...state,
    [eventsGroup]: adapter.updateOne({ id, changes: event }, eventsGroupState),
    [foundBySearchEventsGroup]: adapter.updateOne(
      { id, changes: event },
      state[foundBySearchEventsGroup],
    ),
    loading: false,
  };
};

const changeEventThemeSuccess = (
  state: EventsState,
  event: Event,
): EventsState => {
  const { id, expired } = event;
  const adapter: EntityAdapter<Event> = getAdapterByExpired(expired);
  const eventsGroup = getEventsGroupByExpired(expired);
  const eventsGroupState = state[eventsGroup];

  return {
    ...state,
    [eventsGroup]: adapter.updateOne({ id, changes: event }, eventsGroupState),
    [foundBySearchEventsGroup]: adapter.updateOne(
      { id, changes: event },
      state[foundBySearchEventsGroup],
    ),
  };
};

const changeEventThemeLogoSuccess = (
  state: EventsState,
  event: Event,
): EventsState => {
  const { id, expired } = event;
  const adapter: EntityAdapter<Event> = getAdapterByExpired(expired);
  const eventsGroup = getEventsGroupByExpired(expired);
  const eventsGroupState = state[eventsGroup];

  return {
    ...state,
    [eventsGroup]: adapter.updateOne({ id, changes: event }, eventsGroupState),
    [foundBySearchEventsGroup]: foundBySearchEventsAdapter.updateOne(
      { id, changes: event },
      state[foundBySearchEventsGroup],
    ),
  };
};

const deleteEventThemeLogoSuccess = (
  state: EventsState,
  event: Event,
): EventsState => {
  const { id, expired } = event;
  const adapter: EntityAdapter<Event> = getAdapterByExpired(expired);
  const eventsGroup = getEventsGroupByExpired(expired);
  const eventsGroupState = state[eventsGroup];

  return {
    ...state,
    [eventsGroup]: adapter.updateOne({ id, changes: event }, eventsGroupState),
    [foundBySearchEventsGroup]: foundBySearchEventsAdapter.updateOne(
      { id, changes: event },
      state[foundBySearchEventsGroup],
    ),
  };
};

const deleteEventSuccess = (
  state: EventsState,
  id: string,
  expired: boolean,
): EventsState => {
  const adapter: EntityAdapter<Event> = getAdapterByExpired(expired);
  const eventsGroup = getEventsGroupByExpired(expired);
  const eventsGroupState = state[eventsGroup];

  return {
    ...state,
    [eventsGroup]: adapter.removeOne(id, eventsGroupState),
    foundBySearchEvents: foundBySearchEventsAdapter.removeOne(
      id,
      state[foundBySearchEventsGroup],
    ),
    loading: false,
  };
};

const saveStateSearchParams = (
  state: EventsState,
  isCurrent: boolean,
  amountOfEventsToLoad: number,
  searchValue: string,
  showEventsOnlyForCurrentModerator: boolean,
): EventsState => ({
  ...state,
  searchParams: {
    isCurrent,
    amountOfEventsToLoad,
    searchValue,
    showEventsOnlyForCurrentModerator,
  },
});

const reducer = createReducer<EventsState>(
  eventsInitialState,

  on(
    EventsActions.addEvent,
    (state): EventsState => ({ ...state, loading: true }),
  ),

  on(
    EventsActions.addEventSuccess,
    (state, action): EventsState => addEventSuccess(state, action.event),
  ),

  on(
    EventsActions.saveInStoreSearchEventsPageNumber,
    (state, action): EventsState =>
      saveInStoreSearchEventsPageNumber(state, action.pageNumber),
  ),

  on(
    EventsActions.loadPageableEventsSuccess,
    (state, action): EventsState =>
      loadPageableEventsSuccess(
        state,
        action.info.events,
        action.info.pageNumber,
        action.info.last,
        action.expired,
      ),
  ),

  on(
    EventsActions.loadPageableSearchEvents,
    (state): EventsState => ({
      ...state,
      loading: true,
    }),
  ),

  on(
    EventsActions.loadPageableSearchEventsSuccess,
    (state, action): EventsState =>
      loadPageableSearchEventsSuccess(
        state,
        action.info.events,
        action.info.pageNumber,
        action.info.last,
      ),
  ),

  on(
    EventsActions.saveInStoreLoading,
    (state, action): EventsState => saveInStoreLoading(state, action.loading),
  ),

  on(
    EventsActions.duplicateActivity,
    (state): EventsState => ({ ...state, loading: true }),
  ),

  on(
    EventsActions.saveInStoreDuplicateActivitySuccess,
    (state, action): EventsState =>
      saveInStoreDuplicateActivitySuccess(
        state,
        action.duplicateActivitySuccess,
      ),
  ),

  on(
    EventsActions.setExpire,
    (state): EventsState => ({ ...state, loading: true }),
  ),

  on(
    EventsActions.setExpireSuccess,
    (state, action): EventsState =>
      setExpireSuccess(state, action.event.id, action.expireRequest.expired),
  ),

  on(
    EventsActions.changeEventSettings,
    (state): EventsState => ({ ...state, loading: true }),
  ),

  on(
    EventsActions.changeEventSettingsSuccess,
    (state, action): EventsState =>
      changeEventSettingsSuccess(state, action.event),
  ),

  on(
    EventsActions.changeEventThemeSuccess,
    (state, action): EventsState =>
      changeEventThemeSuccess(state, action.event),
  ),

  on(
    EventsActions.duplicateEvent,
    (state): EventsState => ({ ...state, loading: true }),
  ),

  on(
    EventsActions.deleteEventThemeLogoSuccess,
    (state, action): EventsState =>
      deleteEventThemeLogoSuccess(state, action.event),
  ),

  on(
    EventsActions.changeEventThemeLogoSuccess,
    (state, action): EventsState =>
      changeEventThemeLogoSuccess(state, action.event),
  ),

  on(
    EventsActions.deleteEvent,
    (state): EventsState => ({ ...state, loading: true }),
  ),

  on(
    EventsActions.deleteEventSuccess,
    (state, action): EventsState =>
      deleteEventSuccess(state, action.event.id, action.event.expired),
  ),

  on(
    EventsActions.saveStateSearchParams,
    (state, action): EventsState =>
      saveStateSearchParams(
        state,
        action.isCurrent,
        action.amountOfEventsToLoad,
        action.searchValue,
        action.showEventsOnlyForCurrentModerator,
      ),
  ),

  on(AdminActions.setNewOwnerSuccess, (state, { eventId, moderatorId }) => {
    const eventIndex = state.currentEvents.ids.findIndex(id => id === eventId);

    if (eventIndex === -1) {
      return state;
    }

    const eventIdToUpdate = state.currentEvents.ids[eventIndex];

    const updatedEvent = {
      ...state.currentEvents.entities[eventIdToUpdate],
      moderatorId: moderatorId
    };

    const updatedEvents = {
      ...state.currentEvents,
      entities: {
        ...state.currentEvents.entities,
        [eventIdToUpdate]: updatedEvent
      }
    };
    
    return {
      ...state,
      currentEvents: updatedEvents
    };
  }),
);

export const eventsReducer = (
  state: EventsState | undefined,
  action: EventsActionsTypes,
): EventsState => reducer(state, action);

export const eventsState = (state: EventsState) => state;
export const loading = (state: EventsState) => state.loading;
export const duplicateActivitySuccess = (state: EventsState) =>
  state.duplicateActivitySuccess;
export const currentEventsState = (state: EventsState) => state.currentEvents;
export const expiredEventsState = (state: EventsState) => state.expiredEvents;
export const foundBySearchEventsState = (state: EventsState) =>
  state.foundBySearchEvents;
export const getSearchParams = (state: EventsState) => state.searchParams;

export const selectAllCurrentEvents =
  currentEventsAdapter.getSelectors(currentEventsState).selectAll;

export const selectAllExpiredEvents =
  expiredEventsAdapter.getSelectors(expiredEventsState).selectAll;

export const selectAllFoundBySearchEvents =
  foundBySearchEventsAdapter.getSelectors(foundBySearchEventsState).selectAll;
