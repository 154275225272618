import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { DialogService } from "@tellsy/common/services/dialog.service";
import { TermsTextService } from "@tellsy/common/services/terms-text.service";

@Component({
  selector: "tellsy-legal-info",
  templateUrl: "./legal-info.component.html",
  styleUrls: ["./legal-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalInfoComponent implements OnInit {
  constructor(
    private termsTextService: TermsTextService,
    private dialogService: DialogService,
  ) {}

  ngOnInit() {}

  openPersonalDataDialog() {
    this.termsTextService
      .getAgreementToProcessingPersonalData()
      .subscribe((text) => {
        this.dialogService.openLongContentDialog(
          "Согласие на обработку Персональных Данных",
          text,
        );
      });
  }

  openConfidentialPoliticsDialog() {
    this.termsTextService.getPersonalDataPolicy().subscribe((text) => {
      this.dialogService.openLongContentDialog(
        "Политика обработки Персональных Данных",
        text,
      );
    });
  }

  openTermsOfServiceDialog() {
    this.termsTextService.getUserAgreement().subscribe((text) => {
      this.dialogService.openLongContentDialog(
        "Пользовательское соглашение",
        text,
      );
    });
  }
}
