import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiResponse } from "@app/models/common.models";
import { AuthSuccessResponse, Role, UIRole, User } from "@app/store/auth/model";
import { RegisterInfo } from "@app/store/moderator/models/moderator-model";
import { UserActions } from "@app/store/user/actions/user.actions";
import { Store } from "@ngrx/store";
import { ParticipantAuthState } from "@tellsy/auth-facade";
import { combineLatest, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { AuthService } from "./services/auth.service";
import { AuthStorage, UserCredentials } from "./services/auth.storage";
import { ParticipantAuthActions } from "./store/participant-auth.actions";
import {
  selectEventCode,
  selectParticipantAuthState,
  selectPending,
  selectUsername,
  selectUsernameAndEventCode,
} from "./store/participant-auth.selectors";
import { accessTokenExpired } from "./utils";

@Injectable({
  providedIn: "root",
})
export class AuthFacade {
  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private authStorage: AuthStorage,
    private authService: AuthService,
  ) {}

  getParticipantAuthState$(): Observable<ParticipantAuthState> {
    return this.store.select(selectParticipantAuthState);
  }

  getPending$(): Observable<boolean> {
    return this.store.select(selectPending);
  }

  selectParticipantAuthEventCode$(): Observable<string> {
    return this.store.select(selectEventCode);
  }

  selectParticipantAuthUsername$(): Observable<string> {
    return this.store.select(selectUsername);
  }

  selectUsernameAndEventCode$(): Observable<{
    username: string;
    eventCode: string;
  }> {
    return this.store.select(selectUsernameAndEventCode);
  }

  showTermsOfService(): boolean {
    return !!this.route.snapshot.queryParams.agreement;
  }

  showConfidentialPolitics(): boolean {
    return !!this.route.snapshot.queryParams.politics;
  }

  setPassword(token: string, password: string): Observable<ApiResponse> {
    return this.authService.setPasswordAfterRegistration(token, password).pipe(
      tap(() => {
        this.router.navigate(["/login/moderator"]);
      }),
    );
  }

  getRegisterInfo$(token: string): Observable<RegisterInfo> {
    return this.authService.getRegisterInfo(token);
  }

  refreshAuthTokenDependingOnUrl$(): Observable<UserCredentials> {
    const credentials = this.getStoredCredentialsDependingOnCurrentRoute();
    const userRole = this.getUserRole();

    if (credentials?.refreshToken) {
      return this.refreshAuthToken$(credentials, userRole);
    } else {
      console.error("refreshAuthTokenDependingOnUrl$", "No credentials found");

      return this.getNewTokenAndRetry$();
    }
  }

  getUser$(role: Role): Observable<User | null> {
    const storedCredentials =
      role === "participant"
        ? this.authStorage.getCurrentParticipantCredentials()
        : this.authStorage.getModeratorCredentials();

    if (!storedCredentials) {
      return of(null);
    }

    return this.authService.getUser(storedCredentials.accessToken).pipe(
      catchError((errorResponse) => {
        if (accessTokenExpired(errorResponse)) {
          console.error("getUser$", errorResponse);
          return this.refreshAuthToken$(storedCredentials, role).pipe(
            switchMap((credentials) =>
              this.authService.getUser(credentials.accessToken),
            ),
          );
        } else {
          throwError(() => new Error("Error getting user"));
        }
      }),
    );
  }

  // login stuff

  loginModerator(
    username: string,
    password: string,
  ): Observable<AuthSuccessResponse> {
    return this.authService.loginModerator(username, password).pipe(
      tap((res) => {
        this.authStorage.storeModeratorCredentials(
          this.createUserCredentials(res, { username, eventCode: null }),
        );
        this.router.navigate(["moderator"]);
      }),
    );
  }

  getAccessTokenForCurrentUrl() {
    const credentials = this.getStoredCredentialsDependingOnCurrentRoute();
    return credentials ? credentials.accessToken : null;
  }

  // participant login stuff

  loginParticipantAndNavigate(
    eventCode: string,
    username: string | undefined,
    subEventId?: string,
  ): void {
    this.saveInStorePending(true);

    eventCode = eventCode?.trim();
    username = username?.trim();

    const storedCredentials =
      this.authStorage.getParticipantCredentialsForEvent(eventCode);

    this.store.dispatch(
      ParticipantAuthActions.saveInStoreFieldValues({
        eventCode,
        username,
        subEventId,
      }),
    );

    this.areCredentialsValid$(storedCredentials)
      .pipe(
        take(1),
        switchMap((valid) => {
          if (valid) {
            return of(storedCredentials);
          }

          return this.getAndStoreNewToken$(eventCode, username);
        }),
      )
      .subscribe(() => this.navigateToParticipant(subEventId));
  }

  setUserState({ role, id, name }: { role: UIRole; id: string; name: string }) {
    this.store.dispatch(UserActions.setUser({ role, id, name }));
  }

  saveInStorePending(pending: boolean) {
    this.store.dispatch(ParticipantAuthActions.saveInStorePending({ pending }));
  }

  getAndStoreNewToken$(
    eventCode: string | undefined | null,
    username: string | undefined | null,
  ): Observable<UserCredentials> {
    if (!eventCode) {
      return this.throwErrAndNavigateToLogin$("EventCode not found");
    }

    eventCode = eventCode?.trim();
    username = username?.trim();

    this.saveInStorePending(true);

    return this.authService.loginParticipant(eventCode, username).pipe(
      map((response) => {
        this.saveInStorePending(false);
        const credentials = this.createUserCredentials(response, {
          eventCode,
          username,
        });
        this.authStorage.storeParticipantCredentials(credentials);
        return credentials;
      }),
      catchError((error) => {
        console.error("getAndStoreNewToken$", error);
        this.saveInStorePending(false);
        this.onLoginAttemptError(error);

        return this.throwErrAndNavigateToLogin$(error);
      }),
    );
  }

  getStoredCredentialsDependingOnCurrentRoute() {
    if (this.isOnModeratorUrl()) {
      return this.authStorage.getModeratorCredentials();
    } else {
      return this.authStorage.getCurrentParticipantCredentials();
    }
  }

  getCurrentParticipantCredentials() {
    return this.authStorage.getCurrentParticipantCredentials();
  }

  getNewTokenAndRetry$(): Observable<UserCredentials> {
    const storedCredentials =
      this.getStoredCredentialsDependingOnCurrentRoute();

    return combineLatest([
      of(storedCredentials),
      this.selectUsernameAndEventCode$(),
    ]).pipe(
      map(([credentials, credentialsFromAppState]) => {
        if (credentials?.username && credentials?.eventCode) {
          return {
            username: credentials.username,
            eventCode: credentials.eventCode,
          };
        } else {
          return {
            username: credentialsFromAppState.username,
            eventCode: credentialsFromAppState.eventCode,
          };
        }
      }),
      switchMap(({ eventCode, username }) =>
        this.getAndStoreNewToken$(eventCode, username),
      ),
    );
  }

  private areCredentialsValid$(
    credentials: UserCredentials,
  ): Observable<boolean> {
    if (!credentials) {
      return of(false);
    }
    this.authStorage.storeParticipantCredentials(credentials);
    return this.authService.getUser(credentials.accessToken).pipe(
      map((user) => !!user),
      catchError(() => of(false)),
    );
  }

  private navigateToParticipant(subEventId?: string) {
    if (subEventId) {
      this.router.navigate(["participant"], {
        queryParams: { subEventId },
        replaceUrl: true,
      });
    } else {
      this.router.navigate(["participant"], {
        replaceUrl: true,
      });
    }
  }

  private throwErrAndNavigateToLogin$(error: string): Observable<never> {
    this.router.navigate(["/login"], {
      queryParamsHandling: "merge",
    });
    console.error(error);
    return throwError(() => new Error(error));
  }

  private createUserCredentials(
    response: AuthSuccessResponse,
    data: { eventCode: string | null; username: string },
  ): UserCredentials {
    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      eventCode: data.eventCode ?? null,
      username: data.username ?? null,
    };
  }

  private onLoginAttemptError(response: {
    error: {
      error_description?: string;
      errorCode?: string;
      params?: { usernameFieldName: string };
    };
    status: number;
  }) {
    const error = response.error;
    if (response.status === 0) {
      this.store.dispatch(
        ParticipantAuthActions.saveInStoreEventCodeErrorType({
          errorType: "networkConnectionLost",
        }),
      );
      return;
    }

    if (error?.error_description === "Bad credentials") {
      this.store.dispatch(
        ParticipantAuthActions.saveInStoreUsernameErrorType({
          errorType: "wrongUsername",
        }),
      );
      return;
    }

    if (!error?.errorCode) {
      this.dispatchUnknownEventFieldError();
      return;
    }

    switch (error.errorCode) {
      case "auth-service.alreadyLoggedIn":
        this.store.dispatch(
          ParticipantAuthActions.saveInStoreEventCodeErrorType({
            errorType: "alreadyLoggedIn",
          }),
        );
        break;

      case "auth-service.nullUsername":
        if (error?.params?.usernameFieldName) {
          this.store.dispatch(
            ParticipantAuthActions.saveInStoreUsernameFieldName({
              fieldName: error.params.usernameFieldName,
            }),
          );
        }

        break;

      case "auth-service.participantLimitIsReached":
        this.store.dispatch(
          ParticipantAuthActions.saveInStoreEventCodeErrorType({
            errorType: "participantLimitIsReached",
          }),
        );
        break;

      case "auth-service.participantTotalLimitIsReached":
        this.store.dispatch(
          ParticipantAuthActions.saveInStoreEventCodeErrorType({
            errorType: "participantTotalLimitIsReached",
          }),
        );
        break;

      case "auth-service.eventNotFound":
        this.store.dispatch(
          ParticipantAuthActions.saveInStoreEventCodeErrorType({
            errorType: "eventNotFound",
          }),
        );

        break;

      case "auth-service.licenseExpired":
        this.store.dispatch(
          ParticipantAuthActions.saveInStoreEventCodeErrorType({
            errorType: "licenseExpired",
          }),
        );
        break;

      default:
        this.dispatchUnknownEventFieldError();
        break;
    }
  }

  private dispatchUnknownEventFieldError() {
    this.store.dispatch(
      ParticipantAuthActions.saveInStoreEventCodeErrorType({
        errorType: "unknown",
      }),
    );
  }

  private refreshAuthToken$(
    credentials: UserCredentials,
    userRole: Role,
  ): Observable<UserCredentials> {
    if (!credentials?.refreshToken) {
      return this.getNewTokenAndRetry$();
    }

    return this.authService
      .refreshAuthToken(credentials.refreshToken, userRole)
      .pipe(
        map((response) => {
          const newCredentials = this.createUserCredentials(response, {
            eventCode: credentials.eventCode,
            username: credentials.username,
          });
          this.authStorage.storeParticipantCredentials(newCredentials);
          return newCredentials;
        }),
        catchError(() => {
          return this.getNewTokenAndRetry$();
        }),
      );
  }

  private getUserRole() {
    if (this.isOnModeratorUrl()) {
      return "moderator";
    } else {
      return "participant";
    }
  }

  private isOnModeratorUrl() {
    const url = window.location.pathname;
    return (
      url.startsWith("/moderator") ||
      url.startsWith("/login/moderator") ||
      url.startsWith("/projector")
    );
  }
}
