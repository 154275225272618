import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SliceByLengthPipe } from "./slice-by-length.pipe";

@NgModule({
  declarations: [SliceByLengthPipe],
  exports: [SliceByLengthPipe],
  imports: [CommonModule],
})
export class SliceByLengthModule {}
