import { Injectable } from "@angular/core";

export enum ErrorType {
  API_UNAVAILABLE,
}

@Injectable({
  providedIn: "root",
})
export class ErrorsService {
  constructor() {}

  getErrorMessage(error: ErrorType) {
    if (error === ErrorType.API_UNAVAILABLE) {
      return "Сервер не доступен, проверьте подключение к интернету";
    } else {
      return "Ошибка приложения";
    }
  }
}
