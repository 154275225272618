import { ComponentType } from "@angular/cdk/portal";
import { Injectable, ValueProvider } from "@angular/core";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { SafeUrl } from "@angular/platform-browser";
import { TextDialogComponent } from "@app/modules/shared/dialogs/text-dialog/text-dialog.component";
import { TextEditDialogComponent } from "@app/modules/shared/dialogs/text-edit-dialog/text-edit-dialog.component";
import { TextInputDialogComponent } from "@app/modules/shared/dialogs/text-input-dialog/text-input-dialog.component";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from "@shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ContentClosedDialogComponent } from "@shared/dialogs/content-closed-dialog/content-closed-dialog.component";
import { ImageDialogComponent } from "@shared/dialogs/image-dialog/image-dialog.component";
import { LongContentDialogComponent } from "@shared/dialogs/long-content-dialog/long-content-dialog.component";
import {
  MultipleChoiceDialogComponent,
  MultipleChoiceDialogItem,
} from "@shared/dialogs/multiple-select-dialog/multiple-choice-dialog.component";
import {
  SelectDialogComponent,
  SelectDialogData,
} from "@shared/dialogs/select-dialog/select-dialog.component";
import { TermsTextService } from "@tellsy/common/services/terms-text.service";
import { SelectOption } from "@tellsy/theme/select/select/select.component";
import { Observable } from "rxjs";
import { NumberEditDialogComponent } from "@shared/dialogs/number-edit-dialog/number-edit-dialog.component";

export interface TextInputDialogConfig {
  title: string;
  submitBtnText: string;
  inputText?: string;
  maxLength?: number;
  placeholder?: string;
  subtitle?: string;
}

export interface NumberInputDialogConfig {
  title: string;
  submitBtnName: string;
  errMessage:string
}

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(
    private dialog: MatDialog,
    private termsTextService: TermsTextService,
  ) {}

  openTextInputDialog(config: TextInputDialogConfig): Observable<string> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = config;
    const dialogRef: MatDialogRef<TextInputDialogComponent> = this.dialog.open(
      TextInputDialogComponent,
      dialogConfig,
    );
    return dialogRef.afterClosed();
  }

  openNumberInputDialog(config: NumberInputDialogConfig): Observable<number> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = config;
    const dialogRef: MatDialogRef<NumberEditDialogComponent> = this.dialog.open(
      NumberEditDialogComponent,
      dialogConfig,
    );
    return dialogRef.afterClosed();
  }

  openTextEditDialog(config: TextInputDialogConfig): Observable<string> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = config;
    dialogConfig.restoreFocus = false
    const dialogRef: MatDialogRef<TextEditDialogComponent> = this.dialog.open(
      TextEditDialogComponent,
      dialogConfig,
    );
    return dialogRef.afterClosed();
  }

  openTextDialog(
    title: string,
    content: string,
    extendConfig: MatDialogConfig = null,
  ): MatDialogRef<TextDialogComponent> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title,
      content,
    };
    return this.dialog.open(
      TextDialogComponent,
      extendConfig ? { ...dialogConfig, ...extendConfig } : dialogConfig,
    );
  }

  openConfirmationDialog(
    confirmationData: ConfirmationDialogData,
  ): Observable<boolean> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = confirmationData;
    dialogConfig.maxWidth = "90vw";
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> =
      this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    return dialogRef.afterClosed();
  }

  openLongContentDialog(title: string, content: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title,
      content,
    };
    dialogConfig.panelClass = ["padding-0"];
    dialogConfig.maxWidth = "90vw";
    dialogConfig.maxHeight = "90vh";
    dialogConfig.autoFocus = false;
    const dialogRef: MatDialogRef<LongContentDialogComponent> =
      this.dialog.open(LongContentDialogComponent, dialogConfig);
    return dialogRef.afterClosed();
  }

  openImage(imgPath: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      imgPath,
    };
    dialogConfig.width = "100vw";
    dialogConfig.width = "100vw";
    this.dialog.open(ImageDialogComponent, dialogConfig);
  }

  openImage$(imgPath$: Observable<SafeUrl>) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      imgPath$,
    };
    dialogConfig.width = "100vw";
    dialogConfig.width = "100vw";
    this.dialog.open(ImageDialogComponent, dialogConfig);
  }

  openComponentDialog<T = any>(
    component: ComponentType<T>,
    config?: MatDialogConfig,
  ): MatDialogRef<T> {
    if (!config) {
      config = new MatDialogConfig();
    }
    return this.dialog.open(component, config);
  }

  openMultipleChoiceDialog(
    title: string,
    variants: MultipleChoiceDialogItem[],
    chooseNoOneVariant?: string,
  ) {
    const config = new MatDialogConfig();
    config.data = { title, variants, chooseNoOneVariant };
    const dialog = this.dialog.open(MultipleChoiceDialogComponent, config);
    return dialog.afterClosed();
  }

  openSelectDialog<T extends string | number>(
    title: string,
    options: SelectOption<T>[],
    nullOptionLabel: string,
    selectedValue: any,
    btnText: string,
  ): Observable<T> {
    const config = new MatDialogConfig();
    config.data = {
      title,
      options,
      nullOptionLabel,
      selectedValue,
      btnText,
    } as SelectDialogData;
    const dialog = this.dialog.open(SelectDialogComponent, config);
    return dialog.afterClosed();
  }

  openContentClosedDialog(
    title: string,
  ): MatDialogRef<ContentClosedDialogComponent> {
    const config = new MatDialogConfig();
    config.data = { title };
    return this.dialog.open(ContentClosedDialogComponent, config);
  }

  openAgreementToProcessingPersonalDataDialog(header: string): void {
    this.termsTextService
      .getAgreementToProcessingPersonalData()
      .subscribe((text) => this.openLongContentDialog(header, text));
  }

  openPersonalDataPolicyDialog(header: string): void {
    this.termsTextService.getPersonalDataPolicy().subscribe((text) => {
      this.openLongContentDialog(header, text);
    });
  }

  openUserAgreementDialog(header: string): void {
    this.termsTextService.getUserAgreement().subscribe((text) => {
      this.openLongContentDialog(header, text);
    });
  }

  close(dialogId: string) {
    const dialog = this.dialog.getDialogById(dialogId);
    if (dialog) {
      dialog.close();
    }
  }
}

const DialogServiceMock = {};

export const DialogServiceMockProvider: ValueProvider = {
  provide: DialogService,
  useValue: DialogServiceMock,
};
