import { ArgCountType, AskQuestionActivitySettings, Question, Speaker } from ".";
import { VoteType } from "./ask-question-question.model";

export interface AskQuestionActivity {
  id: string;
  eventId: string;
  mode: CollectDataMode;
  speakers: Speaker[];
  questions: Question[];
  settings: AskQuestionActivitySettings;
  votesMap: VotesMap; // map of participant voted questions
  argumentsCount: ArgCountType[];
}


export interface VotesMap {
  [questionId: string]: VoteType;
}

export interface ArgumentsCountMap {
  [questionId: string]: ArgCountType;
}

export enum CollectDataMode {
  ASK_QUESTION = "ASK_QUESTION",
  GATHER_OPINIONS = "GATHER_OPINIONS",
  CHAT = "CHAT",
  INSTRUCTION = "INSTRUCTION",
}

export enum SortQuestionsBy {
  NEW = "NEW",
  OLD = "OLD",
  LIKE_ASC = "LIKE_ASC",
  LIKE_DESC = "LIKE_DESC",
  RATING = "RATING",
  ANSWERED = "ANSWERED",
  MY = "MY",
  AI = "AI",
}

export enum gatherOptionsTabs {
  PROS = "PROS",
  CONS = "CONS",
  COMMENTS = "COMMENTS"
}
