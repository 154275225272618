import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LogoutService } from "@app/services/auth/logout.service";
import {
  LogoutActionTypes,
  LogoutSuccess,
  SendLogoutRequest,
} from "@app/store/logout/logout.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class LogoutEffects {
  SendLogoutRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LogoutActionTypes.sendLogoutRequest),
      switchMap(({ payload }: SendLogoutRequest) =>
        this.logoutService.logout(
          payload.removeCredentials,
          payload.role,
          payload.eventCode,
        ),
      ),
      map(() => new LogoutSuccess()),
    ),
  );

  LogoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LogoutActionTypes.logoutSuccess),
        tap(() => {
          const isModerator = this.router.url.startsWith("/moderator");
          if (!isModerator) {
            this.logoutService.removeParticipantCurrentCredentials();
          }
          const navigationUrl = isModerator ? "login/moderator" : "login";
          this.router.navigate(navigationUrl.split("/"), {
            queryParams: {},
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private logoutService: LogoutService,
    private router: Router,
  ) {}
}
