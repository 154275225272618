import { Injectable } from "@angular/core";
import { environment } from "@tellsy/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  constructor() {}

  getEnvName(): string {
    return environment.envName;
  }

  getIsProduction(): boolean {
    return environment.production;
  }
}
