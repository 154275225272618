export enum AuthService {
  USER_NOT_FOUND = "auth-service.userNotFound",
  USER_ALREADY_EXISTS = "auth-service.userAlreadyExists",
  PASSWORD_IS_INCORRECT = "auth-service.passwordIsIncorrect",
}

export enum ParticipantService {
  PARTICIPANT_NOT_FOUND = "participant-service.participantNotFound",
}

export enum EventService {
  EVENT_NOT_FOUND = "event-service.eventNotFound",
}

export enum AskQuestionCode {
  SPEAKER_NOT_ACTIVE = "",
}

export const ErrorCode = {
  AuthService,
  ParticipantService,
  EventService,
  AskQuestionCode,
};
