import { createReducer, on } from "@ngrx/store";
import {
  EventSuccessActions,
  ProjectorSuccessActions,
  ProjectorWebsocketActions,
} from "../actions";
import { Projector } from "../model";

export interface ProjectorState extends Projector {}

const initialState: ProjectorState = null;

const updateProjector = (
  state: ProjectorState,
  action: Partial<Projector>,
): ProjectorState => ({
  ...state,
  ...action,
});

export const projectorReducer = createReducer<ProjectorState>(
  initialState,

  on(EventSuccessActions.clearState, (): ProjectorState => null),
  on(
    EventSuccessActions.loadEvent,
    (state, { dto }): ProjectorState => updateProjector(state, dto.projector),
  ),
  on(
    ProjectorSuccessActions.load,
    (state, { projector }): ProjectorState => updateProjector(state, projector),
  ),

  on(
    ProjectorSuccessActions.setActivity,
    (state, { dto }): ProjectorState => updateProjector(state, dto),
  ),

  on(
    ProjectorSuccessActions.setTimerFullscreen,
    (state, { dto }): ProjectorState => updateProjector(state, dto),
  ),

  on(
    ProjectorWebsocketActions.setActivity,
    (
      state,
      { activityId, activityType, queryParams, teamIds, eventId },
    ): ProjectorState =>
      updateProjector(state, {
        currentActivityId: activityId,
        currentActivityType: activityType,
        queryParams,
        teamIds,
      }),
  ),

  on(
    ProjectorWebsocketActions.setTimerFullscreen,
    (state, { showFullscreenTimer }): ProjectorState =>
      updateProjector(state, { showFullscreenTimer }),
  ),
);
