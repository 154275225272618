<div class="wrapper">
  <button mat-icon-button color="warn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
  <img
    *ngIf="imgSrc$ | async as imgSrc; else loading"
    [src]="imgSrc"
    alt="image in dialog"
  />
</div>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
