import { createActionGroup, props } from "@ngrx/store";
import { Session_lightDtoModel } from "@app/store/event/model/dto/session_light.dto.model";

export const LightSessionActions = createActionGroup({
  source: "LightSessionModel",
  events: {
    "Start LightSession": props<{ eventId: string; }>(),
    "Start LightSession Success": props<{ timers: Session_lightDtoModel[] }>(),
    "Finish LightSession": props<{ eventId: string; }>(),
    "Finish LightSession Success": props<{ timers: Session_lightDtoModel[] }>(),
    "Remaining Seconds": props<{ eventId: string; }>(),
    "Remaining Seconds Success": props<{ timers: number }>(),
  }
});
