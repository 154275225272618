import { Injectable } from "@angular/core";
import {
  DEFAULT_TELLSY_THEME_COLORS,
  EventThemeColor,
} from "@app/models/event-theme";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private readonly prefix = "--tellsy-";

  constructor() {}

  setThemeColors(
    colors: EventThemeColor[] = DEFAULT_TELLSY_THEME_COLORS,
  ): void {
    if (!colors || colors.length === 0) {
      return;
    }

    colors.forEach(({ name, value }) => this.setCssProperty(name, value));
  }

  private setCssProperty(name: string, value: string): void {
    document.documentElement.style.setProperty(`${this.prefix}${name}`, value);
  }
}
