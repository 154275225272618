import { RouterStateUrl } from "@app/configs/custom-router-serializer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

const selectRouterState = createFeatureSelector<{ state: RouterStateUrl }>(
  "router",
);

const selectState = createSelector(selectRouterState, (state) =>
  state && state.state ? state.state : null,
);

const selectUrl = createSelector(selectState, (state) =>
  state && state.url ? state.url : null,
);

const selectRouterActivityId = createSelector(selectState, (state) =>
  state && state.params ? (state.params.activityId as string) : null,
);
const selectRouterActivityType = createSelector(selectState, (state) =>
  state && state.params && state.activityType ? state.activityType : null,
);

export const RouterQuery = {
  selectUrl,
  selectRouterActivityId,
  selectRouterActivityType,
};
