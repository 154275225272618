<mat-form-field
  class="baseTheme"
  [class.blackTheme]="dark"
  [class.underline-error]="hint"
  [hideRequiredMarker]="true"
>
  <input
    [style.text-transform]="upperCase ? 'uppercase' : 'none'"
    [spellcheck]="spellcheck"
    matInput
    [ngModel]="value"
    (ngModelChange)="valueChanges($event)"
    (keyup.enter)="keyEnter.emit()"
    (keydown)="keyDown.emit()"
    [placeholder]="label"
    [style.font-weight]="bold ? 'bold' : null"
    autocomplete="off"
    [type]="type"
    [required]="required"
    [disabled]="disabled"
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>
