<h2 mat-dialog-title *ngIf="title">{{ title }}</h2>

<mat-dialog-content>
  <!--CHECKBOX SELECT-->
  <mat-form-field>
    <mat-select multiple [(value)]="selected">
      <ng-container *ngFor="let variant of variants">
        <mat-option
          [value]="variant.value"
          *ngIf="variant.type === types.CHECKBOX"
        >
          {{ variant.text }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <mat-divider></mat-divider>

  <!--MENU-->
  <mat-menu>
    <ng-container *ngFor="let variant of variants">
      <div
        (click)="sendMenuVariants(variant.value)"
        mat-menu-item
        *ngIf="variant.type === types.MENU"
      >
        {{ variant.text }}
      </div>
    </ng-container>
  </mat-menu>
</mat-dialog-content>

<!--BUTTONS-->
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ "shared.dialogs.close" | translate }}
  </button>
  <button
    mat-raised-button
    [disabled]="!selected.length"
    (click)="sendCheckBoxVariants(selected)"
  >
    {{ "shared.dialogs.show" | translate }}
  </button>
</mat-dialog-actions>
