import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

@Component({
  selector: "tellsy-internet-offline-info",
  templateUrl: "./internet-offline-info.component.html",
  styleUrls: ["./internet-offline-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternetOfflineInfoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
