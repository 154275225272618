import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { InternetUtils } from "@app/utils/internet";
import { TranslateService } from "@ngx-translate/core";
import { promptCookies } from "@tellsy/auth-facade";
import { LanguagesService } from "@tellsy/common/services/languages.service";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { Observable, Subject, takeUntil } from "rxjs";
import { ThemeService } from "./services/theme/theme.service";

@Component({
  selector: "tellsy-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  isOnline$: Observable<boolean>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private languages: LanguagesService,
    private themeService: ThemeService,
    private translate: TranslateService,
    private ccService: NgcCookieConsentService,
  ) {
    this.languages.initLanguages();
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.autoAdjustVhForMobileBrowsers();
  }

  ngOnInit(): void {
    this.autoAdjustVhForMobileBrowsers();
    this.isOnline$ = InternetUtils.online$;
    this.themeService.setThemeColors();
    this.setCookiesPromptMessage();
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setCookiesPromptMessage();
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private setCookiesPromptMessage(): void {
    this.translate //
      .get(["auth.cookies.message", "auth.cookies.allow"])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.ccService.getConfig().content =
          this.ccService.getConfig().content || {};
        this.ccService.getConfig().content.message =
          data["auth.cookies.message"];
        this.ccService.getConfig().content.allow = data["auth.cookies.allow"];

        this.ccService.destroy(); // remove previous cookie bar (with default messages)
        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
        promptCookies(this.ccService, this.destroy$);
      });
  }

  private autoAdjustVhForMobileBrowsers(): void {
    const adjust = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    adjust();
  }
}
