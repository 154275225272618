import { Injectable, ValueProvider } from "@angular/core";
import { EventTheme } from "@app/models/event-theme";
import { EventExpiredRequest } from "@app/models/event.models";
import { Event, EventSettingsDto } from "@app/store/event/model";
import { EventsActions } from "@app/store/events/actions/events.actions";
import { EventsService } from "@app/store/events/services/events.service";
import { Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { filter, map, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { EventsQuery } from "../selectors";

@Injectable({
  providedIn: "root"
})
export class EventsFacade {
  constructor(private store: Store, private eventsService: EventsService) {

  }

  loadPageableEvents(expired: boolean, pageNumber: number, pageSize: number) {
    this.store.dispatch(
      EventsActions.loadPageableEvents({
        expired,
        pageNumber,
        pageSize
      })
    );
  }

  loadPageableSearchEvents(
    expired: boolean,
    pageNumber: number,
    pageSize: number,
    search: string,
    showEventsOnlyForCurrentModerator: boolean
  ) {
    this.store.dispatch(
      EventsActions.loadPageableSearchEvents({
        expired,
        pageNumber,
        pageSize,
        search,
        showEventsOnlyForCurrentModerator
      })
    );
  }

  searchValueChanged() {
    this.store.dispatch(
      EventsActions.saveInStoreSearchEventsPageNumber({ pageNumber: null })
    );
  }

  loadNextPageOfSearchEvents(
    destroy$: Observable<void>,
    expired: boolean,
    pageSize: number,
    search: string,
    showEventsOnlyForCurrentModerator: boolean
  ): void {
    if (!search && typeof showEventsOnlyForCurrentModerator !== "boolean") {
      return;
    }
    this.getFoundBySearchEventsPageNumber$(destroy$)
      .pipe(
        withLatestFrom(this.getIsFoundBySearchEventsLoaded$(destroy$)),
        take(1),
        filter(([, loaded]) => loaded === false)
      )
      .subscribe(([pageNumber]) => {
        this.loadPageableSearchEvents(
          expired,
          this.calculateNextPageNumber(pageNumber),
          pageSize,
          search,
          showEventsOnlyForCurrentModerator
        );
      });
  }

  loadNextPageOfSearchEventsWhenAdded(
    destroy$: Observable<void>,
    expired: boolean,
    pageSize: number,
    search: string,
    showEventsOnlyForCurrentModerator: boolean
  ): void {
    if (!search) {
      search = "";
    }
    if (typeof showEventsOnlyForCurrentModerator !== "boolean") {
      showEventsOnlyForCurrentModerator = false;
    }

    this.getFoundBySearchEventsPageNumber$(destroy$)
      .pipe(
        withLatestFrom(this.getIsFoundBySearchEventsLoaded$(destroy$)),
        take(1)
        // filter(([, loaded]) => loaded === false)
      )
      .subscribe(([pageNumber]) => {
        this.loadPageableSearchEvents(
          expired,
          this.calculateNextPageNumber(null),
          pageSize,
          search,
          showEventsOnlyForCurrentModerator
        );
      });
  }

  resetInStoreSearchEventsPageNumber(pnumber: number | null): void {
    this.store.dispatch(
      EventsActions.saveInStoreSearchEventsPageNumber({ pageNumber: null })
    );
  }

  loadNextPageOfEvents(
    destroy$: Observable<void>,
    expired: boolean,
    pageSize: number,
    onlyIfFirstPageIsNotLoaded: boolean
  ): void {
    this.getSearchParams$().subscribe((searchParams) => {
      if (
        searchParams?.searchValue?.trim() !== "" ||
        searchParams?.showEventsOnlyForCurrentModerator
      ) {
        this.getFoundBySearchEventsPageNumber$(destroy$)
          .pipe(
            withLatestFrom(
              this.getIsFoundBySearchEventsLoaded$(destroy$),
              this.getIsFoundBySearchEventsFirstPageLoaded$(destroy$)
            ),
            tap(
              ([, , firstPageLoaded]) =>
                !firstPageLoaded && this.saveInStoreLoading(true)
            ),
            take(1),
            filter(
              ([, fullyLoaded, firstPageLoaded]) =>
                !fullyLoaded &&
                (!onlyIfFirstPageIsNotLoaded || !firstPageLoaded)
            )
          )
          .subscribe(([pageNumber]) =>
            this.loadPageableSearchEvents(
              expired,
              this.calculateNextPageNumber(pageNumber),
              pageSize,
              searchParams?.searchValue,
              searchParams?.showEventsOnlyForCurrentModerator
            )
          );
      } else {
        this.getEventsPageNumberByExpired$(destroy$, expired)
          .pipe(
            withLatestFrom(
              this.getIsEventsFullyLoadedByExpired$(destroy$, expired),
              this.getIsEventsFirstPageLoadedByExpired$(destroy$, expired)
            ),
            tap(
              ([, , firstPageLoaded]) =>
                !firstPageLoaded && this.saveInStoreLoading(true)
            ),
            take(1),
            filter(
              ([, fullyLoaded, firstPageLoaded]) =>
                !fullyLoaded &&
                (!onlyIfFirstPageIsNotLoaded || !firstPageLoaded)
            )
          )
          .subscribe(([pageNumber]) =>
            this.loadPageableEvents(
              expired,
              this.calculateNextPageNumber(pageNumber),
              pageSize
            )
          );
      }
    });
  }

  calculateNextPageNumber(currentPage: number): number {
    if (currentPage === null || currentPage < 0) {
      return 0;
    }
    return currentPage + 1;
  }

  saveInStoreLoading(loading: boolean): void {
    this.store.dispatch(EventsActions.saveInStoreLoading({ loading }));
  }

  saveInStoreDuplicateActivitySuccess(duplicateActivitySuccess: boolean): void {
    this.store.dispatch(
      EventsActions.saveInStoreDuplicateActivitySuccess({
        duplicateActivitySuccess
      })
    );
  }

  addEvent(dto: EventSettingsDto): void {
    this.store.dispatch(EventsActions.addEvent({ dto }));
  }

  changeEventSettings(eventId: string, request: EventSettingsDto): void {
    this.store.dispatch(
      EventsActions.changeEventSettings({ eventId, request })
    );
  }

  changeEventColorTheme(
    eventId: string,
    theme: Omit<EventTheme, "logo">
  ): void {
    this.store.dispatch(EventsActions.changeEventTheme({ eventId, theme }));
  }

  changeEventColorThemeLogo(eventId: string, logo: File): void {
    this.store.dispatch(EventsActions.changeEventThemeLogo({ eventId, logo }));
  }

  deleteEventColorThemeLogo(eventId: string): void {
    this.store.dispatch(EventsActions.deleteEventThemeLogo({ eventId }));
  }

  deleteEvent(event: Event): void {
    this.store.dispatch(EventsActions.deleteEvent({ event }));
  }

  duplicateEvent(event: Event): void {
    this.store.dispatch(EventsActions.duplicateEvent({ newEvent: event }));
  }

  duplicateActivity(
    eventId: string,
    activityId: string,
    targetEventIds: string[]
  ): void {
    this.store.dispatch(
      EventsActions.duplicateActivity({ eventId, activityId, targetEventIds })
    );
  }

  toggleExpired(event: Event): void {
    this.store.dispatch(
      EventsActions.setExpire({
        event,
        expireRequest: { expired: !event.expired } as EventExpiredRequest
      })
    );
  }

  getGeneratedEventCode$(): Observable<string> {
    return this.eventsService
      .generateEventCode()
      .pipe(map((resp) => resp.eventCode));
  }

  getEventNameByEventCode(eventCode: string): Observable<{ eventName: string }> {
    return this.eventsService.getEventNameByEventCode(eventCode);
  }

  checkEventCode$(eventCode: string): Observable<boolean> {
    return this.eventsService
      .checkEventCodeExistence(eventCode.toUpperCase())
      .pipe(map((resp) => resp.exists));
  }

  /* Selectors */

  getLoading$(destroy$: Observable<void>) {
    return this.store
      .select(EventsQuery.selectLoading)
      .pipe(takeUntil(destroy$));
  }

  getDuplicateActivitySuccess$(destroy$: Observable<void>) {
    return this.store
      .select(EventsQuery.selectDuplicateActivitySuccess)
      .pipe(takeUntil(destroy$));
  }

  // Events

  getAllEvents$(destroy$: Observable<void>): Observable<Event[]> {
    return combineLatest(
      this.getCurrentEvents$(destroy$),
      this.getExpiredEvents$(destroy$)
    ).pipe(
      map(([currentEvents, expiredEvents]) => [
        ...currentEvents,
        ...expiredEvents
      ])
    );
  }

  getCurrentEvents$(destroy$: Observable<void>): Observable<Event[]> {
    return this.store
      .select(EventsQuery.selectCurrentEvents)
      .pipe(takeUntil(destroy$));
  }

  getExpiredEvents$(destroy$: Observable<void>): Observable<Event[]> {
    return this.store
      .select(EventsQuery.selectExpiredEvents)
      .pipe(takeUntil(destroy$));
  }

  getFoundBySearchEvents$(destroy$: Observable<void>) {
    return this.store
      .select(EventsQuery.selectFoundBySearchEvents)
      .pipe(takeUntil(destroy$));
  }

  // Loaded

  getIsCurrentEventsLoaded$(destroy$: Observable<void>) {
    return this.store
      .select(EventsQuery.selectIsCurrentEventsLoaded)
      .pipe(takeUntil(destroy$));
  }

  getIsExpiredEventsLoaded$(destroy$: Observable<void>) {
    return this.store
      .select(EventsQuery.selectIsExpiredEventsLoaded)
      .pipe(takeUntil(destroy$));
  }

  getIsFoundBySearchEventsLoaded$(destroy$: Observable<void>) {
    return this.store
      .select(EventsQuery.selectIsFoundBySearchEventsLoaded)
      .pipe(takeUntil(destroy$));
  }

  // Page number

  getCurrentEventsPageNumber$(destroy$: Observable<void>) {
    return this.store
      .select(EventsQuery.selectCurrentEventsPageNumber)
      .pipe(takeUntil(destroy$));
  }

  getExpiredEventsPageNumber$(destroy$: Observable<void>) {
    return this.store
      .select(EventsQuery.selectExpiredEventsPageNumber)
      .pipe(takeUntil(destroy$));
  }

  getFoundBySearchEventsPageNumber$(destroy$: Observable<void>) {
    return this.store
      .select(EventsQuery.selectFoundBySearchEventsPageNumber)
      .pipe(takeUntil(destroy$));
  }

  // Helpers

  getIsEventsFirstPageLoadedByExpired$(
    destroy$: Observable<void>,
    expired: boolean
  ): Observable<boolean> {
    return expired
      ? this.store
        .select(EventsQuery.selectIsExpiredEventsFirstPageLoaded)
        .pipe(takeUntil(destroy$))
      : this.store
        .select(EventsQuery.selectIsCurrentEventsFirstPageLoaded)
        .pipe(takeUntil(destroy$));
  }

  getIsFoundBySearchEventsFirstPageLoaded$(
    destroy$: Observable<void>
  ): Observable<boolean> {
    return this.store
      .select(EventsQuery.selectIsFoundBySearchEventsFirstPageLoaded)
      .pipe(takeUntil(destroy$));
  }

  getIsEventsFullyLoadedByExpired$(
    destroy$: Observable<void>,
    expired: boolean
  ): Observable<boolean> {
    return expired
      ? this.store
        .select(EventsQuery.selectIsExpiredEventsLoaded)
        .pipe(takeUntil(destroy$))
      : this.store
        .select(EventsQuery.selectIsCurrentEventsLoaded)
        .pipe(takeUntil(destroy$));
  }

  getEventsPageNumberByExpired$(
    destroy$: Observable<void>,
    expired: boolean
  ): Observable<number> {
    return expired
      ? this.store
        .select(EventsQuery.selectExpiredEventsPageNumber)
        .pipe(takeUntil(destroy$))
      : this.store
        .select(EventsQuery.selectCurrentEventsPageNumber)
        .pipe(takeUntil(destroy$));
  }

  getSearchParams$() {
    return this.store
      .select(EventsQuery.selectEventsSearchParams)
      .pipe(take(1));
  }

  saveStateSearchParams(
    isCurrent: boolean,
    amountOfEventsToLoad: number,
    searchValue: string,
    showEventsOnlyForCurrentModerator: boolean
  ) {
    this.store.dispatch(
      EventsActions.saveStateSearchParams({
        isCurrent,
        amountOfEventsToLoad,
        searchValue,
        showEventsOnlyForCurrentModerator
      })
    );
  }
}

const EventsFacadeMock = {};

export const EventsFacadeMockProvider: ValueProvider = {
  provide: EventsFacade,
  useValue: EventsFacadeMock
};
