<button
  mat-raised-button
  [class.button]="true"
  [class.default]="!outlined && !text"
  [class.outlined]="this.outlined"
  [class.text]="this.text"
  [class.large]="this.large"
  [class.loading]="this.loading"
  [disabled]="disabled"
  [tabIndex]="tabIndex"
  [type]="type"
  (click)="onClick()"
  [matTooltip]="tooltip"
  matTooltipPosition="above"
>
  <mat-icon class="icon-primary" *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
  <span class="text"><ng-content *ngIf="!loading"></ng-content></span>

  <mat-spinner
    *ngIf="loading"
    @fadeAnimation
    [diameter]="26"
    [style.display]="'inline-flex'"
  >
  </mat-spinner>
</button>
