import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthFacade, promptCookies } from "@tellsy/auth-facade";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";

interface FormType {
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
}

@Component({
  selector: "tellsy-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup<FormType>;
  login$: Observable<string>;
  authLogin: string;
  token: string;
  errorMessage: string;
  pending: boolean;
  goodLink: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private authFacade: AuthFacade,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private ccService: NgcCookieConsentService,
  ) {}

  ngOnInit() {
    promptCookies(this.ccService, this.destroy$);
    this.route.params.subscribe((params) => {
      this.token = params.token;
      this.login$ = this.authFacade.getRegisterInfo$(params.token).pipe(
        map((registerInfo) => {
          this.goodLink = !!registerInfo;
          this.authLogin = registerInfo?.login;
          return registerInfo?.login;
        }),
      );
    });
    this.form = this.fb.group(
      {
        password: this.fb.control<string>("", [
          Validators.required,
          Validators.minLength(6),
        ]),
        confirmPassword: this.fb.control<string>("", [
          Validators.required,
          Validators.minLength(6),
        ]),
      },
      { validators: [this.checkPasswords] },
    );
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  checkPasswords(group: FormGroup<FormType>) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  setPassword(): void {
    if (this.form.invalid) {
      return;
    }
    const { password } = this.form.value;
    this.pending = true;

    this.authFacade.setPassword(this.token, password).subscribe(
      () => {
        this.pending = false;
        this.authFacade.loginModerator(this.authLogin, password).subscribe();
      },
      (res: HttpErrorResponse) => {
        let errorText = this.translate.instant("shared.unknownError");
        const { error } = res;

        if (res.status === 0) {
          errorText = this.translate.instant("shared.noConnection");
        } else if (error.errorCode === "auth-service.tokenNotFound") {
          errorText = this.translate.instant("shared.alreadyUsed");
        } else if (error.errorCode === "auth-service.tokenExpired") {
          errorText = this.translate.instant("shared.tokenExpired");
        }
        this.pending = false;
        this.errorMessage = errorText;
        this.ref.markForCheck();
        return of(null);
      },
    );
  }
}
