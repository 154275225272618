import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ThemeModule } from "@tellsy/theme";
import { ChooseRandomVariantComponent } from "@shared/choose-random-variant/choose-random-variant.component";
import { RandomizationFacade } from "@app/services/mini-activities/randomization-facade.service";

@NgModule({
  declarations: [ChooseRandomVariantComponent],
  providers: [RandomizationFacade],
  imports: [CommonModule, ThemeModule],
  exports: [ChooseRandomVariantComponent],
})
export class ChooseRandomVariantModule {}
