import { HttpRequest } from "@angular/common/http";
import { API } from "@app/configs/api.config";

export function shouldIntercept(req: HttpRequest<any>): boolean {
  const doNotInterceptUrls = [
    //API.auth.requestAuthToken,
    API.auth.getUser,
    API.auth.setPasswordAfterRegistration(),
    API.moderator.diagnostics,
  ];

  return !doNotInterceptUrls.some((url) => req.url.endsWith(url));
}

export function shouldAttachBearerToken(req: HttpRequest<any>): boolean {
  const doNotInterceptUrls = [
    API.auth.requestAuthToken,
    API.auth.getUser,
    API.auth.setPasswordAfterRegistration(),
    API.moderator.diagnostics,
    API.events.getEventNameByEventCode("")
  ];

  return !doNotInterceptUrls.some((url) => req.url.endsWith(url));
}

export function shouldProcessError(req: HttpRequest<any>): boolean {
  const doNotProcessErrorUrls: string[] = [
    API.auth.changeUsername(),
    API.auth.changePassword(),
    API.moderator.diagnostics,
  ];
  return !doNotProcessErrorUrls.some((url) => req.url.endsWith(url));
}
