import { Injectable } from "@angular/core";
import {
  IMessage,
  RxStomp,
  RxStompConfig,
  RxStompState,
} from "@stomp/rx-stomp";
import { environment } from "@tellsy/environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RabbitStompService {
  private stomp: RxStomp;
  private subscriptions: Map<string, Observable<IMessage>> = new Map();

  constructor() {
    const config: RxStompConfig = {
      // url: 'ws://localhost:8080/api/event_service/ws',
      brokerURL: environment.wsRelayHost + "/ws",
      // set to 60 seconds because of this issue https://github.com/stomp-js/stompjs/issues/335
      heartbeatIncoming: 60000, // Typical value 0 - disabled
      heartbeatOutgoing: 60000, // Typical value 20000 - every 20 seconds

      // Wait in milliseconds before attempting auto reconnect
      // Set to 0 to disable
      // Typical value 5000 (5 seconds)
      reconnectDelay: 5000,
    };

    this.stomp = new RxStomp();
    this.stomp.configure(config);

    this.stomp.connectionState$.subscribe((state) =>
      console.debug("STOMP:", RxStompState[state]),
    );
  }

  activate() {
    this.stomp.activate();
  }

  deactivate() {
    this.stomp.deactivate();
  }

  watch$(exchange: string, routingKey: string): Observable<IMessage> {
    const destination = `/exchange/${exchange}/${routingKey}`;

    return this.subscriptions.has(destination)
      ? this.subscriptions.get(destination)
      : this.subscriptions
          .set(
            destination,
            this.stomp.watch(destination).pipe(
              catchError((err) => {
                console.error(err);
                return throwError(() => new Error(err));
              }),
            ),
          )
          .get(destination);
  }

  onConnection$(): Observable<RxStompState> {
    return this.stomp.connected$;
  }

  connectionStatus$(): Observable<RxStompState> {
    return this.stomp.connectionState$;
  }
}
