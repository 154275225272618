import { LogoutActionTypes } from "@app/store/logout/logout.actions";

export function metaLogout(reducer) {
  return function (state, action) {
    return reducer(
      action.type === LogoutActionTypes.logoutSuccess ? undefined : state,
      action,
    );
  };
}
