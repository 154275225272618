<div fxLayout="column" fxLayoutGap.gt-xs="10px" class="wrapper">
  <form
    class="top-container"
    fxFlex.gt-xs="60%"
    fxFlex.xs="70%"
    fxLayout="column"
    fxLayoutAlign="end center"
    (ngSubmit)="attemptLogin()"
  >
    <div fxFlex.xs="20%" fxFlex.gt-xs="30%" fxLayoutAlign="center center">
      <img
        class="logo"
        src="/assets/img/tellsy logo white.svg"
        alt="tellsy.ru"
      />
    </div>

    <p class="header">
      {{ "auth.moderator.title" | translate }}
    </p>

    <app-auth-input
      type="text"
      [required]="true"
      [value]="username.value"
      (valueChange)="onFormControlChange(username, $event)"
      [label]="'auth.moderator.input.username.placeholder' | translate"
      [dark]="false"
      [bold]="true"
      [spellcheck]="false"
      [hint]="
        errorMessage ||
        (username.dirty && !username.value
          ? ('auth.moderator.input.username.error.empty' | translate)
          : '')
      "
    >
    </app-auth-input>

    <app-auth-input
      type="password"
      [required]="true"
      [value]="password.value"
      (valueChange)="onFormControlChange(password, $event)"
      [label]="'auth.moderator.input.password.placeholder' | translate"
      [dark]="false"
      [bold]="true"
      [spellcheck]="false"
      [hint]="
        password.dirty && !password.value
          ? ('auth.moderator.input.password.error.empty' | translate)
          : ''
      "
    >
    </app-auth-input>

    <tellsy-button
      class="login-btn"
      [large]="false"
      [loading]="pending"
      [disabled]="form.invalid"
      type="submit"
    >
      {{ "auth.moderator.button.logIn.title" | translate }}
    </tellsy-button>
  </form>

  <app-auth-footer [isParticipant]="false"></app-auth-footer>
</div>
