import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EventService } from "@app/store/event/services/event.service";
import { LightSessionActions } from "@app/store/event/actions/sessionLight.actions";
import { map, Subject, switchMap, tap } from "rxjs";
import { NotificationType } from "@tellsy/notifications";
import { NotificatorService } from "@tellsy/common/services/notificator.service";
import { TranslateService } from "@ngx-translate/core";
import { Session_lightDtoModel } from "@app/store/event/model/dto/session_light.dto.model";

@Injectable({ providedIn: "root" })
export class SessionLightEffects {
  sessionStartTime$ = new Subject<Session_lightDtoModel[]>();
  sessionStopTime$ = new Subject<Session_lightDtoModel[]>();
  remainingSeconds$ = new Subject<number>();

  startLight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LightSessionActions.startLightSession),
      switchMap(({ eventId }) =>
        this.eventService.startSessionLight(eventId)
      ),
      map((timers) => {
        this.sessionStartTime$.next(timers);
        return LightSessionActions.startLightSessionSuccess({ timers });
      }),
      tap(({ timers }) => {
        this.notificatorService.showToastMessage(
          "",
          this.translate.instant(
            "moderator.event.topPanel.timer.bulkActions.start"
          ),
          NotificationType.Success
        );
      })
    )
  );

  finishLight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LightSessionActions.finishLightSession),
      switchMap(({ eventId }) =>
        this.eventService.finishSessionLight(eventId)
      ),
      map((timers) => {
        this.sessionStopTime$.next(timers);
        return LightSessionActions.finishLightSessionSuccess({ timers });
      }),
      tap(({ timers }) => {
        this.notificatorService.showToastMessage(
          "",
          this.translate.instant(
            "moderator.event.topPanel.timer.bulkActions.finish"
          ),
          NotificationType.Success
        );
      })
    )
  );

  remainingSeconds = createEffect(() =>
    this.actions$.pipe(
      ofType(LightSessionActions.remainingSeconds),
      switchMap(({ eventId }) =>
        this.eventService.remainingSeconds(eventId)
      ),
      map((timers) => {
        this.remainingSeconds$.next(timers);
        return LightSessionActions.remainingSecondsSuccess({ timers });
      }),
    )
  );
  constructor(
    private actions$: Actions,
    private eventService: EventService,
    private notificatorService: NotificatorService,
    private translate: TranslateService
  ) {
  }

}
