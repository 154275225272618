import { Team } from "@app/models/participant.model";
import { createAction, props, union } from "@ngrx/store";

const loadTeams = createAction(
  "[Teams] Load Teams",
  props<{ parentEventId: string }>(),
);

const setTeams = createAction(
  "[Teams] Set Teams",
  props<{ teams: Team[]; safeLink?: boolean }>(),
);

const setTeam = createAction("[Teams] Set Team", props<{ team: Team }>());

const changeTeamCaptain = createAction(
  "[Teams] Change Team Captain",
  props<{ teamId: string; captainId: string; captainIdentifier: string }>(),
);

const randomlyChangeParticipantsTeams = createAction(
  "[Teams] Randomly Change Participants Teams",
  props<{ numberOfTeamsToCreate: number; eventId: string }>(),
);

export const TeamsActions = {
  loadTeams,
  setTeams,
  setTeam,
  changeTeamCaptain,
  randomlyChangeParticipantsTeams,
};

const all = union(TeamsActions);

export type TeamsActionTypes = typeof all;
