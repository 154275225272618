<div fxFlex="auto" fxLayout="column" fxLayoutAlign="start center">
  <a
    tellsy-button
    href="https://tellsy.pro"
    target="_blank"
    rel="noopener"
    theme="transparent"
    [large]="true"
  >
    {{ "auth.footer.aboutUs" | translate }}
  </a>

  <tellsy-legal-info></tellsy-legal-info>

  <ng-container *ngIf="isParticipant; else moderator">
    <a
      tellsy-button
      routerLink="/login/moderator"
      theme="transparent"
      class="participant-button"
      [raised]="false"
    >
      {{ "auth.footer.logIn.moderator" | translate }}
    </a>
  </ng-container>

  <ng-template #moderator>
    <a
      tellsy-button
      routerLink="/login"
      theme="light"
      [raised]="false"
      class="moderator-button"
    >
      {{ "auth.footer.logIn.participant" | translate }}
    </a>
  </ng-template>
</div>
