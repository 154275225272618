import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

export interface TextDialogData {
  title: string;
  content: string;
}
@Component({
  selector: "app-text-dialog",
  templateUrl: "./text-dialog.component.html",
  styleUrls: ["./text-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextDialogComponent implements OnInit {
  title: string;
  content: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: TextDialogData) {}

  ngOnInit() {
    this.content = this.data.content;
    this.title = this.data.title;
  }
}
