import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  selector: "tellsy-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() iconX: boolean;
  @Input() disabled: boolean;
  @Input() theme: "default" | "green" | "red" | "participant" = "default";

  value: boolean;
  init: boolean;

  constructor() {}

  ngOnInit() {}

  writeValue(value: any): void {
    if (this.value !== value) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = (val: boolean, ...args: unknown[]) => {
      this.value = val;
      fn(val, ...args);
    };
  }

  registerOnTouched(_fn: any): void {}

  onChange: any = () => {};

  onTouched: any = () => {};
}
