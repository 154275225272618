import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@app/configs/api.config";
import { ApiResponse } from "@app/models/common.models";
import { Observable, of } from "rxjs";
import {
  AddArgumentDto,
  AddCommentDto,
  AddQuestionDto,
  AddVoteDto, ArgCountType, ArgumentType,
  AskQuestionActivity,
  AskQuestionActivitySettings,
  AskQuestionStatistics,
  Question, QuestionArgument,
  QuestionComment,
  QuestionHistoryItem,
  Speaker,
  SpeakerIdToActiveMap,
  SpeakerIdToOrderMap,
  UpdateCommentDto,
  UpdateQuestionDto
} from "../models";
import { SuggestionsAi } from "@app/store/ask-question/models/suggestionsAi.model";

@Injectable({ providedIn: "root" })
export class AskQuestionService {
  constructor(private http: HttpClient) {}

  getActivity(activityId: string, teamIds: string[] = []) {
    let params = new HttpParams();
    if (teamIds?.length > 0) {
      teamIds.forEach((teamId: string) => {
        params = params.append(`teams_ids[]`, teamId);
      });
    }

    return this.http.get<AskQuestionActivity>(
      API.askQuestion.getActivity(activityId),
      { params },
    );
  }

  getActivityStatistics(activityId: string) {
    return this.http.get<AskQuestionStatistics>(
      API.askQuestion.getActivityStatistics(activityId),
    );
  }

  getArgumentsCount(activityId: string) {
    if (!activityId) {
      return of([])
    }
    return this.http.get<ArgCountType[]>(
      API.askQuestion.getArgumentsCount(activityId),
    );
  }

  getArguments(questionId: string, teamIds: string[] = []) {
    let params = new HttpParams();
    if (teamIds?.length > 0) {
      teamIds.forEach((teamId: string) => {
        params = params.append(`teams_ids[]`, teamId);
      });
    }
    return this.http.get<any>(API.askQuestion.getArguments(questionId), {
      params,
    });
  }

  getArgumentsForProjector(activityId: string, argumentType?: ArgumentType) {
    let params = new HttpParams();
    if (argumentType) {
      params = params.append("type", argumentType);
    }
    return this.http.get<any>(
      API.askQuestion.getArgumentsForProjector(activityId),
      { params },
    );
  }

  updateAi(activityId: string, speakerId: string, dataAi: SuggestionsAi) {
    return this.http.put<Question>(
      API.askQuestion.questions.updateAi(activityId, speakerId),
      JSON.parse(dataAi.aiData),
    );
  }

  updateQuestion(
    activityId: string,
    speakerId: string,
    questionId: string,
    dto: UpdateQuestionDto,
  ) {
    return this.http.put<Question>(
      API.askQuestion.questions.updateQuestion(
        activityId,
        speakerId,
        questionId,
      ),
      dto,
    );
  }

  //#region Moderator
  setSettings(activityId: string, settings: AskQuestionActivitySettings) {
    return this.http.post<AskQuestionActivitySettings>(
      API.askQuestion.settings(activityId),
      {
        settings,
      },
    );
  }

  //#region speakers
  addSpeaker(
    activityId: string,
    speaker: Omit<
      Speaker,
      | "id"
      | "order"
      | "readonly"
      | "focusedParticipantId"
      | "questionAISuggestions"
    >,
  ) {
    return this.http.post<Speaker>(
      API.askQuestion.speakers.addSpeaker(activityId),
      speaker,
    );
  }

  changeSpeakersOrder(
    activityId: string,
    speakerIdToOrderMap: SpeakerIdToOrderMap,
  ) {
    return this.http.put<Speaker[]>(
      API.askQuestion.speakers.changeSpeakersOrder(activityId),
      {
        speakerIdToOrderMap,
      },
    );
  }

  changeSpeakerName(activityId: string, speakerId: string, newName: string) {
    return this.http.put<ApiResponse>(
      API.askQuestion.speakers.changeSpeakerName(activityId, speakerId),
      newName,
    );
  }

  setActiveSpeaker(activityId: string, speakerId: string, active: boolean) {
    return this.http.put<SpeakerIdToActiveMap>(
      API.askQuestion.speakers.setActiveSpeaker(activityId),
      {
        speakerId,
        active,
      },
    );
  }

  changeSpeakerReadonly(
    activityId: string,
    speakerId: string,
    readonly: boolean,
  ) {
    return this.http.put<Speaker>(
      API.askQuestion.speakers.changeSpeakerReadonly(activityId, speakerId),
      readonly,
    );
  }

  setFocusedParticipantForSpeaker(
    activityId: string,
    speakerId: string,
    focusedParticipantId: string,
  ) {
    return this.http.put<Speaker>(
      API.askQuestion.speakers.setFocusedParticipantForSpeaker(
        activityId,
        speakerId,
      ),
      { focusedParticipantId },
    );
  }

  deleteSpeaker(activityId: string, speakerId: string) {
    return this.http.delete<ApiResponse>(
      API.askQuestion.speakers.deleteSpeaker(activityId, speakerId),
    );
  }

  //#endregion

  //#region questions
  setQuestionApproved(
    activityId: string,
    speakerId: string,
    questionId: string,
    approved: boolean,
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      API.askQuestion.questions.setQuestionApproved(
        activityId,
        speakerId,
        questionId,
      ),
      approved,
    );
  }

  setQuestionFocused(
    activityId: string,
    speakerId: string,
    questionId: string,
    focused: boolean,
  ) {
    return this.http.put<ApiResponse>(
      API.askQuestion.questions.setQuestionFocused(
        activityId,
        speakerId,
        questionId,
      ),
      {
        focused,
      },
    );
  }

  setQuestionAnswered(
    activityId: string,
    speakerId: string,
    questionId: string,
    answered: boolean,
  ) {
    return this.http.put<ApiResponse>(
      API.askQuestion.questions.setQuestionAnswered(
        activityId,
        speakerId,
        questionId,
      ),
      {
        answered,
      },
    );
  }

  //#endregion

  getResultsReport(activityId: string) {
    return this.http.get(API.askQuestion.getResultsReport(activityId), {
      responseType: "arraybuffer",
    });
  }

  //#endregion

  //#region Participant
  addQuestion(activityId: string, speakerId: string, dto: AddQuestionDto) {
    return this.http.post<Question>(
      API.askQuestion.questions.addQuestion(activityId, speakerId),
      dto,
    );
  }

  addVote(
    activityId: string,
    speakerId: string,
    questionId: string,
    dto: AddVoteDto,
  ) {
    return this.http.post<Question>(
      API.askQuestion.questions.addVote(activityId, speakerId, questionId),
      dto,
    );
  }

  deleteVote(activityId: string, speakerId: string, questionId: string) {
    return this.http.delete<Question>(
      API.askQuestion.questions.deleteVote(activityId, speakerId, questionId),
    );
  }

  addComment(
    activityId: string,
    speakerId: string,
    questionId: string,
    dto: AddCommentDto,
  ) {
    return this.http.post<QuestionComment>(
      API.askQuestion.questions.addComment(activityId, speakerId, questionId),
      dto,
    );
  }

  updateComment(
    activityId: string,
    speakerId: string,
    questionId: string,
    commentId: string,
    dto: UpdateCommentDto,
  ) {
    return this.http.put<QuestionComment>(
      API.askQuestion.questions.updateComment(
        activityId,
        speakerId,
        questionId,
        commentId,
      ),
      dto,
    );
  }

  deleteComment(activityId: string, commentId: string) {
    return this.http.delete<ApiResponse>(
      API.askQuestion.questions.deleteComment(activityId, commentId),
    );
  }

  addArgument(activityId: string, questionId: string, dto: AddArgumentDto) {
    return this.http.post<QuestionArgument>(
      API.askQuestion.questions.addArgument(activityId, questionId),
      dto,
    );
  }

  updateArgument(
    activityId: string,
    argumentId: string,
    dto: UpdateCommentDto,
  ) {
    return this.http.put<QuestionArgument>(
      API.askQuestion.questions.updateArgument(activityId, argumentId),
      dto,
    );
  }

  deleteArgument(activityId: string, argumentId: string) {
    return this.http.delete<ApiResponse>(
      API.askQuestion.questions.deleteArgument(activityId, argumentId),
    );
  }

  getQuestionHistory(
    activityId: string,
    speakerId: string,
    questionId: string,
  ): Observable<QuestionHistoryItem[]> {
    return this.http.get<QuestionHistoryItem[]>(
      API.askQuestion.questions.getHistory(activityId, speakerId, questionId),
    );
  }

  //#endregion

  deleteQuestion(questionId: string, activityId: string) {
    return this.http.delete<ApiResponse>(
      API.askQuestion.questions.deleteQuestion(activityId, questionId),
    );
  }
}
