import { createActionGroup, props } from "@ngrx/store";
import { TimerDto } from "../model/dto/timer.dto.model";

export const TimerActions = createActionGroup({
  source: "Timer",
  events: {
    "Load": props<{ eventId: string }>(),
    "Load Success": props<{ timers: TimerDto[] }>(),

    "Start": props<{ eventIds: string[]; seconds: number }>(),
    "Start Success": props<{ timers: TimerDto[] }>(),

    "Stop": props<{ eventIds: string[] }>(),
    "Stop Success": props<{ timers: TimerDto[] }>(),

    "Set Can Be Negative": props<{
      eventIds: string[];
      canBeNegative: boolean;
    }>(),
    "Set Can Be Negative Success": props<{ timers: TimerDto[] }>(),
  },
});

export const TimerWebsocketActions = createActionGroup({
  source: "Timer ~ Websocket",
  events: {
    "Start": props<{ eventId: string }>(),
    "Stop": props<{ eventId: string }>(),
    "Set Can Be Negative": props<{ eventId: string; canBeNegative: boolean }>(),
  },
});
