import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";

export interface DialogData {
  title: string;
  content: string;
}

@Component({
  selector: "app-long-content-dialog",
  templateUrl: "./long-content-dialog.component.html",
  styleUrls: ["./long-content-dialog.component.scss"],
})
export class LongContentDialogComponent implements OnInit {
  title: string;
  content: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private dialogRef: MatDialogRef<LongContentDialogComponent>,
  ) {
    this.content = data.content;
    this.title = data.title;
  }

  ngOnInit() {}
}
