<ng-container *ngIf="participantAuthState$ | async as state">
  <div class="component-wrapper" fxLayoutAlign="center center">
    <div class="wrapper" fxFlex="0" fxLayout="column">
      <tellsy-language-button
        class="language-button"
        fxFlexAlign="end"
        [isForParticipant]="true"
      ></tellsy-language-button>
      <div
        class="login-form"
        fxFlex="0"
        fxLayout="column"
        fxLayoutAlign="start stretch"
      >
        <svg class="logo">
          <use [attr.xlink:href]="DEFAULT_TELLSY_LOGO_URL"></use>
        </svg>
        <div class="header">{{ "auth.participant.header" | translate }}</div>

        <ng-container *ngIf="state.navigatedByLink; else eventCodeInput">
          <div class="welcome">
            {{
              "auth.participant.logginInByLink"
                | translate: { eventName: state.eventName ?? "" }
            }}
          </div>
        </ng-container>

          <tellsy-participant-input
            class="event-code input"
            [disabled]="state.navigatedByLink"
            [bold]="true"
            [uppercase]="true"
            [placeholder]="
              'auth.participant.input.eventCode.placeholder' | translate
            "
            [error]="
              state.eventCodeErrorType
                ? ('auth.participant.input.eventCode.error.' +
                    state.eventCodeErrorType | translate)
                : ''
            "
            (enterKey)="attemptLogin()"
            [(ngModel)]="eventCode"
          ></tellsy-participant-input>

        <tellsy-participant-input
          *ngIf="state.usernameFieldName || username"
          class="username input"
          @visibilityChanged
          [bold]="true"
          [uppercase]="true"
          [placeholder]="state.usernameFieldName"
          [error]="
            state.usernameErrorType
              ? ('auth.participant.input.username.error.' +
                  state.usernameErrorType | translate)
              : ''
          "
          (enterKey)="attemptLogin()"
          [(ngModel)]="username"
        ></tellsy-participant-input>

        <tellsy-checkbox class="checkbox" [(ngModel)]="policyChecked">
          <p class="agreement">
            {{ "auth.participant.agreement.start" | translate }}
            <a (click)="openAgreementToProcessingPersonalDataDialog($event)">{{
              "auth.participant.agreement.agreementToProcessingPersonalData"
                | translate
            }}</a>
            {{ "auth.participant.agreement.next" | translate }}
            <a (click)="openPersonalDataPolicyDialog($event)">{{
              "auth.participant.agreement.personalDataPolicy" | translate
            }}</a
            >{{ "auth.participant.agreement.andCookies" | translate }}
            <a (click)="openUserAgreementDialog($event)">{{
              "auth.participant.agreement.userAgreement" | translate
            }}</a>
          </p>
        </tellsy-checkbox>
        <tellsy-participant-button
          class="login-btn"
          [disabled]="!policyChecked || !eventCode"
          [loading]="state.pending"
          (clicked)="attemptLogin()"
        >
          {{
            (!!state.usernameFieldName
              ? "auth.participant.button.logIn.title"
              : "auth.participant.button.continue.title"
            ) | translate
          }}
        </tellsy-participant-button>
      </div>

      <app-auth-footer [isParticipant]="true"></app-auth-footer>
    </div>
  </div>
</ng-container>
