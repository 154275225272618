import { Injectable } from "@angular/core";
import { LanguagesService } from "./languages.service";

export type Locale = "ru-RU" | "en-US";

@Injectable({
  providedIn: "root",
})
export class DateFormatService {
  constructor(private languagesService: LanguagesService) {}

  format(dateToFormat: Date | string, locale?: Locale): string | null {
    if (!dateToFormat) {
      return null;
    }

    const isString = typeof dateToFormat === "string";

    const date = isString ? new Date(dateToFormat) : dateToFormat;

    if (!locale) {
      switch (this.languagesService.currentLanguageValue) {
        case "ru":
          locale = "ru-RU";
          break;
        case "en":
          locale = "en-US";
          break;
      }
    }

    const dateArr = [];

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear() - 2000;

    switch (locale) {
      case "ru-RU":
        dateArr.push(day);
        dateArr.push(month);
        dateArr.push(year);
        return dateArr.join(".");
      case "en-US":
        dateArr.push(month);
        dateArr.push(day);
        dateArr.push(year);
        return dateArr.join("/");
    }
  }
}
