<div class="wrapper">
  <div fxLayout="row" fxLayoutAlign="end center" class="close-dialog-btn">
    <button tabindex="-1" mat-dialog-close mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <app-choose-random-variant
    [variants]="data.variants"
    [chosenVariant]="chosenVariant$ | async"
    [showRandomization]="randomizationRunning$ | async"
  >
  </app-choose-random-variant>
</div>
