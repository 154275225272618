import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthFacade, isAuthenticatedAsParticipant } from "@tellsy/auth-facade";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ParticipantGuard {
  constructor(private authFacade: AuthFacade, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authFacade.getUser$("participant").pipe(
      map((user) => {
        const authenticatedAsParticipant = isAuthenticatedAsParticipant(user);
        if (authenticatedAsParticipant) {
          this.authFacade.setUserState({
            role: "participant",
            id: user.principal.id,
            name: user.name,
          });
        }
        return authenticatedAsParticipant;
      }),
      tap((authenticated) => (!authenticated ? this.navigateToLogin() : null)),
    );
  }

  private navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }
}
