<button
  (click)="onClick()"
  [class.large]="large"
  [class.loading]="loading"
  [disabled]="disabled"
  [ngClass]="['button', theme, buttonClass ? buttonClass : '']"
  [style.box-shadow]="!raised ? 'none' : null"
  [style.font-weight]="fontWeight"
  [style.text-decoration]="link ? 'underline' : 'none'"
  [style.width.px]="width"
  [style.height.px]="height"
  [style.background-color]="disabled ? null : background"
  [tabIndex]="tabIndex"
  [type]="type"
  mat-raised-button
>
  <mat-icon class="icon-primary" *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
  <ng-content *ngIf="!loading"></ng-content>

  <mat-spinner
    *ngIf="loading"
    @fadeAnimation
    [diameter]="26"
    [style.display]="'inline-flex'"
  >
  </mat-spinner>
</button>
