import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

export class IconsRegister {
  static addIcons(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    [
      "analytics",
      "answer",
      "arrow-left",
      "brainstorm-statement",
      "calendar",
      "chart",
      "check",
      "clear",
      "clock",
      "close",
      "comments",
      "cons",
      "crown",
      "delete",
      "dislike",
      "dislike-filled",
      "documents",
      "edit",
      "edit-participant",
      "ellipse-chevron-right",
      "email",
      "emoji-events",
      "emoji-events-projector",
      "error",
      "favorite",
      "file",
      "image",
      "info",
      "jump-to-top",
      "knowledge_base",
      "like",
      "like-filled",
      "link",
      "lock",
      "logout",
      "mdi-chevron-down",
      "mdi-chevron-left",
      "mdi-chevron-right",
      "mdi-chevron-up",
      "menu",
      "new-direct-message",
      "omni-supervisor",
      "people",
      "phone",
      "phone-participant",
      "picklist-type",
      "play",
      "plus-minus",
      "projector",
      "pros",
      "reply",
      "resource-absence",
      "role-game-search",
      "schedule",
      "scroll_hand",
      "send",
      "settings",
      "share",
      "shuffle",
      "shuffle-white",
      "survey",
      "task",
      "team",
      "trash",
      "unlock",
      "upload",
      "user",
      "users",
      "vacancy-empty",
      "vacancy-expert",
      "vacancy-filled",
      "video",
      "whatsapp",
    ].forEach((el) =>
      iconRegistry.addSvgIcon(
        el,
        domSanitizer.bypassSecurityTrustResourceUrl(
          `../assets/icons/${el}.svg`,
        ),
      ),
    );
  }
}
