import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LockUserActionDirective } from "./lock-user-action.directive";

@NgModule({
  declarations: [LockUserActionDirective],
  exports: [LockUserActionDirective],
  imports: [CommonModule],
})
export class LockDirectivesModule {}
