import { Component, Inject, OnInit } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { SafeUrl } from "@angular/platform-browser";
import { LoadImageService } from "@app/services/image/load-image.service";
import { truthyFilter } from "@tellsy/rxjs/operators";
import { Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

@Component({
  selector: "app-image-dialog",
  templateUrl: "./image-dialog.component.html",
  styleUrls: ["./image-dialog.component.scss"],
})
export class ImageDialogComponent implements OnInit {
  imgSrc$: Observable<SafeUrl>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { imgPath: string; imgPath$: Observable<string> },
    private loadImageService: LoadImageService,
    private dialogRef: MatDialogRef<any>,
  ) {
    if (this.data.imgPath) {
      this.imgSrc$ = this.loadImageService.getSafeFileUrl$(this.data.imgPath);
    } else {
      this.imgSrc$ = this.data.imgPath$.pipe(
        tap((path) => (!path ? this.dialogRef.close() : null)),
        truthyFilter(),
        switchMap((filepath) =>
          this.loadImageService.getSafeFileUrl$(filepath),
        ),
      );
    }
  }

  ngOnInit() {}
}
