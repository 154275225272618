import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { API } from "@app/configs/api.config";
import { ErrorCode } from "@app/error-handling/error-code";
import { LogoutService } from "@app/services/auth/logout.service";
import { ErrorsService, ErrorType } from "@app/services/errors/errors.service";
import { shouldIntercept, shouldProcessError } from "@app/services/interceptors/utils";
import { ModeratorService } from "@app/store/moderator/initialize-module/moderator.service";
import { accessTokenExpired, AuthFacade, noTokenOrUnauthorized, refreshTokenExpired } from "@tellsy/auth-facade";
import { NotificatorService } from "@tellsy/common/services/notificator.service";
import { NotificationType } from "@tellsy/notifications";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private notificator: NotificatorService,
    private logoutService: LogoutService,
    private authFacade: AuthFacade,
    private router: Router,
    private errorService: ErrorsService,
    private moderatorService: ModeratorService
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!shouldIntercept(req)) {
      return next.handle(req);
    }

    if (!shouldProcessError(req)) {
      return next.handle(req);
    }

    const requestToForward = req.clone();

    return next.handle(requestToForward).pipe(
      catchError((errorResponse) => {
        let errMsg: string;
        if (errorResponse instanceof HttpErrorResponse) {
          if (refreshTokenExpired(errorResponse)) {
            console.error("refreshTokenExpired", errorResponse);
            return this.authFacade
              .getNewTokenAndRetry$()
              .pipe(switchMap(() => next.handle(requestToForward)));
          }

          if (accessTokenExpired(errorResponse)) {
            console.error("accessTokenExpired", errorResponse);
            return this.authFacade
              .refreshAuthTokenDependingOnUrl$()
              .pipe(switchMap(() => next.handle(requestToForward)));
          }

          if (noTokenOrUnauthorized(errorResponse)) {
            console.error("noTokenOrUnauthorized", errorResponse);
            return this.authFacade
              .getNewTokenAndRetry$()
              .pipe(switchMap(() => next.handle(requestToForward)));
          }

          if (this.accessTokenIsNull(errorResponse)) {
            return this.throwErrAndNavigateToLogin(errorResponse.error);
          }

          if (
            this.attemptToLogoutFromDeletedEvent(
              req.url,
              errorResponse.error.errorCode
            )
          ) {
            return this.throwErrAndNavigateToLogin(errorResponse.error);
          }

          if (this.eventNotFoundFromParticipant(errorResponse)) {
            this.logoutService.revokeToken().subscribe();
            return this.throwErrAndNavigateToLogin(errorResponse.error);
          }

          if (
            errorResponse.error.errorCode ===
            ErrorCode.ParticipantService.PARTICIPANT_NOT_FOUND
          ) {
            this.logoutService.revokeToken().subscribe();
            return this.throwErrAndNavigateToLogin(errorResponse.error);
          }

          if (
            errorResponse.error.errorCode ===
            "ask-question-service.speakerNotActive"
          ) {
            return throwError(errorResponse.error.errorCode);
          }

          if (errorResponse.status === 0) {
            if (req.url?.includes("/workspace/documents")) {
              errMsg = "Загружаемый файл превышает 50Мб"
            } else {
              errMsg = this.errorService.getErrorMessage(
                ErrorType.API_UNAVAILABLE
              );
            }
          } else {
            errMsg = errorResponse.error.message
              ? errorResponse.error.message
              : errorResponse.message;
          }
        } else {
          errMsg = errorResponse.message || "Ошибка приложения";
        }

        this.authFacade
          .selectUsernameAndEventCode$()
          .pipe(
            take(1),
            switchMap(({ username, eventCode }) =>
              this.moderatorService.diagnostics({
                datetime: new Date(),
                username,
                eventCode,
                credentials: this.authFacade.getCurrentParticipantCredentials(),
                errorResponse
              })
            )
          )
          .subscribe((res) => {
            console.log(res);
          });

        this.notificator.showToastMessage(
          errMsg,
          errorResponse.error.error,
          NotificationType.Error
        );

        return throwError(() => errorResponse);
      })
    );
  }

  private eventNotFoundFromParticipant(errorResponse) {
    return (
      this.router.url.startsWith("/participant") &&
      errorResponse.error.errorCode === ErrorCode.EventService.EVENT_NOT_FOUND
    );
  }

  private attemptToLogoutFromDeletedEvent(
    url: string,
    errorCode: string
  ): boolean {
    return (
      url.endsWith(API.auth.logout) &&
      errorCode === ErrorCode.AuthService.USER_NOT_FOUND
    );
  }

  private accessTokenIsNull(errorResponse: HttpErrorResponse) {
    return (
      errorResponse.error.error_description &&
      errorResponse.error.error_description === "Invalid access token: null"
    );
  }

  private throwErrAndNavigateToLogin(error: any): Observable<never> {
    this.router.navigate(["/login"], {
      queryParamsHandling: "merge"
    });
    console.error(error);
    return throwError(error);
  }
}
