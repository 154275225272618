import { createReducer, on } from "@ngrx/store";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { LightSessionModel } from "@app/store/event/model/lightSession.model";
import { LightSessionActions } from "@app/store/event/actions/sessionLight.actions";

export interface SessionLightState extends EntityState<LightSessionModel> {}

const adapter = createEntityAdapter<LightSessionModel>({
  selectId: (a) => a.eventId
});
export const sessionLightReducer = createReducer<SessionLightState>(
  adapter.getInitialState([]),

  on(
    LightSessionActions.startLightSession,
    (state, { eventId }): SessionLightState =>
      adapter.setOne(
        { eventId },
        state
      )
  ),
  on(
    LightSessionActions.finishLightSession,
    (state, { eventId }): SessionLightState =>
      adapter.setOne(
        { eventId },
        state
      )
  ),
  on(
    LightSessionActions.remainingSeconds,
    (state, { eventId }): SessionLightState =>
      adapter.setOne(
        { eventId },
        state
      )
  )
);
