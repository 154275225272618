import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs";
import { ProjectorActions, ProjectorSuccessActions } from "../actions";
import { EventService } from "../services/event.service";

@Injectable()
export class ProjectorEffects {
  setProjectorActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectorActions.setActivity),
      switchMap(({ dto }) =>
        this.eventService
          .setProjectorActivity({
            ...dto,
            queryParams: {
              ...(dto.queryParams || {}),
              teamIds: dto.teamIds?.join(","),
            },
          })
          .pipe(map(() => dto)),
      ),
      map((dto) =>
        ProjectorSuccessActions.setActivity({
          dto,
        }),
      ),
    ),
  );

  setTimerFullscreen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectorActions.setTimerFullscreen),
        switchMap((action) =>
          this.eventService.setProjectorTimerFullscreen(action),
        ),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private eventService: EventService) {}
}
