import { animate, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "tellsy-participant-input",
  templateUrl: "./participant-input.component.html",
  styleUrls: ["./participant-input.component.scss"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ transform: "translateY(-100%)", opacity: 0 }),
        animate(
          "200ms ease-in",
          style({ transform: "translateY(0)", opacity: 1 }),
        ),
      ]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParticipantInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantInputComponent implements OnInit, ControlValueAccessor {
  @Input() error: string;
  @Input() hint: string;
  @Input() placeholder: string;
  @Input() type = "text";
  @Input("ngModel") value = "";

  @Input() uppercase = false;
  @Input() bold = false;
  @Input() disabled = false;

  @Input() autocomplete = "on";
  @Input() clearButton = false;
  @Input() debounceTime = 0;
  @Input() maxLength: number = null;
  @Input() showMaxLength: boolean;

  @Input() materialIcon: string;
  @Input() svgIcon: string;
  @Input() iconPosition: "before" | "after" = "after";
  @Input() iconSize = 14;

  @Output() enterKey = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output("ngModelChange") update: EventEmitter<string> = new EventEmitter();

  @ViewChild("input") input: ElementRef<HTMLInputElement>;

  focused = false;
  hovered = false;

  private debouncer: Subject<string> = new Subject();

  constructor() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit() {
    this.debouncer.pipe(debounceTime(this.debounceTime)).subscribe((value) => {
      this.update.emit(value);
    });
  }

  onChange() {}

  onTouch() {}

  onEnter() {
    this.enterKey.emit();
  }

  onBlur() {
    this.focused = false;
    this.blur.emit();
  }

  onFocus() {
    this.focused = true;
    this.focus.emit();
  }

  writeValue(value: any) {
    if (value !== null && this.value !== value) {
      if (this.debounceTime > 0) {
        this.debouncer.next(value);
      } else {
        this.update.emit(value);
      }
    }
  }
}
