import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { TimelineItemComponent } from "./timeline-item/timeline-item.component";
import { TimelineComponent } from "./timeline.component";

@NgModule({
  declarations: [TimelineComponent, TimelineItemComponent],
  imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
  exports: [TimelineComponent, TimelineItemComponent],
})
export class TimelineModule {}
