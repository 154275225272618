import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatLegacySelect } from "@angular/material/legacy-select";
import { debounce } from 'lodash';

@Component({
  selector: "tellsy-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent<T extends string | number = string>
  implements OnInit, OnChanges, ControlValueAccessor, AfterViewInit
{
  @Input() disabled: boolean;
  @Input() label: string | null;
  @Input() placeholder: string;
  @Input() width: number;
  @Input() options: SelectOption<T>[] = [];
  @Input() theme: "default" | "participant" = "default";
  @Input() iconPosition: "before" | "after" = "before";

  @Output() selectionChange = new EventEmitter<T>();
  @ViewChild('select') select: MatLegacySelect;

  value: T;
  optionsMap: Record<string, SelectOption<T>> = {};

  showArrowUp: boolean = false;
  showArrowDown: boolean = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      const options: SelectOption<T>[] = changes.options.currentValue;
      this.optionsMap = options.reduce(
        (result, option) => ({ ...result, [option.value]: option }),
        {},
      );
    }
  }

  ngAfterViewInit(): void {

    this.select.openedChange.subscribe((opened) => {
      if (opened) {
        this.checkForOverflow()
        this.select.panel.nativeElement.addEventListener('scroll', this.handleScroll);
      }
    });
  }
  checkForOverflow() {
    const selectPanel = this.select.panel.nativeElement;
    this.showArrowDown = selectPanel.scrollHeight > selectPanel.clientHeight;
  }

  handleScroll = debounce(() => {
    const selectOptions = this.select.panel.nativeElement.querySelectorAll('.mat-option');
    if (!selectOptions || selectOptions.length === 0) return;

    const firstVisibleOption: any = Array.from(selectOptions).find((option: any) => option.offsetTop >= this.select.panel.nativeElement.scrollTop);
    const secondVisibleOption: any = Array.from(selectOptions).find((option: any) => option.offsetTop >= this.select.panel.nativeElement.scrollTop + 20);
    const lastVisibleOption: any = Array.from(selectOptions).reverse().find((option: any) => option.offsetTop + option.offsetHeight <= this.select.panel.nativeElement.scrollTop + this.select.panel.nativeElement.offsetHeight);

    if (!firstVisibleOption || !lastVisibleOption) return;

    if ((!this.showArrowUp && firstVisibleOption == secondVisibleOption) || (this.showArrowUp && firstVisibleOption !== secondVisibleOption )) {
      this.showArrowUp = firstVisibleOption !== selectOptions[0] || secondVisibleOption !== selectOptions[1];
    }

    if (this.showArrowUp && this.select.panel.nativeElement.scrollTop == 0)  {
        this.showArrowUp = false
    }

    this.showArrowDown = this.select.panel.nativeElement.scrollTop <= 111;

  }, 10)


  onChange = (_value: T) => {};

  onTouch = () => {};

  onSelectionChanged(value: T) {
    this.writeValue(value);
    this.selectionChange.emit(value);
  }

  registerOnChange(fn: () => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: T): void {
    this.optionsMap = this.options?.reduce(
      (result, option) => ({ ...result, [option.value]: option }),
      {},
    );

    this.value = value;
    this.onChange(value);
  }

  trackBy(index: number, option: SelectOption<T>) {
    return option?.value ?? index;
  }
}

export interface SelectOption<T extends string | number = string> {
  value: T;
  label: string;
  materialIcon?: string;
  svgIcon?: string;
  iconTooltip?: string;
}
