import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ParticipantAuthState } from "@tellsy/auth-facade";
import { PARTICIPANT_AUTH_STATE_ID } from "./participant-auth.actions";

export const selectParticipantAuthState =
  createFeatureSelector<ParticipantAuthState>(PARTICIPANT_AUTH_STATE_ID);

export const selectPending = createSelector(
  selectParticipantAuthState,
  (state) => state?.pending,
);

export const selectEventCode = createSelector(
  selectParticipantAuthState,
  (state) => state?.eventCode,
);

export const selectUsername = createSelector(
  selectParticipantAuthState,
  (state) => state?.username,
);

export const selectUsernameAndEventCode = createSelector(
  selectUsername,
  selectEventCode,
  (username, eventCode) => ({ username, eventCode }),
);

export const selectNavigatedByLink = createSelector(
  selectParticipantAuthState,
  (state) => state?.navigatedByLink,
);
