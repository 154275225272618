<div fxLayout="column" fxLayoutAlign="start center">
  <button tabindex="-1" (click)="close()" class="close-button">
    <mat-icon color="primary">close</mat-icon>
  </button>

  <p class="bold title uppercase">{{ title }}</p>
  <tellsy-number-input
      min="0"
      max="15"
      width="64"
      initialValue="1"
      [hideArrows]="false"
      (changed)="setQuantity($event)"
    ></tellsy-number-input>
    <tellsy-button
      class="btn"
      theme="light"
      style="padding: 1rem 0"
      [disabled]="!isValid"
      (clicked)="submit()"
    >
      {{submitBtnName}}
    </tellsy-button>
  <div *ngIf="!isValid" class="error-message">
    {{errMessage}}
  </div>
</div>

