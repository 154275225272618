import { ActivityMessage } from "@tellsy/common/services/stomp.model";
import {
  AskQuestionActivitySettings,
  CollectDataMode,
  Question,
  QuestionAISuggestion, QuestionArgument,
  QuestionComment,
  Speaker,
  SpeakerIdToActiveMap,
  SpeakerIdToOrderMap
} from ".";

export enum AskQuestionMessageType {
  // activity
  ACTIVITY_MODE_CHANGED = "ACTIVITY_MODE_CHANGED",
  SETTINGS_CHANGED = "SETTINGS_CHANGED",

  // speakers
  SPEAKER_ADDED = "SPEAKER_ADDED",
  SPEAKER_DELETED = "SPEAKER_DELETED",
  SET_ACTIVE_SPEAKER = "SET_ACTIVE_SPEAKER",
  SET_SPEAKERS_ORDER = "SET_SPEAKERS_ORDER",
  SPEAKER_NAME_CHANGED = "SPEAKER_NAME_CHANGED",
  SPEAKER_READONLY_CHANGED = "SPEAKER_READONLY_CHANGED",
  SPEAKER_FOCUSED_PARTICIPANT_CHANGED = "SPEAKER_FOCUSED_PARTICIPANT_CHANGED",

  // questions
  SET_QUESTION_ANSWERED = "SET_QUESTION_ANSWERED",
  SET_QUESTION_FOCUSED = "SET_QUESTION_FOCUSED",
  SET_QUESTION_APPROVED = "SET_QUESTION_APPROVED",
  QUESTIONS_UPDATES = "QUESTIONS_UPDATES",
  COMMENT_ADDED = "COMMENT_ADDED",
  COMMENT_UPDATED = "COMMENT_UPDATED",
  ARGUMENT = "ARGUMENT",
  ARGUMENTS_COUNTED = "ARGUMENTS_COUNTED",
  QUESTION_AI_SUGGESTION_CHANGED = "QUESTION_AI_SUGGESTION_CHANGED",

}

// speakers
export class SpeakerAddedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SPEAKER_ADDED;
  speaker: Speaker;
}

export class SpeakerDeletedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SPEAKER_DELETED;
  speakerId: string;
}

export class SetActiveSpeakerMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SET_ACTIVE_SPEAKER;
  speakerIdToActiveMap: SpeakerIdToActiveMap;
}

export class SpeakersOrderChangedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SET_SPEAKERS_ORDER;
  speakerIdToOrderMap: SpeakerIdToOrderMap;
}

export class SpeakerNameChangedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SPEAKER_NAME_CHANGED;
  speakerId: string;
  newName: string;
}

export class SpeakerReadonlyChangedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SPEAKER_READONLY_CHANGED;
  speakerId: string;
  readonly: boolean;
}

export class SpeakerFocusedParticipantChangedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SPEAKER_FOCUSED_PARTICIPANT_CHANGED;
  speakerId: string;
  focusedParticipantId: string;
}

// questions
export class QuestionsUpdatesMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.QUESTIONS_UPDATES;
  questions: Question[];
  deletedQuestionsIds: string[];
}

export class SetQuestionAnsweredMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SET_QUESTION_ANSWERED;
  questionId: string;
  answered: boolean;
  focused: boolean;
}

export class SetQuestionFocusedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SET_QUESTION_FOCUSED;
  speakerId: string;
  questionId: string;
  focused: boolean;
}

export class SetQuestionApprovedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SET_QUESTION_APPROVED;
  questionId: string;
  approved: boolean;
}

// activity
export class ChangeActivityModeMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.ACTIVITY_MODE_CHANGED;
  mode: CollectDataMode;
}

export class SettingsChangedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.SETTINGS_CHANGED;
  settings: AskQuestionActivitySettings;
}

export class CommentAddedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.COMMENT_ADDED;
  comment: QuestionComment;
}

export class CommentUpdatedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.COMMENT_UPDATED;
  comment: QuestionComment;
  deleted: boolean
}

export class ArgumentMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.ARGUMENT;
  argument: QuestionArgument;
  deleted: boolean
}

export class identifiersMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.ARGUMENT;
}


export class ArgumentCountedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.ARGUMENTS_COUNTED;
  argument: QuestionArgument;
}

export class QuestionAISuggestionsChangedMessage extends ActivityMessage {
  static eventType = AskQuestionMessageType.QUESTION_AI_SUGGESTION_CHANGED;
  speakerId: string;
  questionAISuggestions: QuestionAISuggestion[];
}
