import { HttpClient } from "@angular/common/http";
import { Injectable, ValueProvider } from "@angular/core";
import { API } from "@app/configs/api.config";
import { ApiResponse } from "@app/models/common.models";
import { EventTheme } from "@app/models/event-theme";
import {
  DuplicateEventRequest,
  EventCodeExistsRequest,
  EventCodeRequest,
  EventExpiredRequest,
  PageableEventsShortInfo
} from "@app/models/event.models";
import { Activity, Event, EventSettingsDto } from "@app/store/event/model";
import { FileUploadService } from "@tellsy/common/services/file-upload.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class EventsService {
  constructor(
    private http: HttpClient,
    private fileUploadService: FileUploadService
  ) {
  }

  /**
   * Return duplicate Event
   *
   * @param event
   */
  duplicateEvent(event: Event): Observable<Event> {
    return this.http.post<Event>(API.events.duplicateEvent(), {
      eventId: event.id,
      newEventCode: event.eventCode,
      newName: event.name,
      creationDate: event.creationDate,
      moderatorName: event.moderatorName,
      notLimited: event.notLimited,
      startDate: event.startDate,
      endDate: event.endDate
    } as DuplicateEventRequest);
  }

  duplicateActivity(
    eventId: string,
    activityId: string,
    targetEventIds: string[]
  ): Observable<Activity> {
    return this.http.post<Activity>(
      API.event.activity.duplicateActivity(eventId),
      {
        activityId,
        eventIds: targetEventIds
      }
    );
  }

  /**
   * Load All Events
   */
  // getAllEvents(): Observable<Event[]> {
  //   const url = API.events.getAllEvents();
  //   return this.http.get<Event[]>(url).pipe(share());
  // }

  getPageableEventsShortInfo(
    expired: boolean,
    pageNumber: number,
    pageSize: number,
    search?: string,
    showEventsOnlyForCurrentModerator?: boolean
  ): Observable<PageableEventsShortInfo> {
    const params = {
      expired: expired.toString(),
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
      search,
      showEventsOnlyForCurrentModerator:
        showEventsOnlyForCurrentModerator?.toString()
    };

    if (!search) {
      delete params.search;
    }

    if (typeof showEventsOnlyForCurrentModerator !== "boolean") {
      delete params.showEventsOnlyForCurrentModerator;
    }

    return this.http.get<PageableEventsShortInfo>(
      API.events.getPageableEventsShortInfo(),
      {
        params
      }
    );
  }

  /**
   * Add Event
   *
   * @param event
   */
  addEvent(dto: EventSettingsDto): Observable<Event> {
    const url = API.events.createEvent();
    return this.http.post<Event>(url, dto);
  }

  /**
   * Delete Event
   *
   * @param eventId
   */
  deleteEvent(eventId: string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(API.events.deleteEvent(eventId));
  }

  /**
   * Set Expired Event
   *
   * @param event
   * @param eventExpired
   */
  setExpire(event: Event, eventExpired: EventExpiredRequest) {
    return this.http.post(API.events.setExpired(event.id), eventExpired);
  }

  changeEventSettings(
    eventId: string,
    request: EventSettingsDto
  ): Observable<Event> {
    return this.http.post<Event>(
      API.events.changeEventSettings(eventId),
      request
    );
  }

  changeEventTheme(
    eventId: string,
    theme: Omit<EventTheme, "logo">
  ): Observable<Event> {
    return this.http.post<Event>(API.events.changeEventTheme(eventId), theme);
  }

  changeEventThemeLogo(eventId: string, logo: File): Observable<Event> {
    return this.fileUploadService.fileUpload(
      logo,
      API.events.changeEventThemeLogo(eventId)
    );
  }

  deleteEventThemeLogo(eventId: string): Observable<Event> {
    return this.http.delete<Event>(API.events.deleteEventThemeLogo(eventId));
  }

  /**
   * Return free unique eventCode
   */
  generateEventCode() {
    return this.http.post<EventCodeRequest>(API.events.generateEventCode(), {});
  }

  /**
   * Checked eventCode does exist
   *
   * @param eventCode
   */
  checkEventCodeExistence(eventCode: string) {
    return this.http.get<EventCodeExistsRequest>(
      API.events.checkEventCodeExistence(eventCode)
    );
  }

  /**
   * Loads event name by event code on Participant log in time
   *
   * @param eventCode
   */
  getEventNameByEventCode(eventCode: string): Observable<{ eventName: string; }> {
    return this.http.get<{ eventName: string; }>(API.events.getEventNameByEventCode(eventCode));
  }
}

const EventsServiceMock = {};

export const EventsServiceMockProvider: ValueProvider = {
  provide: EventsService,
  useValue: EventsServiceMock
};
