<ng-container *ngIf="isOnline$ | async; else offline">
  <router-outlet></router-outlet>
  <app-connection-status></app-connection-status>
</ng-container>

<ng-template #offline>
  <tellsy-internet-offline-info></tellsy-internet-offline-info>
</ng-template>

<simple-notifications
  [options]="{ position: ['top', 'right'] }"
></simple-notifications>
