import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "tellsy-participant-button,[tellsy-participant-button]",
  templateUrl: "./participant-button.component.html",
  styleUrls: ["./participant-button.component.scss"],
  animations: [
    trigger("fadeAnimation", [
      transition(":enter", [
        style({ opacity: 0, display: "inline-block" }),
        animate("0.3s ease-in", style({ opacity: 1, display: "inline-block" })),
      ]),
    ]),
  ],
})
export class ParticipantButtonComponent implements OnInit {
  @Input() outlined = false;
  @Input() text = false;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() large = false;
  @Input() tabIndex = 0;
  @Input() svgIcon: string;
  @Input() width: string;
  @Input() height: string;
  @Input() type: "submit" | "reset" | "button" = "submit";
  @Input() tooltip: string;

  @Output() clicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick() {
    if (!this.loading && !this.disabled) {
      this.clicked.emit();
    }
  }
}
