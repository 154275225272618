import { DEFAULT_TELLSY_THEME } from "@app/models/event-theme";
import { EventUtils } from "@app/store/event/utils";
import { RouterQuery } from "@app/store/router/selectors";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { EventState, EVENT_STATE_ID } from "../reducers";
import {
  selectAllActivities,
  selectAllActivitiesEntities,
} from "../reducers/activity.reducer";
import { timerSelectors } from "../reducers/timer.reducer";
import {
  selectAllDocuments,
  selectAllDocumentsEntities,
} from "../reducers/workspace.reducer";

const selectEventState = createFeatureSelector<EventState>(EVENT_STATE_ID);

// Event
export const selectEvent = createSelector(
  selectEventState,
  (state) => state?.event,
);
export const selectIsEventLoading = createSelector(
  selectEvent,
  (event) => event?.loading,
);
export const selectIsEventLoaded = createSelector(
  selectEvent,
  (event) => event?.loaded,
);

export const selectIsSubEvent = createSelector(selectEvent, (event) =>
  EventUtils.isSubEvent(event),
);

// Ids
export const selectEventId = createSelector(selectEvent, (event) => event?.id);
export const selectParentEventId = createSelector(
  selectEvent,
  selectIsSubEvent,
  (event, isSubEvent) => (isSubEvent ? event.parentEventId : event?.id),
);
export const selectParentEventName = createSelector(
  selectEvent,
  selectIsSubEvent,
  (event, isSubEvent) => (isSubEvent ? event.parentEventName : event?.name),
);

// Common settings
export const selectEventName = createSelector(
  selectEvent,
  (event) => event?.name,
);
export const selectEventCode = createSelector(
  selectEvent,
  selectIsSubEvent,
  (event, isSubEvent) =>
    isSubEvent ? event.parentEventCode : event?.eventCode,
);
export const selectHelpPhone = createSelector(
  selectEvent,
  (event) => event?.helpPhone,
);
export const selectSharedIframeUrl = createSelector(
  selectEvent,
  (event) => event?.sharedIframeUrl,
);
export const selectInfoForParticipant = createSelector(
  selectEvent,
  (event) => event?.infoForParticipantHtml,
);
export const selectIsParticipantActivityTracks = createSelector(
  selectEvent,
  (event) => event?.trackParticipantTab || false,
);

// Webinar
export const selectEventWebinar = createSelector(
  selectEvent,
  (event) => event?.webinar,
);
export const selectEventWebinarUrlChat = createSelector(
  selectEventWebinar,
  (webinar) => webinar?.urlChat,
);
export const selectEventWebinarUrlBroadcast = createSelector(
  selectEventWebinar,
  (webinar) => webinar?.urlBroadcast,
);
export const selectIsMeetUrl = createSelector(
  selectEventWebinarUrlChat,
  selectEventWebinarUrlBroadcast,
  (urlChat, urlBroadcast) =>
    urlChat?.toLowerCase().includes("meet") ||
    urlBroadcast?.toLowerCase().includes("meet") ||
    false,
);

// SubEvents
export const selectIsEventHasSubEvents = createSelector(
  selectEvent,
  (event) => !!event?.subEventsLinks?.length,
);
const selectSubEventsLinks = createSelector(selectEvent, (event) =>
  event?.subEventsLinks
    ? [...event.subEventsLinks].sort((a, b) => a.order - b.order)
    : null,
);

export const selectSubEventsLinksFull = createSelector(
  selectParentEventId,
  selectParentEventName,
  selectSubEventsLinks,
  (eventId, parentEventName, subEventsLinks) => [
    {
      eventId,
      subEventName: parentEventName,
      order: 0,
      isHidden: false,
    },
    ...(subEventsLinks || []),
  ],
);

export const selectSubEventsLinksFullNotHidden = createSelector(
  selectSubEventsLinksFull,
  (subEventsLinks) => subEventsLinks.filter(({ isHidden }) => !isHidden),
);

export const selectSubEventsLinksFullWithoutCurrent = createSelector(
  selectEventId,
  selectSubEventsLinksFull,
  (eventId, subEventsLinks) =>
    subEventsLinks.filter((e) => e.eventId !== eventId),
);

// Activity
const selectActivitiesState = createSelector(
  selectEventState,
  (state) => state?.activity,
);
export const selectSetOrderInProcess = createSelector(
  selectActivitiesState,
  (state) => state?.setOrderInProcess,
);
export const selectDuplicateInProcess = createSelector(
  selectActivitiesState,
  (state) => state?.duplicateInProcess,
);

export const selectActivities = createSelector(
  selectActivitiesState,
  selectAllActivities,
);
export const selectActivitiesEntities = createSelector(
  selectActivitiesState,
  selectAllActivitiesEntities,
);
export const selectActiveActivity = createSelector(
  selectActivities,
  (activities) => activities?.find((el) => el.active),
);
export const selectSelectedActivity = createSelector(
  RouterQuery.selectRouterActivityId,
  selectActivitiesEntities,
  (routerActivityId, activitiesEntities) =>
    activitiesEntities[routerActivityId],
);
export const selectSelectedActivityIsActive = createSelector(
  selectSelectedActivity,
  (selectedActivity) =>
    selectedActivity?.active || selectedActivity?.activeForTeams?.length > 0,
);
export const selectActivityById = (activityId: string) =>
  createSelector(
    selectActivitiesEntities,
    (activitiesEntities) => activitiesEntities[activityId],
  );

export const selectActiveActivityForParticipant = (teamId?: string) =>
  createSelector(
    selectActivities,
    selectActiveActivity,
    (activities, activeActivity) =>
      teamId
        ? activities?.find((el) => el.teamIds.includes(teamId))
        : activeActivity,
  );

// Theme
export const selectTheme = createSelector(selectEvent, (event) =>
  event?.theme?.active ? event.theme : DEFAULT_TELLSY_THEME,
);
export const selectThemeColors = createSelector(
  selectTheme,
  (theme) => theme?.colors,
);
export const selectThemeLogo = createSelector(
  selectTheme,
  (theme) => theme?.logo,
);

export const selectThemeLogoFileName = createSelector(
  selectThemeLogo,
  (logo) => logo?.fileName,
);

export const selectThemeLogoOriginalFilename = createSelector(
  selectThemeLogo,
  (logo) => logo?.originalFilename,
);
export const selectIsThemeLogoShow = createSelector(
  selectThemeLogo,
  (logo) => logo?.show || false,
);
export const selectThemeLogoPresent = createSelector(
  selectThemeLogoFileName,
  (logoFileName) => !!logoFileName,
);

export const selectThemeLogoDataForUrl = createSelector(
  selectThemeLogoFileName,
  selectParentEventId,
  (fileName, eventId) => ({ fileName, eventId }),
);

// Session Light
export const selectSessionLight = createSelector(
  selectEventState,
  (state) => state?.event,
);

export const selectSessionLightEnded = createSelector(
  selectEventState,
  (state) => state?.event,
);


// Timer
export const selectTimerState = createSelector(
  selectEventState,
  (state) => state?.timer,
);

export const selectTimer = createSelector(
  selectTimerState,
  selectEventId,
  (timer, eventId) => timer?.entities[eventId],
);

export const selectAllTimers = createSelector(
  selectTimerState,
  timerSelectors.selectAll,
);

export const selectAllTimersEntities = createSelector(
  selectTimerState,
  timerSelectors.selectEntities,
);

export const selectIsTimerRunning = createSelector(
  selectTimer,
  (timer) => timer && !timer.paused,
);

// Projector
export const selectProjector = createSelector(
  selectEventState,
  (state) => state?.projector,
);

export const selectProjectorActivityId = createSelector(
  selectProjector,
  (projector) => projector?.currentActivityId,
);

export const selectProjectorActivityType = createSelector(
  selectProjector,
  (projector) => projector?.currentActivityType,
);

export const selectProjectorTeamIds = createSelector(
  selectProjector,
  (projector) => projector?.teamIds,
);

export const selectIsTimerFullscreen = createSelector(
  selectProjector,
  (projector) => projector?.showFullscreenTimer || false,
);

// Workspace
export const selectWorkspace = createSelector(
  selectEventState,
  (state) => state?.workspace,
);
export const selectIsWorkspaceLoading = createSelector(
  selectWorkspace,
  (workspace) => workspace.loading,
);

export const selectWorkspaceDocuments = createSelector(
  selectWorkspace,
  selectAllDocuments,
);
export const selectWorkspaceDocumentsEntities = createSelector(
  selectWorkspace,
  selectAllDocumentsEntities,
);
export const selectWorkspaceHasDocuments = createSelector(
  selectWorkspaceDocuments,
  (docs) => !!docs.length,
);
export const selectWorkspaceDocumentById = (id: string) =>
  createSelector(
    selectWorkspaceDocumentsEntities,
    (documentsEntities) => documentsEntities[id],
  );

export const selectVisibleDocuments = createSelector(
  selectWorkspaceDocuments,
  (documents) => documents?.filter((el) => !el.isHidden),
);

export const selectWorkspaceDocumentsProtected = createSelector(
  selectWorkspace,
  (workspace) => workspace?.documentsProtected,
);

export const selectWorkspaceDocumentsIsHidden = createSelector(
  selectWorkspace,
  selectVisibleDocuments,
  (workspace, visibleDocuments) =>
    workspace?.documentsHidden || !visibleDocuments?.length,
);
export const selectWorkspaceSchedule = createSelector(
  selectWorkspace,
  (workspace) => workspace?.schedule,
);
export const selectWorkspaceScheduleHidden = createSelector(
  selectWorkspaceSchedule,
  (schedule) => schedule?.isHidden ?? true
);
export const selectWorkspaceScheduleFilename = createSelector(
  selectWorkspaceSchedule,
  (schedule) => schedule?.fileName,
);
export const selectWorkspaceScheduleOriginalFilename = createSelector(
  selectWorkspaceSchedule,
  (schedule) => schedule?.originalFileName,
);
export const selectWorkspaceScheduleExtension = createSelector(
  selectWorkspaceSchedule,
  (schedule) =>
    schedule?.originalFileName?.slice(
      (schedule.originalFileName.lastIndexOf(".") || Infinity) + 1,
    ),
);

// Randomization
export const selectRandomization = createSelector(
  selectEventState,
  (state) => state?.randomization,
);
export const selectIsRandomizationRunning = createSelector(
  selectRandomization,
  (randomization) => randomization?.isRunning || false,
);
export const selectRandomizationChosenVariant = createSelector(
  selectRandomization,
  (randomization) => randomization?.chosenVariant,
);
