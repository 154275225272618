import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "tellsy-button,[tellsy-button]",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  animations: [
    trigger("fadeAnimation", [
      transition(":enter", [
        style({ opacity: 0, display: "inline-block" }),
        animate("0.3s ease-in", style({ opacity: 1, display: "inline-block" })),
      ]),
    ]),
  ],
})
export class ButtonComponent implements OnInit {
  @Input() theme:
    | "white"
    | "light"
    | "primary"
    | "warn"
    | "easy"
    | "role-game" = "white";
  @Input() background: string;
  @Input() raised = true;
  @Input() link = false;
  @Input() disabled = false;
  @Input() fontWeight: string | number = 400;
  @Input() loading = false;
  @Input() large = false;
  @Input() buttonClass: string;
  @Input() tabIndex = 0;
  @Input() svgIcon: string;
  @Input() width: string;
  @Input() height: string;
  @Input() type: "submit" | "reset" | "button" = "submit";

  @Output() clicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick() {
    if (!this.loading && !this.disabled) {
      this.clicked.emit();
    }
  }
}
