<div
  *ngIf="spinning && !chosenVariant"
  class="heading bold"
  fxLayoutAlign="center"
>
  {{ "shared.choose-random-variant.whoAnswer" | translate }}
</div>

<div
  *ngIf="!spinning && !!chosenVariant"
  class="heading bold"
  fxLayoutAlign="center"
>
  {{ "shared.choose-random-variant.answering" | translate }}
</div>

<div class="variants" [class.spinning]="spinning">
  <ng-container *ngIf="!!chosenVariant">
    <div class="bold chosen variant">
      <div *ngIf="chosenVariant.identifier">
        {{ chosenVariant.identifier }}
      </div>
      <div *ngIf="chosenVariant.identifierExtra">
        {{ chosenVariant.identifierExtra}}
      </div>
      <div *ngIf="chosenVariant.teamName">
        {{ chosenVariant.teamName}}
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="spinning && currentVariants?.length">
    <div
      *ngFor="let curVariant of currentVariants"
      class="variant regular"
      [style.background]="curVariant.color"
    >
      <div>{{ curVariant.variant.identifier }}</div>
    </div>
  </ng-container>
</div>




