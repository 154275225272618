import { createReducer, on } from "@ngrx/store";
import {
  EventActions,
  EventSuccessActions,
  EventWebsocketActions,
} from "../actions";
import { Randomization } from "../model";

export interface RandomizationState extends Randomization {}

const initialState: RandomizationState = null;

export const randomizationReducer = createReducer<RandomizationState>(
  initialState,

  on(EventSuccessActions.clearState, (): RandomizationState => null),
  on(EventSuccessActions.clearRandomization, (): RandomizationState => null),
  on(
    EventWebsocketActions.setRandomization,
    (_state, { randomization }): RandomizationState => randomization,
  ),
  on(
    EventActions.startRandomization,
    (state): RandomizationState => ({ ...state, isRunning: true }),
  ),
);
