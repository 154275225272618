import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FullscreenTimerComponent } from "./fullscreen-timer/fullscreen-timer.component";
import { TimerComponent } from "./timer.component";

@NgModule({
  declarations: [TimerComponent, FullscreenTimerComponent],
  exports: [TimerComponent, FullscreenTimerComponent],
  imports: [CommonModule],
})
export class TimerModule {}
