<div class="legal" fxLayout="column" fxLayoutGap="5px">
  <a (click)="openPersonalDataDialog()">
    {{ "auth.footer.documents.agreementToProcessingPersonalData" | translate }}
  </a>
  <a (click)="openConfidentialPoliticsDialog()">
    {{ "auth.footer.documents.personalDataPolicy" | translate }}
  </a>
  <a (click)="openTermsOfServiceDialog()">
    {{ "auth.footer.documents.userAgreement" | translate }}
  </a>
</div>
