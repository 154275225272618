import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { RxStompState } from "@stomp/rx-stomp";
import { StompService } from "@tellsy/common/services/stomp.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-connection-status",
  templateUrl: "./connection-status.component.html",
  styleUrls: ["./connection-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionStatusComponent implements OnInit {
  connectionStatus$: Observable<RxStompState>;

  constructor(private stompService: StompService) {}

  ngOnInit() {
    this.connectionStatus$ = this.stompService.connectionStatus$();
  }
}
