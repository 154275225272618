import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ThemeModule } from "@tellsy/theme";
import { ParticipantToolbarComponent } from "./participant-toolbar.component";

@NgModule({
  imports: [CommonModule, ThemeModule],
  declarations: [ParticipantToolbarComponent],
  exports: [ParticipantToolbarComponent],
})
export class ParticipantToolbarModule {}
