import { createAction, props, union } from "@ngrx/store";

export const PARTICIPANT_AUTH_STATE_ID = "participant-auth";

const loginParticipant = createAction(
  `[${PARTICIPANT_AUTH_STATE_ID}] Login participant`,
  props<{ eventCode: string; username: string }>()
);

const loginParticipantSuccess = createAction(
  `[${PARTICIPANT_AUTH_STATE_ID}] Login participant success`,
  props<{ eventCode: string; accessToken: string; refreshToken: string }>()
);

const saveInStoreEventCodeErrorType = createAction(
  `[${PARTICIPANT_AUTH_STATE_ID}] Save in store event code error type`,
  props<{ errorType: string }>()
);

const saveInStoreUsernameErrorType = createAction(
  `[${PARTICIPANT_AUTH_STATE_ID}] Save in store username error type`,
  props<{ errorType: string }>()
);

const saveInStoreUsernameFieldName = createAction(
  `[${PARTICIPANT_AUTH_STATE_ID}] Save in store username field name`,
  props<{ fieldName: string }>()
);

const saveInStorePending = createAction(
  `[${PARTICIPANT_AUTH_STATE_ID}] Save in store pending`,
  props<{ pending: boolean }>()
);

const saveInStoreFieldValues = createAction(
  `[${PARTICIPANT_AUTH_STATE_ID}] Save in store field values`,
  props<{
    eventCode: string;
    username: string | null | undefined;
    subEventId?: string;
    navigatedByLink?: boolean;
    navigatedByLinkWithLogin?: boolean;
  }>()
);

const saveInStoreEventName = createAction(
  `[${PARTICIPANT_AUTH_STATE_ID}] Save in store event name`,
  props<{ eventName: string; }>()
);

export const ParticipantAuthActions = {
  loginParticipant,
  loginParticipantSuccess,
  saveInStoreEventCodeErrorType,
  saveInStoreUsernameErrorType,
  saveInStoreUsernameFieldName,
  saveInStorePending,
  saveInStoreFieldValues,
  saveInStoreEventName
};

const all = union(ParticipantAuthActions);

export type ParticipantAuthActionsTypes = typeof all;
