import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs";
import {
  SecurityConfigActions,
  SecurityConfigSuccessActions,
} from "../actions";
import { AuthService } from "../services/auth.service";

@Injectable()
export class SecurityConfigEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityConfigActions.load),
      switchMap((action) =>
        this.authService.loadEventSecurityConfig(action.eventId),
      ),
      map((securityConfig) =>
        SecurityConfigSuccessActions.load({ securityConfig }),
      ),
    ),
  );

  setAuthenticateOnLoginAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityConfigActions.setAuthenticateOnLoginAttempt),
      switchMap((action) =>
        this.authService.setAuthenticateOnLoginAttempt(action),
      ),
      map(({ eventId, authenticateOnLoginAttempt }) =>
        SecurityConfigSuccessActions.setAuthenticateOnLoginAttempt({
          eventId,
          authenticateOnLoginAttempt,
        }),
      ),
    ),
  );

  setAllowOnlyOneAuthenticationAtATime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityConfigActions.setAllowOnlyOneAuthAtATime),
      switchMap((action) =>
        this.authService.setAllowOnlyOneAuthenticationAtATime(action),
      ),
      map(({ eventId, allowOnlyOneAuthAtATime }) =>
        SecurityConfigSuccessActions.setAllowOnlyOneAuthAtATime({
          eventId,
          allowOnlyOneAuthAtATime,
        }),
      ),
    ),
  );
  constructor(private actions$: Actions, private authService: AuthService) {}
}
