import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { DEFAULT_TELLSY_LOGO_URL } from "@app/models/event-theme";
import { LoadImageService } from "@app/services/image/load-image.service";
import { UIRole } from "@app/store/auth/model";
import { EventService } from "@app/store/event/services/event.service";
import { EventUtils } from "@app/store/event/utils";
import { TeamsFacade } from "@app/store/teams/facades/teams.facade";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { NotificatorService } from "@tellsy/common/services/notificator.service";
import { NotificationType } from "@tellsy/notifications";
import { truthyFilter } from "@tellsy/rxjs/operators";
import { TimerViewData } from "@tellsy/theme/timer/timer.model";
import FileSaver from "file-saver";
import QRCode from "qrcode";
import {
  combineLatest,
  map,
  Observable,
  of,
  switchMap,
  take,
  timer,
  withLatestFrom,
} from "rxjs";
import {
  ActivityActions,
  EventActions,
  EventSuccessActions,
  ProjectorActions,
  TimerActions,
  WorkspaceActions,
} from "../actions";
import {
  ActivityType,
  Event,
  EventSettingsDto,
  SetTeamsForActivityDto,
  SubEvent,
  SubEventsLink,
  Timer,
  Webinar,
} from "../model";
import {
  selectActiveActivity,
  selectActiveActivityForParticipant,
  selectActivities,
  selectActivityById,
  selectAllTimers,
  selectAllTimersEntities,
  selectDuplicateInProcess,
  selectEvent,
  selectEventCode,
  selectEventId,
  selectEventName,
  selectEventWebinar,
  selectEventWebinarUrlBroadcast,
  selectEventWebinarUrlChat,
  selectHelpPhone,
  selectInfoForParticipant,
  selectIsEventHasSubEvents,
  selectIsEventLoaded,
  selectIsEventLoading,
  selectIsMeetUrl,
  selectIsParticipantActivityTracks,
  selectIsRandomizationRunning,
  selectIsSubEvent,
  selectIsTimerFullscreen,
  selectIsTimerRunning,
  selectIsWorkspaceLoading,
  selectParentEventId,
  selectProjector,
  selectProjectorActivityId,
  selectProjectorActivityType,
  selectProjectorTeamIds,
  selectRandomization,
  selectRandomizationChosenVariant,
  selectSelectedActivity,
  selectSelectedActivityIsActive,
  selectSessionLight,
  selectSessionLightEnded,
  selectSetOrderInProcess,
  selectSharedIframeUrl,
  selectSubEventsLinksFull,
  selectSubEventsLinksFullNotHidden,
  selectSubEventsLinksFullWithoutCurrent,
  selectTheme,
  selectThemeColors,
  selectThemeLogo,
  selectThemeLogoDataForUrl,
  selectThemeLogoFileName,
  selectThemeLogoPresent,
  selectTimer,
  selectVisibleDocuments,
  selectWorkspaceDocumentById,
  selectWorkspaceDocuments,
  selectWorkspaceDocumentsIsHidden,
  selectWorkspaceDocumentsProtected,
  selectWorkspaceSchedule,
  selectWorkspaceScheduleExtension,
  selectWorkspaceScheduleFilename,
  selectWorkspaceScheduleHidden
} from "../selectors";
import { LightSessionActions } from "@app/store/event/actions/sessionLight.actions";

@Injectable({ providedIn: "root" })
export class EventFacade {
  DISPLAY_INTERFACE_PARAM = "displayInterface";

  constructor(
    private store: Store,
    private router: Router,
    private loadImageService: LoadImageService,
    private notify: NotificatorService,
    private translate: TranslateService,
    private eventService: EventService,
    private teamsFacade: TeamsFacade,
    private domSanitizer: DomSanitizer,
  ) {}

  /* Getters */

  // Event
  selectIsEventLoading$() {
    return this.store.select(selectIsEventLoading);
  }

  selectIsEventLoaded$() {
    return this.store.select(selectIsEventLoaded);
  }

  getEvent$() {
    return this.store.select(selectEvent);
  }

  getIsSubEvent$() {
    return this.store.select(selectIsSubEvent);
  }

  // Ids
  getEventId$() {
    return this.store.select(selectEventId);
  }

  getParentEventId$() {
    return this.store.select(selectParentEventId);
  }

  // Common settings
  getEventName$() {
    return this.store.select(selectEventName);
  }

  getEventCode$() {
    return this.store.select(selectEventCode);
  }

  getHelpPhone$() {
    return this.store.select(selectHelpPhone);
  }

  getSharedIframeUrl$() {
    return this.store.select(selectSharedIframeUrl);
  }

  getInfoForParticipant$() {
    return this.store.select(selectInfoForParticipant);
  }

  getIsParticipantActivityTracks$() {
    return this.store.select(selectIsParticipantActivityTracks);
  }

  // Webinar
  getEventWebinar$() {
    return this.store.select(selectEventWebinar);
  }

  getEventWebinarUrlChat$() {
    return this.store.select(selectEventWebinarUrlChat);
  }

  getEventWebinarUrlBroadcast$() {
    return this.store.select(selectEventWebinarUrlBroadcast);
  }

  getIsMeetUrl$() {
    return this.store.select(selectIsMeetUrl);
  }

  // SubEvents
  getIsEventHasSubEvents$() {
    return this.store.select(selectIsEventHasSubEvents);
  }

  getSubEventsLinksFull$() {
    return this.store.select(selectSubEventsLinksFull);
  }

  selectSubEventsLinksFullNotHidden$() {
    return this.store.select(selectSubEventsLinksFullNotHidden);
  }

  getSubEventsLinksWithoutCurrent$() {
    return this.store.select(selectSubEventsLinksFullWithoutCurrent);
  }

  // Activity
  getActivities$() {
    return this.store.select(selectActivities);
  }

  getActivitiesWithFilter$(searchQuery$: Observable<string>) {
    return combineLatest([this.getActivities$(), searchQuery$]).pipe(
      map(([activities, searchStr]) =>
        activities.filter((el) => el.name.match(new RegExp(searchStr, "gi"))),
      ),
    );
  }

  getActiveActivity$() {
    return this.store.select(selectActiveActivity);
  }

  getActiveActivityByRole$(role: UIRole, teamId?: string) {
    switch (role) {
      case "participant":
        return this.selectIsEventLoaded$().pipe(
          truthyFilter(),
          switchMap(() =>
            this.store.select(selectActiveActivityForParticipant(teamId)),
          ),
        );
    }
  }

  getActivityById$(activityId: string) {
    return this.store.select(selectActivityById(activityId));
  }

  getSelectedActivity$() {
    return this.store.select(selectSelectedActivity);
  }

  getSelectedActivityIsActive$() {
    return this.store.select(selectSelectedActivityIsActive);
  }

  selectSetOrderInProcess$() {
    return this.store.select(selectSetOrderInProcess);
  }

  selectDuplicateInProcess$() {
    return this.store.select(selectDuplicateInProcess);
  }

  // Theme
  getTheme$() {
    return this.store.select(selectTheme);
  }

  getThemeColors$() {
    return this.store.select(selectThemeColors);
  }

  getThemeLogo$() {
    return this.store.select(selectThemeLogo);
  }

  selectThemeLogoPresent$() {
    return this.store.select(selectThemeLogoPresent);
  }

  getThemeLogoUrl$() {
    return this.store.select(selectThemeLogoDataForUrl).pipe(
      switchMap((data) => {
        if (!data?.eventId || !data?.fileName) {
          return of(DEFAULT_TELLSY_LOGO_URL);
        }

        return this.loadImageService.getFileUrl$(data.eventId, data.fileName);
      }),
    );
  }

  getThemeLogoFileName$() {
    return this.store.select(selectThemeLogoFileName);
  }


  // Session Light
  getSessionLightData$ () {
   return  this.store.select(selectSessionLight);
  }

  getSessionLightFinish$ () {
    return  this.store.select(selectSessionLightEnded);
  }


  // Timer
  getTimer$() {
    return this.store.select(selectTimer);
  }

  getIsTimerRunning$() {
    return this.store.select(selectIsTimerRunning);
  }

  getAllTimers$() {
    return this.store.select(selectAllTimers);
  }

  getAllTimersEntities$() {
    return this.store.select(selectAllTimersEntities);
  }

  getAllTimersViewData$() {
    return timer(0, 200).pipe(
      switchMap(() => this.store.select(selectAllTimers)),
      map((timers) =>
        timers.reduce(
          (result, aTimer) => ({
            ...result,
            [aTimer.eventId]: this.convertTimerToViewData(aTimer),
          }),
          {} as Record<string, TimerViewData>,
        ),
      ),
    );
  }

  getTimerViewData$(): Observable<TimerViewData> {
    return timer(0, 200).pipe(
      switchMap(() => this.getTimer$()),
      truthyFilter(),
      map((aTimer) => this.convertTimerToViewData(aTimer)),
    );
  }

  // Projector
  getProjector$() {
    return this.store.select(selectProjector);
  }

  selectProjectorTeamIds$() {
    return this.store.select(selectProjectorTeamIds);
  }

  selectProjectorTeams$() {
    return combineLatest([
      this.teamsFacade.getAllTeams$(),
      this.store.select(selectProjectorTeamIds),
    ]).pipe(
      map(([teams, selectedTeamIds]) =>
        teams?.filter((team) => selectedTeamIds?.includes(team.id)),
      ),
    );
  }

  getProjectorActivityId$() {
    return this.store.select(selectProjectorActivityId);
  }

  getProjectorActivityType$() {
    return this.store.select(selectProjectorActivityType);
  }

  getIsTimerFullScreen$() {
    return this.store.select(selectIsTimerFullscreen);
  }

  // Workspace
  selectIsWorkspaceLoading$() {
    return this.store.select(selectIsWorkspaceLoading);
  }

  getWorkspaceDocuments$() {
    return this.store.select(selectWorkspaceDocuments);
  }

  getWorkspaceDocumentUrl$(documentId: string) {
    return this.store.select(selectWorkspaceDocumentById(documentId)).pipe(
      truthyFilter(),
      withLatestFrom(this.getParentEventId$()),
      switchMap(([{ fileName }, eventId]) =>
        this.loadImageService.getFileUrl$(eventId, fileName),
      ),
      map((safeUrl) => (safeUrl as any)?.changingThisBreaksApplicationSecurity),
    );
  }

  selectVisibleDocuments$() {
    return this.store.select(selectVisibleDocuments);
  }

  getWorkspaceDocumentsProtected$() {
    return this.store.select(selectWorkspaceDocumentsProtected);
  }

  getWorkspaceDocumentsIsHidden$() {
    return this.store.select(selectWorkspaceDocumentsIsHidden);
  }

  getWorkspaceSchedule$() {
    return this.store.select(selectWorkspaceSchedule);
  }
  getWorkspaceScheduleUrl$() {
    return this.getWorkspaceSchedule$().pipe(
      withLatestFrom(this.getParentEventId$()),
      switchMap(([schedule, eventId]) =>
        schedule && schedule.fileName
          ? this.loadImageService.getFileUrl$(eventId, schedule?.fileName)
          : of(null),
      ),
      map((safeUrl) => (safeUrl as any)?.changingThisBreaksApplicationSecurity),
    );
  }
  getWorkspaceScheduleHidden$() {
    return this.store.select(selectWorkspaceScheduleHidden);
  }

  getWorkspaceScheduleFilename$() {
    return this.store.select(selectWorkspaceScheduleFilename);
  }

  getWorkspaceScheduleExtension$() {
    return this.store.select(selectWorkspaceScheduleExtension);
  }

  // Randomization
  getRandomization$() {
    return this.store.select(selectRandomization);
  }

  getIsRandomizationRunning$() {
    return this.store.select(selectIsRandomizationRunning);
  }

  getRandomizationChosenVariant$() {
    return this.store.select(selectRandomizationChosenVariant);
  }
  /* */

  /* Methods */
  clearState() {
    this.store.dispatch(EventSuccessActions.clearState());
  }

  fetchEvent$(eventId: string) {
    return this.eventService.loadEvent(eventId);
  }

  loadEvent(eventId: string) {
    this.store.dispatch(EventActions.loadEvent({ eventId }));
  }

  changeSettings(eventId: string, request: EventSettingsDto) {
    this.store.dispatch(EventActions.changeSettings({ eventId, request }));
  }

  copyHelpPhone() {
    this.getHelpPhone$()
      .pipe(take(1))
      .subscribe((phone) => {
        if (phone) {
          navigator.clipboard.writeText(phone);
          this.notify.showToastMessage(
            this.translate.instant(
              "participant.sidenav.notifications.phoneCopied",
            ),
          );
        } else {
          this.notify.showToastMessage(
            this.translate.instant("participant.sidenav.notifications.noPhone"),
            "",
            NotificationType.Error,
          );
        }
      });
  }

  openWhatsAppPage(helpPhone: string) {
    window.open(`https://wa.me/${helpPhone}`, "_blank");
  }

  copyEventLink(subEventId?: string, username?: string) {
    this.getEvent$()
      .pipe(take(1))
      .subscribe((event) => {
        if (event) {
          navigator.clipboard.writeText(
            this.makeEventLink(event, subEventId, username),
          );
          this.notify.showToastMessage(
            this.translate.instant(
              "moderator.event.topPanel.copyEventLink.message",
            ),
          );
        } else {
          this.notify.showToastMessage(
            "No event to copy",
            "",
            NotificationType.Error,
          );
        }
      });
  }

  copySubEventLinks(subEventLinks: SubEventsLink[]) {
    this.getEvent$()
      .pipe(take(1))
      .subscribe((event) => {
        if (event && subEventLinks?.length) {
          navigator.clipboard.writeText(
            subEventLinks
              .map(
                (el) =>
                  `${el.subEventName} ${this.makeEventLink(event, el.eventId)}`,
              )
              .join("\n"),
          );
          this.notify.showToastMessage(
            this.translate.instant(
              "moderator.event.topPanel.copyEventLink.message",
            ),
          );
        } else {
          this.notify.showToastMessage(
            "No events to copy",
            "",
            NotificationType.Error,
          );
        }
      });
  }

  generateQr(subEventId?: string) {
    this.getEvent$()
      .pipe(take(1))
      .subscribe((event) =>
        QRCode.toDataURL(this.makeEventLink(event, subEventId), {
          width: 512,
        }).then((url: string) => {
          const subEventLink = event.subEventsLinks.find(
            (el) => el.eventId === subEventId,
          );
          const fileName = subEventLink?.subEventName || event.name;
          FileSaver.saveAs(url, `${fileName}.png`);
        }),
      );
  }

  navigateToEventActivity(navigationData: {
    role: UIRole;
    eventId: string;
    activityData?: {
      activityId: string;
      activityType: ActivityType;
    };
  }): void {
    if (!navigationData?.role || !navigationData?.eventId) {
      console.error(
        `Correct navigation data should be provided. Data received: ${navigationData}`,
      );
    }

    const { role, eventId, activityData } = navigationData;
    const path = [role, "event", eventId];

    if (activityData?.activityId && activityData?.activityType) {
      path.push(
        activityData.activityType.toLowerCase(),
        activityData.activityId,
      );
    }

    this.router.navigate(path);
  }

  // Event data updates
  setHelpPhone(eventId: string, helpPhone: string) {
    this.store.dispatch(
      EventActions.setHelpPhone({
        eventId,
        helpPhone,
      }),
    );
  }

  setWebinar(eventId: string, webinar: Webinar) {
    this.store.dispatch(
      EventActions.setWebinar({
        eventId,
        webinar,
      }),
    );
  }

  setSharedIframeUrl(eventId: string, url: string) {
    this.store.dispatch(
      EventActions.setSharedIframeUrl({
        eventId,
        url,
      }),
    );
  }

  setInfoForParticipant(eventId: string, information: string) {
    this.store.dispatch(
      EventActions.setInfoForParticipant({
        eventId,
        information,
      }),
    );
  }

  downloadReport(eventIds: string[]) {
    this.store.dispatch(
      EventActions.downloadReport({
        eventIds,
      }),
    );
  }

  switchParticipantsActivityTracking(eventId: string) {
    this.getIsParticipantActivityTracks$()
      .pipe(take(1))
      .subscribe((trackParticipantTab) =>
        this.store.dispatch(
          EventActions.setParticipantActivityTracking({
            eventId,
            trackParticipantTab: !trackParticipantTab,
          }),
        ),
      );
  }

  // Activity
  createActivity(eventId: string, activityType: ActivityType) {
    this.getSelectedActivity$()
      .pipe(take(1))
      .subscribe((activity) =>
        this.store.dispatch(
          ActivityActions.create({
            eventId,
            activityType,
            previousActivityId: activity?.activityId,
          }),
        ),
      );
  }

  deleteActivity(eventId: string, activityId: string) {
    this.store.dispatch(
      ActivityActions.delete({
        eventId,
        activityId,
      }),
    );
  }

  deleteMultiActivity(eventId: string, activityId: string) {
    this.store.dispatch(
        ActivityActions.deleteMulti({
          eventId,
          activityId,
        }),
    );
  }

  duplicateActivity(eventId: string, activityId: string, eventIds: string[]) {
    this.store.dispatch(
      ActivityActions.duplicate({
        eventId,
        activityId,
        eventIds,
      }),
    );
  }

  renameActivity(eventId: string, activityId: string, name: string) {
    this.store.dispatch(
      ActivityActions.rename({
        eventId,
        activityId,
        name,
      }),
    );
  }

  updateAiData(activityId: string, speakerId: string, aiData: string) {
    this.store.dispatch(
        ActivityActions.update({
          activityId,
          speakerId,
          aiData,
        }),
    );
  }

  setActivityOrder(eventId: string, activityId: string, order: number) {
    this.store.dispatch(
      ActivityActions.setOrder({
        eventId,
        activityId,
        order,
      }),
    );
  }

  activateActivity(eventId: string, activityId: string) {
    this.store.dispatch(
      ActivityActions.activate({
        eventId,
        activityId,
      }),
    );
  }

  deactivateActivity(eventId: string) {
    this.store.dispatch(
      ActivityActions.deactivate({
        eventId,
      }),
    );
  }

  activateNextActivity(eventId: string) {
    this.store.dispatch(
      ActivityActions.activateNext({
        eventId,
      }),
    );
  }

  activatePrevActivity(eventId: string) {
    this.store.dispatch(
      ActivityActions.activatePrev({
        eventId,
      }),
    );
  }

  // Activity for teams
  setTeamsForActivity(eventId: string, activityId: string, teamIds: string[]) {
    this.store.dispatch(
      ActivityActions.setTeams({
        eventId,
        activityId,
        teamIds,
      }),
    );
  }

  activateTeamActivities(
    eventId: string,
    teamActivities: SetTeamsForActivityDto[],
  ) {
    this.store.dispatch(
      ActivityActions.activateForTeams({
        eventId,
        teamActivities,
      }),
    );
  }

  deactivateTeamActivities(
    eventId: string,
    teamActivities: SetTeamsForActivityDto[],
  ) {
    this.store.dispatch(
      ActivityActions.deactivateForTeams({
        eventId,
        teamActivities,
      }),
    );
  }

  //Session Light
  startSessionLight(eventId: string,): void {
    this.store.dispatch(
      LightSessionActions.startLightSession({
        eventId,
      }),
    );
  }

  remainingSeconds(eventId: string,): void {
    this.store.dispatch(
      LightSessionActions.remainingSeconds({
        eventId,
      }),
    );
  }

  finishSessionLight(eventId: string,): void {
    this.store.dispatch(
      LightSessionActions.finishLightSession({
        eventId,
      }),
    );
  }

  // Timer
  loadTimer(eventId: string): void {
    this.store.dispatch(
      TimerActions.load({
        eventId,
      }),
    );
  }

  startTimer(eventIds: string[], seconds: number): void {
    this.store.dispatch(
      TimerActions.start({
        eventIds,
        seconds,
      }),
    );
  }

  stopTimer(eventIds: string[]): void {
    this.store.dispatch(
      TimerActions.stop({
        eventIds,
      }),
    );
  }

  setTimerCanBeNegative(eventIds: string[], canBeNegative: boolean): void {
    this.store.dispatch(
      TimerActions.setCanBeNegative({
        eventIds,
        canBeNegative,
      }),
    );
  }

  // Projector
  showOnProjector(
    eventId: string,
    activityType: ActivityType,
    activityId: string,
    teamIds: string[] = [],
  ) {
    this.store.dispatch(
      ProjectorActions.setActivity({
        dto: {
          eventId,
          activityType,
          activityId,
          teamIds,
          queryParams: {},
        },
      }),
    );
  }

  setProjectorTimerFullscreen(eventId: string, showFullscreenTimer: boolean) {
    this.store.dispatch(
      ProjectorActions.setTimerFullscreen({
        eventId,
        showFullscreenTimer,
      }),
    );
  }

  // Workspace
  addDocument(file: File) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.addDocument({
            eventId,
            file,
          }),
        ),
      );
  }

  deleteDocument(documentId: string) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.deleteDocument({
            eventId,
            documentId,
          }),
        ),
      );
  }

  renameDocument(documentId: string, fileName: string) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.renameDocument({
            eventId,
            documentId,
            fileName,
          }),
        ),
      );
  }

  replaceDocument(documentId: string, file: File) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.replaceDocument({
            eventId,
            documentId,
            file,
          }),
        ),
      );
  }

  setDocumentProtected(documentId: string, isProtected: boolean) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.setDocumentProtected({
            eventId,
            documentId,
            isProtected,
          }),
        ),
      );
  }

  setDocumentHidden(documentId: string, isHidden: boolean) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.setDocumentHidden({
            eventId,
            documentId,
            isHidden,
          }),
        ),
      );
  }
  setAllDocumentsProtected(documentsProtected: boolean) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.setAllDocumentsProtected({
            eventId,
            documentsProtected,
          }),
        ),
      );
  }

  setAllDocumentsHidden(documentsHidden: boolean) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.setAllDocumentsHidden({
            eventId,
            documentsHidden,
          }),
        ),
      );
  }

  setDocumentsOrders(documentsIds: string[], documentsOrders: number[]) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.setDocumentsOrders({
            eventId,
            documentsIds,
            documentsOrders,
          }),
        ),
      );
  }

  setSchedule(file: File) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.setSchedule({
            eventId,
            file,
          }),
        ),
      );
  }

  deleteSchedule() {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(WorkspaceActions.deleteSchedule({ eventId })),
      );
  }

  setScheduleHidden(isHidden: boolean) {
    this.getParentEventId$()
      .pipe(take(1))
      .subscribe((eventId) =>
        this.store.dispatch(
          WorkspaceActions.setScheduleHidden({
            eventId,
            isHidden,
          }),
        ),
      );
  }

  // Randomization
  clearRandomization() {
    this.store.dispatch(EventSuccessActions.clearRandomization());
  }

  startRandomization(
    eventId: string,
    delayTimeInSeconds: number,
    isTeamsVariant: boolean,
  ) {
    this.store.dispatch(
      EventActions.startRandomization({
        eventId,
        delayTimeInSeconds,
        isTeamsVariant,
      }),
    );
  }

  closeRandomization(eventId: string) {
    this.store.dispatch(
      EventActions.closeRandomization({
        eventId,
      }),
    );
  }

  private convertTimerToViewData(aTimer: Timer): TimerViewData {
    const {
      paused,
      durationMinThreshold,
      canBeNegative,
      remainingDuration,
      loadTimerTimestamp,
    } = aTimer;
    const remainingMs =
      remainingDuration - (new Date().getTime() - loadTimerTimestamp);
    return {
      running: !paused && remainingMs > -durationMinThreshold,
      timerAsTime: this.transformToTimeStr(remainingMs),
      timerAsSeconds: Math.round(remainingMs / 1000),
      shouldDisplayForParticipants: canBeNegative ? true : remainingMs >= 0,
      canBeNegative,
    };
  }

  private transformToTimeStr(timeInMS: string | number) {
    if (typeof timeInMS === "string") {
      timeInMS = parseInt(timeInMS, 10);
    }

    let minutes = Math.floor(Math.abs(timeInMS) / 60000);
    let seconds = Math.round((Math.abs(timeInMS) % 60000) / 1000);

    if (seconds === 60) {
      seconds = 0;
      minutes += 1;
    }

    return `${timeInMS < -500 ? "-" : ""}${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  }

  private makeEventLink(
    event: Event | SubEvent,
    subEventId?: string,
    username?: string,
  ): string {
    const baseUrl = `${location.protocol}//${location.host}/login?eventCode=`;

    let url: string;

    if (EventUtils.isSubEvent(event)) {
      url = baseUrl + event.parentEventCode;
    } else {
      url = baseUrl + event.eventCode;
    }

    if (subEventId) {
      url += `&subEventId=${subEventId}`;
    }
    if (username) {
      url += `&username=${username}`;
    }

    return url;
  }
}
