import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { User, UserRole } from "@app/store/auth/model";
import { AuthFacade, promptCookies } from "@tellsy/auth-facade";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { Observable, Subject, of } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ModeratorGuard {
  private readonly ALLOWED_AUTHORITIES: UserRole[] = [
    UserRole.ROLE_SUPERADMIN,
    UserRole.ROLE_ADMIN,
    UserRole.ROLE_MODERATORPRO,
    UserRole.ROLE_MODERATOR,
  ];
  private leftRoute$: Subject<void> = new Subject();

  constructor(
    private authFacade: AuthFacade,
    private router: Router,
    private ccService: NgcCookieConsentService,
  ) {
    promptCookies(this.ccService, this.leftRoute$);
  }

  canActivate(): Observable<boolean> {
    return this.authFacade.getUser$("moderator").pipe(
      map((user: User) => {
        if (!user) {
          this.navigateToLogin();
          return false;
        }

        const isModerator =
          user?.authorities?.some((role) =>
            this.ALLOWED_AUTHORITIES.includes(role.authority),
          ) ?? false;

        if (isModerator) {
          this.authFacade.setUserState({
            role: "moderator",
            id: user.principal.id,
            name: user.name,
          });
        }
        return isModerator;
      }),
      catchError(() => of(false)),
      takeUntil(this.leftRoute$),
    );
  }

  canDeactivate(): boolean {
    this.leftRoute$.next();
    return true;
  }

  private navigateToLogin() {
    this.router.navigate(["/login/moderator"]);
  }
}
