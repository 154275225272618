import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { PARTICIPANT_AUTH_STATE_ID } from "./store/participant-auth.actions";
import { participantAuthReducer } from "./store/participant-auth.reducer";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PARTICIPANT_AUTH_STATE_ID, participantAuthReducer),
  ],
})
export class AuthFacadeModule {}
