import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ModeratorGuard } from "@app/guard/moderator.guard";
import { ParticipantGuard } from "@app/guard/participant.guard";
import { SetPasswordComponent } from "@app/modules/auth/set-password/set-password.component";
import { NotFoundComponent } from "@app/not-found/not-found.component";
import { NavigateToEventByQueryParamsGuard } from "./guard/navigate-to-event-by-query-params.guard";
import { ModeratorAuthComponent } from "./modules/auth/moderator-auth/moderator-auth.component";
import { ParticipantAuthComponent } from "./modules/auth/participant-auth/participant-auth.component";

export const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/login",
  },
  {
    path: "login",
    component: ParticipantAuthComponent,
    canActivate: [NavigateToEventByQueryParamsGuard],
  },
  {
    path: "login/moderator",
    component: ModeratorAuthComponent,
  },
  {
    path: "moderator",
    loadChildren: () =>
      import("./modules/moderator/moderator.module").then(
        (m) => m.ModeratorModule,
      ),
    canActivate: [ModeratorGuard],
    canDeactivate: [ModeratorGuard],
  },
  {
    path: "projector",
    loadChildren: () =>
      import("./modules/projector/projector.module").then(
        (m) => m.ProjectorModule,
      ),
    canActivate: [ModeratorGuard],
    canDeactivate: [ModeratorGuard],
  },
  {
    path: "participant",
    loadChildren: () =>
      import("./modules/participant/participant.module").then(
        (m) => m.ParticipantModule,
      ),
    canActivate: [ParticipantGuard],
  },
  {
    path: "register/:token",
    component: SetPasswordComponent,
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
