import { Injectable } from "@angular/core";
import { UIRole } from "@app/store/auth/model";
import { RabbitStompService } from "@tellsy/common/services/rabbit-stomp.service";
import { truthyFilter } from "@tellsy/rxjs/operators";
import { merge, Observable, of } from "rxjs";
import { filter, map, share, skip, switchMap, tap } from "rxjs/operators";
import {
  ActivityMessage,
  Message,
  MessageType,
  ParticipantMessage,
  ServiceName,
} from "./stomp.model";

import { InternetUtils } from "@app/utils/internet";
import { RxStompState } from "@stomp/rx-stomp";
import { QuestionArgument } from "@app/store/ask-question/models";
@Injectable({ providedIn: "root" })
export class StompService {
  constructor(private rabbitService: RabbitStompService) {}

  activate() {
    this.rabbitService.activate();
  }

  deactivate() {
    this.rabbitService.deactivate();
  }

  getMessagesByType$<T extends Message>(config: {
    app: ServiceName;
    eventId: string;
    messageType: MessageType;
    role: UIRole;
    deleted?:boolean;
    argument?: QuestionArgument
  }): Observable<T> {
    if (!config?.app || !config?.messageType || !config?.role) {
      console.error("Error in getMessagesByType subscription config");
    }

    return this.watchAllStompMessages$<T>(config.eventId, config.role).pipe(
      filter(
        (mes) => mes.type === config.messageType && mes.app === config.app,
      ),
    );
  }

  getParticipantMessages$<T extends ParticipantMessage>(config: {
    app: ServiceName;
    eventId: string;
    messageType: MessageType;
    participantId: string;
    role: UIRole;
    deleted?:boolean;
    argument?: QuestionArgument
  }): Observable<T> {
    if (
      !config?.app ||
      !config?.messageType ||
      !config?.participantId ||
      !config?.role
    ) {
      console.error("Error in getParticipantMessages subscription config");
    }

    return this.getMessagesByType$<T>({
      app: config.app,
      eventId: config.eventId,
      messageType: config.messageType,
      role: config.role,
      deleted: config.deleted,
      argument: config.argument
    }).pipe(filter((mes) => mes.participantId === config.participantId));
  }

  getActivityMessages$<T extends ActivityMessage>(config: {
    app: ServiceName;
    eventId: string;
    messageType: MessageType;
    activityId: string;
    role: UIRole;
    deleted?: boolean;
    argument?: QuestionArgument;
  }): Observable<T> {
    if (
      !config?.app ||
      !config?.messageType ||
      !config?.activityId ||
      !config?.role
    ) {
      console.error("Error in getActivityMessages subscription config");
    }

    return this.getMessagesByType$<T>({
      app: config.app,
      eventId: config.eventId,
      messageType: config.messageType,
      role: config.role,
      deleted: config.deleted,
      argument: config.argument
    }).pipe(filter((mes) => mes.activityId === config.activityId));
  }

  getActivityMessagesByTypes$<T extends ActivityMessage>(config: {
    app: ServiceName;
    messageTypes: MessageType[];
    eventId: string;
    activityId: string;
    role: UIRole;
  }): Observable<T> {
    if (
      !config?.app ||
      !config?.messageTypes ||
      !config?.activityId ||
      !config?.role
    ) {
      console.error("Error in getActivityMessagesByTypes subscription config");
    }

    return this.watchAllStompMessages$<T>(config.eventId, config.role).pipe(
      filter(
        (mes) =>
          mes.activityId === config.activityId &&
          config.messageTypes.includes(mes.type) &&
          mes.app === config.app,
      ),
    );
  }

  onConnection$(): Observable<void> {
    return this.rabbitService.onConnection$().pipe(
      tap(() => console.debug("Stomp connected")),
      switchMap(() => of()),
    );
  }

  onReconnection$(): Observable<void> {
    return merge(
      this.onConnection$(),
      InternetUtils.online$.pipe(truthyFilter(), skip(1)),
    ).pipe(
      skip(1),
      tap(() => console.debug("Stomp reconnected")),
      switchMap(() => of()),
    );
  }

  connectionStatus$(): Observable<RxStompState> {
    return this.rabbitService.connectionStatus$();
  }

  private watchAllStompMessages$<T extends Message>(
    eventId: string,
    role: UIRole,
  ): Observable<T> {
    if (!eventId || !role) {
      console.error("Error in watchAllStompMessages subscription config");
      return;
    }

    const exchangeName = `${role}-exchange`;
    const routingKey = `${role}.event.${eventId}.updates`;
    return this.rabbitService.watch$(exchangeName, routingKey).pipe(
      truthyFilter(),
      map((mes) => JSON.parse(mes.body)),
      share(),
    );
  }
}
