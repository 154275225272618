/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from "@angular/core";

import { WindowWithYbugSettings } from "../polyfills";

declare let window: WindowWithYbugSettings;

@Injectable({
  providedIn: "root",
})
export class BugReportBtnService {
  private initialized: boolean;

  constructor() {}

  initialize(hideLauncher = false, userInfo?: any) {
    if (!this.initialized) {
      this.initYbug(hideLauncher, userInfo);
      this.initialized = true;
    }
  }

  openYbug(): void {
    window.Ybug.open();
  }

  private initYbug(hide_launcher: boolean, user?: any) {
    window.ybug_settings = {
      id: "44npqr4c13",
      hide_launcher,
      translate: {
        "launcherButton.Title": "Сообщить о проблеме",
        "feedbackTypeForm.Heading": "Сформировать отчет",
        "feedbackTypeForm.Subheading": "Обнаружили проблему?",
        "feedbackTypeForm.ButtonSpecific": "Отчет со скриншотом",
        "feedbackTypeForm.ButtonGeneral": "Текстовый отчет",
        "feedbackForm.Heading": "Отчет",
        "feedbackForm.TextPlaceholder": "Описание проблемы",
        "feedbackForm.AddScreenshot": "Добавить скриншот",
        "feedbackForm.EditScreenshot": "Изменить скриншот",
        "thankYouModal.Button": "Закрыть",
      },
      user,
    };

    const ybug = document.createElement("script");
    ybug.type = "text/javascript";
    ybug.async = true;
    ybug.src =
      "https://ybug.io/api/v1/button/" +
      (window as any).ybug_settings.id +
      ".js";
    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(ybug, s);
  }
}
