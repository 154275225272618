import { EventUtils } from "@app/store/event/utils";
import { createReducer, on } from "@ngrx/store";
import {
  EventActions,
  EventErrorActions,
  EventSuccessActions,
  EventWebsocketActions,
} from "../actions";
import { Event, SubEvent } from "../model";

const updateEvent = (
  state: EventEventState,
  event: Partial<Event | SubEvent>,
): EventEventState => ({
  ...state,
  ...event,
});

export interface EventEventState extends Event, SubEvent {
  loading: boolean;
  loaded: boolean;
}

const initialState: EventEventState = null;

export const eventReducer = createReducer<EventEventState>(
  initialState,

  on(EventSuccessActions.clearState, (): EventEventState => initialState),

  on(
    EventActions.loadEvent,
    (state): EventEventState => ({ ...state, loading: true, loaded: false }),
  ),

  on(
    EventSuccessActions.loadEvent,
    (state, { dto }): EventEventState => ({
      ...state,
      ...dto,
      loading: false,
      loaded: true,
    }),
  ),
  on(
    EventSuccessActions.changeSettings,
    (state, { event }): EventEventState =>
      EventUtils.isSubEvent(state)
        ? updateEvent(state, {
            parentEventCode: event.eventCode,
            parentEventName: event.name,
            subEventsLinks: event.subEventsLinks,
            notLimited: event.notLimited,
            startDate: event.startDate,
          })
        : updateEvent(state, event),
  ),
  on(EventSuccessActions.setInfoForParticipant, updateEvent),
  on(EventSuccessActions.setHelpPhone, updateEvent),
  on(
    EventSuccessActions.setSharedIframeUrl,
    (state, { url: sharedIframeUrl }): EventEventState =>
      updateEvent(state, { sharedIframeUrl }),
  ),
  on(EventSuccessActions.setTheme, updateEvent),
  on(EventSuccessActions.setWebinar, updateEvent),
  on(EventSuccessActions.setParticipantActivityTracking, updateEvent),

  // Event ~ Websocket
  on(
    EventWebsocketActions.setName,
    (state, { name }): EventEventState => ({
      ...state,
      name,
      parentEventName: name,
    }),
  ),
  on(EventWebsocketActions.setWebinar, updateEvent),
  on(
    EventWebsocketActions.setSharedIframeUrl,
    (state, { url: sharedIframeUrl }): EventEventState =>
      updateEvent(state, { sharedIframeUrl }),
  ),
  on(EventWebsocketActions.setInfoForParticipant, updateEvent),
  on(EventWebsocketActions.setParticipantsActivityTracking, updateEvent),

  on(
    EventWebsocketActions.setSubEventsLinks,
    (state, { name, subEventsLinks }): EventEventState =>
      EventUtils.isSubEvent(state)
        ? updateEvent(state, { parentEventName: name, subEventsLinks })
        : updateEvent(state, { name, subEventsLinks }),
  ),

  on(EventWebsocketActions.setTheme, updateEvent),
  on(
    EventWebsocketActions.setLogo,
    (state, { show, fileName }): EventEventState =>
      updateEvent(state, {
        theme: {
          ...state.theme,
          logo: {
            ...state.theme.logo,
            show,
            fileName,
          },
        },
      }),
  ),

  on(
    EventActions.downloadReport,
    (state): EventEventState => ({ ...state, loading: true }),
  ),
  on(
    EventSuccessActions.downloadReport,
    (state): EventEventState => ({ ...state, loading: false }),
  ),
  on(
    EventErrorActions.downloadReport,
    (state): EventEventState => ({ ...state, loading: false }),
  ),
);
