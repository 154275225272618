import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { COMMON_UTILS } from "@tellsy/common/utils";
import {
  EventTagCloudImages,
  TagCloudDataForReport,
} from "@tellsy/theme/tag-cloud/model";
import { TagCloudWorkerService } from "@tellsy/theme/tag-cloud/tag-cloud-worker.service";
import FileSaver from "file-saver";
import { catchError, concatMap, map, of, switchMap } from "rxjs";
import {
  EventActions,
  EventErrorActions,
  EventSuccessActions,
} from "../actions";
import { LoadEventDto } from "../model";
import { EventService } from "../services/event.service";

@Injectable()
export class EventEffects {
  /* On effects with { dispatch: false } store updates over websocket */

  loadEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.loadEvent),
      switchMap((action) => this.eventService.loadEvent(action.eventId)),
      map((dto: LoadEventDto) => EventSuccessActions.loadEvent({ dto })),
    ),
  );

  changeSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.changeSettings),
      switchMap(({ eventId, request }) =>
        this.eventService.changeSettings(eventId, request),
      ),
      map((event) => EventSuccessActions.changeSettings({ event })),
      catchError((error: string) =>
        of(
          EventErrorActions.changeSettings({
            error,
          }),
        ),
      ),
    ),
  );

  setHelpPhone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.setHelpPhone),
      switchMap((action) => this.eventService.setHelpPhone(action)),
      map(({ eventId, helpPhone }) =>
        EventSuccessActions.setHelpPhone({ eventId, helpPhone }),
      ),
    ),
  );

  setWebinar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.setWebinar),
        switchMap((action) => this.eventService.setWebinar(action)),
      ),
    { dispatch: false },
  );

  setSharedIframeUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.setSharedIframeUrl),
        switchMap((action) => this.eventService.setSharedIframeUrl(action)),
      ),
    { dispatch: false },
  );

  setInfoForParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.setInfoForParticipant),
      switchMap((action) => this.eventService.setInfoForParticipant(action)),
      map(({ information: infoForParticipantHtml }) =>
        EventSuccessActions.setInfoForParticipant({
          infoForParticipantHtml,
        }),
      ),
    ),
  );

  downloadReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.downloadReport),
      switchMap(({ eventIds }) =>
        this.eventService.getTagCloudActivitiesForReport({ eventIds }).pipe(
          concatMap((tagCloudData) =>
            this.transformTagCloudDataToImages(tagCloudData),
          ),
          switchMap((imagesByEvent) =>
            this.eventService.downloadReport({
              eventIds,
              imagesByEvent,
            }),
          ),
          map((res) => FileSaver.saveAs(new Blob([res]), "event_report.docx")),
          catchError((error: string) =>
            of(
              EventErrorActions.downloadReport({
                error,
              }),
            ),
          ),
        ),
      ),
      map(() => EventSuccessActions.downloadReport()),
    ),
  );

  setParticipantsActivityTracking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventActions.setParticipantActivityTracking),
      switchMap((action) =>
        this.eventService.setParticipantsActivityTracking(action),
      ),
      map(({ eventId, trackParticipantTab }) =>
        EventSuccessActions.setParticipantActivityTracking({
          eventId,
          trackParticipantTab,
        }),
      ),
    ),
  );

  startRandomization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.startRandomization),
        switchMap((action) => this.eventService.startRandomization(action)),
      ),
    { dispatch: false },
  );

  closeRandomization = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventActions.closeRandomization),
        switchMap((action) =>
          this.eventService.closeRandomization(action.eventId),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private eventService: EventService,
    private tagCloudWorker: TagCloudWorkerService,
  ) {}

  private async transformTagCloudDataToImages(
    tagCloudsData: TagCloudDataForReport[],
  ): Promise<EventTagCloudImages[]> {
    const eventIds: string[] = COMMON_UTILS.removeDuplicatesInCollection(
      Object.values(tagCloudsData).map((el) => el.eventId),
    );

    const allActivitiesIdToImageMap = Object.fromEntries(
      await Promise.all(
        Object.entries(tagCloudsData).map(async ([activityId, data]) => {
          this.tagCloudWorker.recalculate(data.tagsData, data.settings);
          return [activityId, await this.tagCloudWorker.getImageAsPNG()];
        }),
      ),
    );

    return eventIds.map((eventId) => {
      const activityIdsForThisEvent = Object.entries(tagCloudsData)
        .filter(([_activityId, data]) => data.eventId === eventId)
        .map(([activityId]) => activityId);
      return {
        eventId,
        activityIdToImageMap: Object.fromEntries(
          activityIdsForThisEvent.map((activityId) => [
            activityId,
            allActivitiesIdToImageMap[activityId],
          ]),
        ),
      };
    });
  }
}
