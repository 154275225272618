<span class="question" [innerHtml]="data.confirmationQuestion"> </span>
<span class="undo">{{ data.placeholder }}</span>

<mat-dialog-actions
  fxLayout="row nowrap"
  fxFlexAlign="center center"
  fxLayoutGap="20px"
>
  <tellsy-button (clicked)="dialogRef.close()" (click)="closeCheckboxPane()" theme="white">
    {{ "shared.dialogs.cancel" | translate }}
  </tellsy-button>

  <tellsy-button theme="primary" (clicked)="confirm()">
    {{ data.confirmButtonText }}
  </tellsy-button>
</mat-dialog-actions>
