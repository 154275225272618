import { Injectable } from "@angular/core";

export class UserCredentials {
  accessToken: string | undefined | null;
  refreshToken: string | undefined | null;
  username: string | undefined | null;
  eventCode: string | undefined | null;
}

@Injectable({
  providedIn: "root",
})
export class AuthStorage {
  readonly currentParticipantCredentialsKey = "CURRENT_PARTICIPANT_CREDENTIALS";
  readonly moderatorCredentialsKey = "MODERATOR_CREDENTIALS";

  // Moderator

  storeModeratorCredentials(credentials: UserCredentials): void {
    localStorage.setItem(
      this.moderatorCredentialsKey,
      JSON.stringify(credentials),
    );
  }

  getModeratorCredentials(): UserCredentials | null {
    return this.getCredentialsObjOrNull(this.moderatorCredentialsKey);
  }

  removeModeratorCredentials(): void {
    localStorage.removeItem(this.moderatorCredentialsKey);
  }

  // Participant

  storeParticipantCredentials(credentials: UserCredentials): void {
    this.storeCurrentParticipantCredentials(credentials);
    this.storeParticipantCredentialsForEvent(credentials);
  }

  getCurrentParticipantCredentials(): UserCredentials | null {
    return this.getCredentialsObjOrNull(this.currentParticipantCredentialsKey);
  }

  getParticipantCredentialsForEvent(eventCode: string): UserCredentials | null {
    return this.getCredentialsObjOrNull(
      this.getParticipantCredentialsForEventLocalStorageKey(eventCode),
    );
  }

  removeParticipantCurrentCredentials(): void {
    localStorage.removeItem(this.currentParticipantCredentialsKey);
  }

  removeParticipantCredentialsForEvent(eventCode: string): void {
    localStorage.removeItem(
      this.getParticipantCredentialsForEventLocalStorageKey(eventCode),
    );
  }

  private storeCurrentParticipantCredentials(
    credentials: UserCredentials,
  ): void {
    localStorage.setItem(
      this.currentParticipantCredentialsKey,
      JSON.stringify(credentials),
    );
  }

  private storeParticipantCredentialsForEvent(
    credentials: UserCredentials,
  ): void {
    if (!credentials.eventCode) {
      return console.error("eventCode not defined");
    }

    localStorage.setItem(
      this.getParticipantCredentialsForEventLocalStorageKey(
        credentials.eventCode,
      ),
      JSON.stringify(credentials),
    );
  }

  private getParticipantCredentialsForEventLocalStorageKey(
    eventCode: string,
  ): string {
    return `participant-credentials-for-event-${eventCode.toLocaleUpperCase()}`;
  }

  private getCredentialsObjOrNull(key: string): UserCredentials | null {
    if (!localStorage) {
      return null;
    }
    const credentialsStringified = localStorage.getItem(key);

    if (credentialsStringified) {
      return JSON.parse(credentialsStringified);
    } else {
      return null;
    }
  }
}
