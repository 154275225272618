import { Injectable } from "@angular/core";
import { UIRole } from "@app/store/auth/model";
import { ServiceName } from "@tellsy/common/services/stomp.model";
import { StompService } from "@tellsy/common/services/stomp.service";
import { Observable } from "rxjs";
import {
  ActivitiesChangedMessage,
  ActivityCreatedMessage,
  ActivityNameChangedMessage,
  AllDocumentsHiddenMessage,
  AllDocumentsProtectedMessage,
  ChangeProjectorActivityMessage,
  CommonActivityChangedMessage,
  DocumentDeletedMessage,
  DocumentHiddenMessage,
  DocumentRenamedMessage,
  DocumentsOrdersChangedMessage,
  DocumentUploadedMessage,
  EvenetLogoChangedMessage,
  EventInformationForParticipantMessage,
  EventMessageType,
  EventNameChangedMessage,
  EventSharedIframeUrlChangedMessage,
  EventWebinarChangedMessage,
  FullscreenTimerMessage,
  ParticipantsActivityTrackingChangedMessage,
  RandomizationMessage,
  ScheduleChangedMessage,
  SubEventLinksChangedMessage,
  TeamActivityChangedMessage,
  ThemeChangedMessage,
  TimerCanBeNegativeChangedMessage,
  TimerChangedMessage,
} from "../model";

@Injectable()
export class EventStompService {
  private readonly EVENT_SERVICE_NAME: ServiceName = "event-service";

  private readonly RANDOMIZATION_SOURCE_SERVICE_NAME: ServiceName =
    "mini-activities-service";

  constructor(private stompService: StompService) {}

  // Event
  getEventRenameMessages$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<EventNameChangedMessage> {
    return this.stompService.getMessagesByType$<EventNameChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.EVENT_NAME_CHANGED,
      eventId,
      role,
    });
  }

  getWebinarChangedMessages$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<EventWebinarChangedMessage> {
    return this.stompService.getMessagesByType$<EventWebinarChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.WEBINAR_CHANGED,
      eventId,
      role,
    });
  }

  getSharedIframeUrlMessages$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<EventSharedIframeUrlChangedMessage> {
    return this.stompService.getMessagesByType$<EventSharedIframeUrlChangedMessage>(
      {
        app: this.EVENT_SERVICE_NAME,
        messageType: EventMessageType.SHARED_IFRAME_URL_CHANGED,
        eventId,
        role,
      },
    );
  }

  getInformationForParticipantMessages$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<EventInformationForParticipantMessage> {
    return this.stompService.getMessagesByType$<EventInformationForParticipantMessage>(
      {
        app: this.EVENT_SERVICE_NAME,
        messageType: EventMessageType.INFORMATION_FOR_PARTICIPANT_CHANGED,
        eventId,
        role,
      },
    );
  }

  getParticipantsActivityTrackingChangedMessage$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getMessagesByType$<ParticipantsActivityTrackingChangedMessage>(
      {
        app: this.EVENT_SERVICE_NAME,
        messageType: EventMessageType.TRACKING_PARTICIPANT_TAB_CHANGED,
        eventId,
        role,
      },
    );
  }

  // SubEvents
  getSubEventLinksChanged$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<SubEventLinksChangedMessage> {
    return this.stompService.getMessagesByType$<SubEventLinksChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.SUB_EVENT_LINKS_CHANGED,
      eventId,
      role,
    });
  }

  // Activities
  getActivityCreatedMessages$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<ActivityCreatedMessage> {
    return this.stompService.getMessagesByType$<ActivityCreatedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.ACTIVITY_CREATED,
      eventId,
      role,
    });
  }

  getActivityNameChangedMessages$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<ActivityNameChangedMessage> {
    return this.stompService.getMessagesByType$<ActivityNameChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.ACTIVITY_NAME_CHANGED,
      eventId,
      role,
    });
  }

  getEventActivitiesChangedMessages$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<ActivitiesChangedMessage> {
    return this.stompService.getMessagesByType$<ActivitiesChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.ACTIVITIES_CHANGED,
      eventId,
      role,
    });
  }

  getCommonActiveActivityChangedMessages$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<CommonActivityChangedMessage> {
    return this.stompService.getMessagesByType$<CommonActivityChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.COMMON_ACTIVITY_CHANGED,
      eventId,
      role,
    });
  }

  // Activity for teams
  getTeamActiveActivityChanged$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<TeamActivityChangedMessage> {
    return this.stompService.getMessagesByType$<TeamActivityChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.TEAM_ACTIVITY_CHANGED,
      eventId,
      role,
    });
  }

  // Theme
  getThemeChangeMessage$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<ThemeChangedMessage> {
    return this.stompService.getMessagesByType$<ThemeChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.THEME_CHANGED,
      eventId,
      role,
    });
  }

  getThemeLogoChanged$({ eventId, role }: { eventId: string; role: UIRole }) {
    return this.stompService.getMessagesByType$<EvenetLogoChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.EVENT_LOGO_CHANGED,
      eventId,
      role,
    });
  }

  // Timer
  getTimerStartedMessage$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<TimerChangedMessage> {
    return this.stompService.getMessagesByType$<TimerChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.TIMER_STARTED,
      eventId,
      role,
    });
  }

  getTimerPausedMessage$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<TimerChangedMessage> {
    return this.stompService.getMessagesByType$<TimerChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.TIMER_PAUSED,
      eventId,
      role,
    });
  }

  getTimerCanBeNegativeChangedMessage$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<TimerCanBeNegativeChangedMessage> {
    return this.stompService.getMessagesByType$<TimerCanBeNegativeChangedMessage>(
      {
        app: this.EVENT_SERVICE_NAME,
        messageType: EventMessageType.TIMER_CAN_BE_NEGATIVE_CHANGED,
        eventId,
        role,
      },
    );
  }

  // Projector
  getProjectorActivityChanged$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<ChangeProjectorActivityMessage> {
    return this.stompService.getMessagesByType$<ChangeProjectorActivityMessage>(
      {
        app: this.EVENT_SERVICE_NAME,
        messageType: EventMessageType.PROJECTOR_ACTIVITY_CHANGED,
        eventId,
        role,
      },
    );
  }

  getFullscreenTimerChangeMessage$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<FullscreenTimerMessage> {
    return this.stompService.getMessagesByType$<FullscreenTimerMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.TIMER_FULLSCREEN_CHANGED,
      eventId,
      role,
    });
  }

  // Workspace
  getWorkspaceDocumentUploaded$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getMessagesByType$<DocumentUploadedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.WORKSPACE_DOCUMENT_UPLOADED,
      eventId,
      role,
    });
  }
  getWorkspaceDocumentDeleted$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getMessagesByType$<DocumentDeletedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.WORKSPACE_DOCUMENT_DELETED,
      eventId,
      role,
    });
  }
  getWorkspaceDocumentRenamed$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getMessagesByType$<DocumentRenamedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.WORKSPACE_DOCUMENT_RENAMED,
      eventId,
      role,
    });
  }
  getWorkspaceDocumentHiddenChanged$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getMessagesByType$<DocumentHiddenMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.WORKSPACE_DOCUMENT_HIDDEN_CHANGED,
      eventId,
      role,
    });
  }
  getWorkspaceAllDocumentsProtectedChanged$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getMessagesByType$<AllDocumentsProtectedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.WORKSPACE_ALL_DOCUMENTS_PROTECTED_CHANGED,
      eventId,
      role,
    });
  }
  getWorkspaceAllDocumentsHiddenChanged$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getMessagesByType$<AllDocumentsHiddenMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.WORKSPACE_ALL_DOCUMENTS_HIDDEN_CHANGED,
      eventId,
      role,
    });
  }
  getWorkspaceDocumentsOrderChanged$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getMessagesByType$<DocumentsOrdersChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.WORKSPACE_DOCUMENTS_ORDERS_CHANGED,
      eventId,
      role,
    });
  }

  getWorkspaceScheduleChanged$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getMessagesByType$<ScheduleChangedMessage>({
      app: this.EVENT_SERVICE_NAME,
      messageType: EventMessageType.WORKSPACE_SCHEDULE_CHANGED,
      eventId,
      role,
    });
  }

  getRandomizationChanged$({
    eventId,
    role,
  }: {
    eventId: string;
    role: UIRole;
  }): Observable<RandomizationMessage> {
    return this.stompService.getMessagesByType$<RandomizationMessage>({
      app: this.RANDOMIZATION_SOURCE_SERVICE_NAME,
      messageType: EventMessageType.RANDOMIZATION_CHANGED,
      eventId,
      role,
    });
  }
}
