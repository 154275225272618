import { createAction, props, union } from "@ngrx/store";
import {
  Moderator,
  ModeratorFormParams,
  ModeratorParamsChangeRequest,
  Tariff,
} from "@app/store/moderator/models/moderator-model";
import { Pageable, PageableResponse } from "@tellsy/common/utils";
import { PageableEventsShortInfo } from "@app/models/event.models";
import { Event } from "@app/store/event/model";
import { ModeratorsSortByEnum } from "@app/modules/moderator/admin/admin.component";

const loadModerators = createAction(
  "[Admin] Load Moderators",
  props<{ pageable: Pageable; search: string }>(),
);

const loadModeratorsSuccess = createAction(
  "[Admin] Load Moderators Success",
  props<{
    pageableResponse: PageableResponse<Moderator>;
    sortParam: ModeratorsSortByEnum;
    sortOrder: "asc" | "desc";
  }>(),
);

const loadAllModeratorsSuccess = createAction(
  "[Admin] Load All Moderators Success",
  props<{ moderators: Moderator[] }>(),
);

const changeUser = createAction(
  "[Admin] Change User",
  props<{ moderatorId: string; user: ModeratorParamsChangeRequest }>(),
);

const changeUserSuccess = createAction(
  "[Admin] Change User Success",
  props<{ moderatorId: string; user: Moderator }>(),
);

const changeCompanyName = createAction(
  "[Admin] Change company name",
  props<{ moderatorId: string; user: ModeratorParamsChangeRequest }>(),
);

const changeCompanyNameSuccess = createAction(
  "[Admin] Change company name Success",
  props<{ moderatorId: string; user: Moderator }>(),
);

const changeLicenseExpiration = createAction(
  "[Admin] Change License Expiration",
  props<{
    moderatorId: string;
    licenseStart: string;
    licenseExpiration: string;
  }>(),
);

const changeLicenseExpirationSuccess = createAction(
  "[Admin] Change License Expiration Success",
  props<{
    moderatorId: string;
    licenseStart: string;
    licenseExpiration: string;
  }>(),
);

const addModerator = createAction(
  "[Admin] Add Moderator",
  props<{
    moderator: ModeratorFormParams;
    customTariff: Tariff;
  }>(),
);

const addModeratorSuccess = createAction(
  "[Admin] Add Moderator Success",
  props<{ moderator: Moderator }>(),
);

const deleteModerator = createAction(
  "[Admin] Delete Moderator",
  props<{ moderatorId: string }>(),
);

const deleteModeratorSuccess = createAction(
  "[Admin] Delete Moderator Success",
  props<{ moderatorId: string }>(),
);

const resetFailuresCount = createAction(
  "[Admin] Reset Failures Count",
  props<{ moderatorId: string }>(),
);

const changeModeratorTariff = createAction(
  "[Admin] Change Moderator Tariff",
  props<{ moderatorId: string; tariff: string }>(),
);

const changeModeratorTariffSuccess = createAction(
  "[Admin] Change Moderator Tariff Success",
  props<{ moderatorId: string; tariff: string }>(),
);

const changeModeratorCustomTariff = createAction(
  "[Admin] Change Moderator Custom Tariff",
  props<{ moderatorId: string; tariff: Tariff }>(),
);

const changeModeratorCustomTariffSuccess = createAction(
  "[Admin] Change Moderator Custom Tariff Success",
  props<{ moderatorId: string; tariff: Tariff }>(),
);

const loadAllTariffs = createAction("[Admin] Load All tariffs");

const loadAllTariffsSuccess = createAction(
  "[Admin] Load All tariffs Success",
  props<{ tariffs: Tariff[] }>(),
);

const grantAccess = createAction(
  "[Admin] Grant Access",
  props<{ moderatorId: string; eventIds: string[] }>(),
);

const grantAccessSuccess = createAction(
  "[Admin] Grant Access Success",
  props<{ moderatorId: string; eventIds: string[] }>(),
);

const revokeAccess = createAction(
  "[Admin] Revoke Access",
  props<{ moderatorId: string; eventIds: string[] }>(),
);

const revokeAccessSuccess = createAction(
  "[Admin] Revoke Access Success",
  props<{ moderatorId: string; eventIds: string[] }>(),
);

const setNewOwner = createAction(
  "[Admin] Set New Owner",
  props<{ moderatorId: string; eventId: string }>(),
);

const setNewOwnerSuccess = createAction(
  "[Admin] Set New Owner Success",
  props<{ moderatorId: string; eventId: string }>(),
);

const loadEventsToGrantAccess = createAction(
  "[Admin] Load Events To Grant Access",
  props<{ moderatorId: string; pageNumber: number }>(),
);

const loadEventsToGrantAccessSuccess = createAction(
  "[Admin] Load Events To Grant Access Success",
  props<{ shortInfo: PageableEventsShortInfo }>(),
);

const loadGrantedAccessEvents = createAction(
  "[Admin] Load Granted Access Events",
  props<{ moderatorId: string }>(),
);

const loadGrantedAccessEventsSuccess = createAction(
  "[Admin] Load Granted Access Events Success",
  props<{ events: Event[] }>(),
);

export const AdminActions = {
  loadModerators,
  loadModeratorsSuccess,
  loadAllModeratorsSuccess,
  changeLicenseExpiration,
  changeLicenseExpirationSuccess,
  addModerator,
  addModeratorSuccess,
  deleteModerator,
  deleteModeratorSuccess,
  resetFailuresCount,
  changeModeratorTariff,
  changeModeratorTariffSuccess,
  changeModeratorCustomTariff,
  changeModeratorCustomTariffSuccess,
  changeUser,
  changeUserSuccess,
  changeCompanyName,
  changeCompanyNameSuccess,
  loadAllTariffs,
  loadAllTariffsSuccess,
  grantAccess,
  grantAccessSuccess,
  revokeAccess,
  revokeAccessSuccess,
  setNewOwner,
  setNewOwnerSuccess,
  loadEventsToGrantAccess,
  loadEventsToGrantAccessSuccess,
  loadGrantedAccessEvents,
  loadGrantedAccessEventsSuccess,
};

const all = union(AdminActions);

export type AdminActionsTypes = typeof all;
