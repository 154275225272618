import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { shouldAttachBearerToken } from "@app/services/interceptors/utils";
import { AuthFacade } from "@tellsy/auth-facade";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authFacade: AuthFacade) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (shouldAttachBearerToken(req)) {
      const token = this.authFacade.getAccessTokenForCurrentUrl();

      if (token) {
        return next.handle(
          req.clone({
            setHeaders: {
              Authorization: "Bearer " + token,
            },
          }),
        );
      }
    }
    return next.handle(req);
  }
}
