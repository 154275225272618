import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API } from "@app/configs/api.config";
import { ApiResponse } from "@app/models/common.models";
import {
  EventSecurityConfig,
  LogoutParticipantDto,
  SetAllowOnlyOneAuthAtATimeDto,
  SetAuthenticateOnLoginAttemptDto,
} from "@app/store/auth/model";
import { map } from "rxjs";

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  loadEventSecurityConfig(eventId: string) {
    return this.http.get<EventSecurityConfig>(
      API.auth.loadEventSecurityConfig(),
      {
        params: { eventId },
      },
    );
  }

  // Security config
  setAuthenticateOnLoginAttempt({
    eventId,
    authenticateOnLoginAttempt,
  }: SetAuthenticateOnLoginAttemptDto) {
    return this.http
      .post<ApiResponse>(API.auth.setAuthenticateOnLoginAttempt(eventId), {
        authenticateOnLoginAttempt,
      })
      .pipe(map(() => ({ eventId, authenticateOnLoginAttempt })));
  }

  setAllowOnlyOneAuthenticationAtATime({
    eventId,
    allowOnlyOneAuthAtATime,
  }: SetAllowOnlyOneAuthAtATimeDto) {
    return this.http
      .post<ApiResponse>(API.auth.setAllowOnlyOneAuthAtATime(eventId), {
        allowOnlyOneAuthAtATime,
      })
      .pipe(map(() => ({ eventId, allowOnlyOneAuthAtATime })));
  }

  // Auth
  logoutParticipant({ participantId }: LogoutParticipantDto) {
    return this.http.post<ApiResponse>(
      API.auth.logoutParticipant(participantId),
      {},
    );
  }

  logoutAllParticipants(eventId: string) {
    return this.http.post<ApiResponse>(
      API.auth.logoutAllParticipants(eventId),
      {},
    );
  }
}
