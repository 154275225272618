import { fromEvent, merge, of } from "rxjs";
import { map } from "rxjs/operators";

export class InternetUtils {
  static online$ = merge(
    of(navigator.onLine),
    fromEvent(window, "online").pipe(map(() => true)),
    fromEvent(window, "offline").pipe(map(() => false)),
  );
}
