import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { TextInputDialogConfig } from "@tellsy/common/services/dialog.service";

@Component({
  selector: "tellsy-text-edit-dialog",
  templateUrl: "./text-edit-dialog.component.html",
  styleUrls: ["./text-edit-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditDialogComponent implements OnInit {
  title: string;
  subtitle: string;
  submitBtnText: string;
  maxLength: number;
  content: string;
  placeholder: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TextInputDialogConfig,
    private dialogRef: MatDialogRef<TextEditDialogComponent>,
  ) {
    this.title = this.data.title;
    this.subtitle = this.data.subtitle;
    this.submitBtnText = this.data.submitBtnText;
    this.content = this.data.inputText ? data.inputText : "";
    this.maxLength = this.data.maxLength ? data.maxLength : 0;
    this.placeholder = this.data.placeholder || "";
  }

  ngOnInit() {}

  submit() {
    this.dialogRef.close(this.content);
  }
}
