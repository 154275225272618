import { User } from "@app/store/auth/model";
import { createActionGroup, props } from "@ngrx/store";
import { LogoutParticipantDto } from "../model/dto/auth.dto.model";

export const AuthActions = createActionGroup({
  source: "Auth",
  events: {
    "Logout Participant": props<LogoutParticipantDto>(),
    "Logout All Participants": props<{ eventId: string }>(),

    "Load User": props<{ role: string; id: string; name: string }>(),
    "Load User Success": props<{ user: User }>(),
  },
});
