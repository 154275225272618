import { animate, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { DEFAULT_TELLSY_LOGO_URL } from "@app/models/event-theme";
import { TranslateService } from "@ngx-translate/core";
import {
  AuthFacade,
  ParticipantAuthState,
  promptCookies,
} from "@tellsy/auth-facade";
import { DialogService } from "@tellsy/common/services/dialog.service";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-participant-auth",
  templateUrl: "./participant-auth.component.html",
  styleUrls: ["./participant-auth.component.scss"],
  animations: [
    trigger("visibilityChanged", [
      transition(":enter", [
        style({ height: "0", opacity: 0 }),
        animate(400, style({ height: "*", opacity: 1 })),
      ]),
      transition(":leave", [animate(400, style({ height: 0, opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantAuthComponent implements OnInit, OnDestroy {
  policyChecked: boolean;
  participantAuthState$: Observable<ParticipantAuthState>;
  eventCode: string;
  username: string | undefined;
  subEventId: string;

  DEFAULT_TELLSY_LOGO_URL = DEFAULT_TELLSY_LOGO_URL;

  private destroyed$ = new Subject<void>();

  constructor(
    private authFacade: AuthFacade,
    private dialogService: DialogService,
    private ccService: NgcCookieConsentService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    promptCookies(this.ccService, this.destroyed$);
    this.participantAuthState$ = this.authFacade.getParticipantAuthState$();

    if (this.authFacade.showConfidentialPolitics()) {
      this.openPersonalDataPolicyDialog();
    }
    // cause without this line after eventCode changed from state and
    // loginBtn was clicked empty string will be provided to loginParticipant
    this.subscribeOnUsernameOrEventCodeChangedInStore(
      this.participantAuthState$,
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  attemptLogin() {
    if (this.eventCode && this.policyChecked) {
      this.authFacade.loginParticipantAndNavigate(
        this.eventCode,
        this.username,
        this.subEventId,
      );
    }
  }

  openAgreementToProcessingPersonalDataDialog(e: Event) {
    e?.stopPropagation();
    e?.preventDefault();
    this.dialogService.openAgreementToProcessingPersonalDataDialog(
      this.translate.instant(
        "auth.participant.agreementToProcessingPersonalDataHeader",
      ),
    );
  }

  openPersonalDataPolicyDialog(event?: Event) {
    event?.stopPropagation();
    event?.preventDefault();
    this.dialogService.openPersonalDataPolicyDialog(
      this.translate.instant("auth.participant.personalDataPolicyHeader"),
    );
  }

  openUserAgreementDialog(event?: Event) {
    event?.stopPropagation();
    event?.preventDefault();
    this.dialogService.openUserAgreementDialog(
      this.translate.instant("auth.participant.userAgreementHeader"),
    );
  }

  private subscribeOnUsernameOrEventCodeChangedInStore(
    participantAuthState$: Observable<ParticipantAuthState>,
  ) {
    participantAuthState$
      .pipe(
        map(({ eventCode, username, subEventId }) => ({
          eventCode,
          username,
          subEventId,
        })),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe(({ eventCode, username, subEventId }) => {
        this.eventCode = eventCode;
        this.username = username;
        this.subEventId = subEventId;
      });
  }
}
