import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  ActivityActions,
  ActivitySuccessActions,
  ActivityWebsocketActions,
  EventSuccessActions,
} from "../actions";
import { Activity } from "../model";

export interface ActivityState extends EntityState<Activity> {
  setOrderInProcess: boolean;
  duplicateInProcess: boolean;
}

const adapter = createEntityAdapter<Activity>({
  selectId: (a) => a.activityId,
  sortComparer: (a, b) => a.order - b.order,
});

export const {
  selectAll: selectAllActivities,
  selectEntities: selectAllActivitiesEntities,
} = adapter.getSelectors();

const initialState: ActivityState = adapter.getInitialState({
  setOrderInProcess: false,
  duplicateInProcess: false,
});

export const activityReducer = createReducer<ActivityState>(
  adapter.getInitialState(initialState),

  on(EventSuccessActions.clearState, (): ActivityState => initialState),

  on(
    EventSuccessActions.loadEvent,
    (state, { dto }): ActivityState => adapter.setAll(dto.activities, state),
  ),

  on(
    ActivitySuccessActions.create,
    (state, { activity }): ActivityState => adapter.addOne(activity, state),
  ),
  on(
    ActivitySuccessActions.activate,
    (state, { activityId }): ActivityState =>
      adapter.map(
        (el) => ({
          ...el,
          active: el.activityId === activityId,
        }),
        state,
      ),
  ),
  on(
    ActivitySuccessActions.deactivate,
    (state): ActivityState =>
      adapter.map(
        (el) => ({
          ...el,
          active: false,
        }),
        state,
      ),
  ),

  on(
    ActivitySuccessActions.activatePrev,
    (state, { activity }): ActivityState =>
      adapter.map(
        (el) => ({
          ...el,
          active: el.activityId === activity?.activityId,
        }),
        state,
      ),
  ),

  on(
    ActivitySuccessActions.activateNext,
    (state, { activity }): ActivityState =>
      adapter.map(
        (el) => ({
          ...el,
          active: el.activityId === activity?.activityId,
        }),
        state,
      ),
  ),

  on(
    ActivitySuccessActions.delete,
    (state, { activityId }): ActivityState =>
      adapter.removeOne(activityId, state),
  ),
  on(
    ActivitySuccessActions.deleteMulti,
     (state, { activityId }): ActivityState =>{
       const arrayActivityId = activityId.split(',')
       return adapter.removeMany(arrayActivityId, state)},
    ),
  on(
    ActivityActions.duplicate,
    (state): ActivityState => ({ ...state, duplicateInProcess: true }),
  ),
  on(
    ActivitySuccessActions.duplicate,
    (state, { activities }): ActivityState =>
      adapter.upsertMany(activities, { ...state, duplicateInProcess: false }),
  ),
  on(ActivitySuccessActions.rename, (state, { activityId: id, name }) =>
    adapter.updateOne(
      {
        id,
        changes: {
          name,
        },
      },
      state,
    ),
  ),
  on(
    ActivityActions.setOrder,
    (state): ActivityState => ({ ...state, setOrderInProcess: true }),
  ),
  on(
    ActivitySuccessActions.setOrder,
    (state, { activities }): ActivityState =>
      adapter.updateMany(
        activities.map((el) => ({
          id: el.activityId,
          changes: {
            ...el,
          },
        })),
        { ...state, setOrderInProcess: false },
      ),
  ),

  on(
    ActivitySuccessActions.setTeams,
    (state, { activityId: id, teamIds }): ActivityState =>
      adapter.updateOne(
        {
          id,
          changes: { teamIds },
        },
        state,
      ),
  ),
  on(
    ActivitySuccessActions.activateForTeams,
    (state, { teamActivities }): ActivityState =>
      adapter.updateMany(
        teamActivities.map(({ activityId: id, teamIds }) => ({
          id,
          changes: {
            activeForTeams: teamIds,
          },
        })),
        state,
      ),
  ),
  on(
    ActivitySuccessActions.deactivateForTeams,
    (state, { teamActivities }): ActivityState =>
      adapter.updateMany(
        teamActivities.map(({ activityId: id, teamIds }) => {
          const activity = state.entities[id];
          const activeForTeams = activity.activeForTeams.filter(
            (el) => !teamIds.includes(el),
          );

          return {
            id,
            changes: {
              activeForTeams,
            },
          };
        }),
        state,
      ),
  ),

  on(
    ActivityWebsocketActions.add,
    (state, { activity }): ActivityState => adapter.addOne(activity, state),
  ),
  on(
    ActivityWebsocketActions.setName,
    (state, { activityId: id, name }): ActivityState =>
      adapter.updateOne({ id, changes: { name } }, state),
  ),
  on(
    ActivityWebsocketActions.setActive,
    (state, { activity }): ActivityState =>
      adapter.map(
        (el) => ({
          ...el,
          active: el.activityId === activity?.activityId,
        }),
        state,
      ),
  ),
  on(
    ActivityWebsocketActions.setActivities,
    (state, { activities }): ActivityState => adapter.setAll(activities, state),
  ),
  on(
    ActivityWebsocketActions.changeForTeam,
    (state, { activity }): ActivityState =>
      activity.activeForTeams?.length > 0
        ? adapter.updateOne(
            {
              id: activity.activityId,
              changes: {
                ...activity,
              },
            },
            adapter.map(
              (el) => ({
                ...el,
                activeForTeams: [],
              }),
              state,
            ),
          )
        : adapter.map(
            (el) => ({
              ...el,
              activeForTeams: [],
            }),
            state,
          ),
  ),
);
