import { Injectable } from "@angular/core";
import { EventFacade } from "@app/store/event/facades/event.facade";
import { ShowRandomizationDialogComponent } from "@shared/show-randomization/show-randomization-dialog.component";
import { DialogService } from "@tellsy/common/services/dialog.service";
import { truthyFilter } from "@tellsy/rxjs/operators/truthyFilter";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class RandomizationFacade {
  private randomizationDialogId: string;

  constructor(
    private eventFacade: EventFacade,
    private dialog: DialogService,
  ) {}

  subscribeOnRandomization(destroy$: Observable<any>): void {
    this.eventFacade
      .getRandomization$()
      .pipe(truthyFilter(), takeUntil(destroy$))
      .subscribe((randomization) => {
        if (randomization.isRunning) {
          this.dialog.close(this.randomizationDialogId);
          this.randomizationDialogId = this.dialog.openComponentDialog(
            ShowRandomizationDialogComponent,
            {
              data: {
                variants: randomization.variants,
              },
            },
          ).id;
        }
        if (randomization.isClosed) {
          this.dialog.close(this.randomizationDialogId);
        }
      });
  }
}

export class RandomizationFacadeMock extends RandomizationFacade {
  constructor() {
    super(null, null);
  }

  subscribeOnRandomization(destroy$: Observable<any>): void {}
}

export const randomizationFacadeMockProvider = {
  provide: RandomizationFacade,
  useValue: new RandomizationFacadeMock(),
};
