import {
  currentEventsState,
  duplicateActivitySuccess,
  EventsState,
  expiredEventsState,
  foundBySearchEventsState,
  getSearchParams,
  loading,
  selectAllCurrentEvents,
  selectAllExpiredEvents,
  selectAllFoundBySearchEvents,
} from "@app/store/events/reducers";
import { createFeatureSelector, createSelector } from "@ngrx/store";

const selectEventsState = createFeatureSelector<EventsState>("events");

const selectLoading = createSelector(selectEventsState, loading);

const selectDuplicateActivitySuccess = createSelector(
  selectEventsState,
  duplicateActivitySuccess,
);

// State
const selectCurrentEventsState = createSelector(
  selectEventsState,
  currentEventsState,
);

const selectExpiredEventsState = createSelector(
  selectEventsState,
  expiredEventsState,
);

const selectFoundBySearchEventsState = createSelector(
  selectEventsState,
  foundBySearchEventsState,
);

// Events
const selectCurrentEvents = createSelector(
  selectEventsState,
  selectAllCurrentEvents,
);

const selectExpiredEvents = createSelector(
  selectEventsState,
  selectAllExpiredEvents,
);

const changeEventThemeLogoSuccess = createSelector(
  selectEventsState,
  selectAllCurrentEvents,
);

const selectFoundBySearchEvents = createSelector(
  selectEventsState,
  selectAllFoundBySearchEvents,
);

// Loaded
const selectIsCurrentEventsFirstPageLoaded = createSelector(
  selectCurrentEventsState,
  (events) => events.firstPageLoaded,
);

const selectIsCurrentEventsLoaded = createSelector(
  selectCurrentEventsState,
  (events) => events.fullyLoaded,
);

const selectIsExpiredEventsFirstPageLoaded = createSelector(
  selectExpiredEventsState,
  (events) => events.firstPageLoaded,
);

const selectIsFoundBySearchEventsFirstPageLoaded = createSelector(
  selectFoundBySearchEventsState,
  (events) => events.firstPageLoaded,
);

const selectIsExpiredEventsLoaded = createSelector(
  selectExpiredEventsState,
  (events) => events.fullyLoaded,
);

const selectIsFoundBySearchEventsLoaded = createSelector(
  selectFoundBySearchEventsState,
  (events) => events.fullyLoaded,
);

// Page number
const selectCurrentEventsPageNumber = createSelector(
  selectCurrentEventsState,
  (events) => events.pageNumber,
);

const selectExpiredEventsPageNumber = createSelector(
  selectExpiredEventsState,
  (events) => events.pageNumber,
);

const selectFoundBySearchEventsPageNumber = createSelector(
  selectFoundBySearchEventsState,
  (events) => events.pageNumber,
);

//Search params
const selectEventsSearchParams = createSelector(
  selectEventsState,
  getSearchParams,
);

export const EventsQuery = {
  selectLoading,
  selectDuplicateActivitySuccess,
  selectCurrentEventsState,
  selectExpiredEventsState,
  selectFoundBySearchEventsState,
  selectCurrentEvents,
  selectExpiredEvents,
  selectFoundBySearchEvents,
  selectIsCurrentEventsFirstPageLoaded,
  selectIsCurrentEventsLoaded,
  selectIsExpiredEventsFirstPageLoaded,
  selectIsExpiredEventsLoaded,
  selectIsFoundBySearchEventsFirstPageLoaded,
  selectIsFoundBySearchEventsLoaded,
  selectCurrentEventsPageNumber,
  selectExpiredEventsPageNumber,
  selectFoundBySearchEventsPageNumber,
  selectEventsSearchParams,
  changeEventThemeLogoSuccess
};
