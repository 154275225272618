import {
  LogoutActionTypes,
  LogoutTypes,
} from "@app/store/logout/logout.actions";
import { UserActionsTypes } from "@app/store/user/actions/user.actions";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const USER_STATE_ID = "user";

export interface UserState {
  role: string;
  id: string;
  name: string;
}

export const initialState: UserState = {
  role: null,
  id: null,
  name: null,
};

export function userReducer(
  state = initialState,
  action: UserActionsTypes | LogoutTypes,
) {
  switch (action.type) {
    case LogoutActionTypes.logoutSuccess: {
      return initialState;
    }

    case "[User] Set User": {
      return {
        role: action.role,
        id: action.id,
        name: action.name,
      };
    }

    default:
      return state;
  }
}

const selectUserState = createFeatureSelector<UserState>(USER_STATE_ID);

const selectUserId = createSelector(selectUserState, (state) => state.id);

const selectUserName = createSelector(selectUserState, (state) => state.name);

const selectUserRole = createSelector(selectUserState, (state) => state.role);

export const UserQuery = {
  selectUserId,
  selectUserName,
  selectUserRole,
};
