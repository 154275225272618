import { A11yModule } from "@angular/cdk/a11y";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { LetDirective, PushPipe } from "@ngrx/component";
import { TranslateModule } from "@ngx-translate/core";
import { FileDropzoneModule } from "@tellsy/theme/file-dropzone/file-dropzone.module";
import { ButtonToggleComponent } from "@tellsy/theme/inputs/button-toggle/button-toggle.component";
import { AnswerComponent } from "@tellsy/theme/survey/answers-form/answer/answer.component";
import { AnswersFormComponent } from "@tellsy/theme/survey/answers-form/answers-form.component";
import { ChartComponent } from "@tellsy/theme/survey/chart/chart.component";
import { QuestionsFormResultsComponent } from "@tellsy/theme/survey/questions-form-results/questions-form-results.component";
import { ResultComponent } from "@tellsy/theme/survey/questions-form-results/result/result.component";
import { SummaryResultComponent } from "@tellsy/theme/survey/questions-form-results/summary-result/summary-result.component";
import { SummaryTeamResultComponent } from "@tellsy/theme/survey/questions-form-results/summary-team-result/summary-team-result.component";
import { QuestionComponent } from "@tellsy/theme/survey/questions-form/question/question.component";
import { QuestionsFormComponent } from "@tellsy/theme/survey/questions-form/questions-form.component";
import { StatementComponent } from "@tellsy/theme/survey/questions-form/statement/statement.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ColorPickerModule } from "ngx-color-picker";
import { CountUpModule } from "ngx-countup";
import { BigAddButtonComponent } from "./big-add-button/big-add-button.component";
import { BigButtonComponent } from "./big-button/big-button.component";
import { ButtonComponent } from "./buttons/button/button.component";
import { FlowButtonComponent } from "./buttons/flow-button/flow-button.component";
import { HelpButtonComponent } from "./buttons/help-button/help-button.component";
import { HidingButtonComponent } from "./buttons/hiding-button/hiding-button.component";
import { IconButtonComponent } from "./buttons/icon-button/icon-button.component";
import { LanguageButtonComponent } from "./buttons/language-button/language-button.component";
import { ParticipantButtonComponent } from "./buttons/participant-button/participant-button.component";
import { ParticipantIconButtonComponent } from "./buttons/participant-icon-button/participant-icon-button.component";
import { SendButtonComponent } from "./buttons/send-button/send-button.component";
import { SimplyIconButtonComponent } from "./buttons/simply-icon-button/simply-icon-button.component";
import { ActiveTabDirective } from "./buttons/tabs/active-tab.directive";
import { TabsComponent } from "./buttons/tabs/tabs.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { CardContainerComponent } from "./containers/card-container/card-container.component";
import { CardWithActionsComponent } from "./containers/card-with-actions/card-with-actions.component";
import { SpoilerComponent } from "./containers/spoiler/spoiler.component";
import { SubtitleContainerComponent } from "./containers/subtitle-container/subtitle-container.component";
import { DirectivesModule } from "./directives/directives.module";
import { DoughnutComponent } from "./doughnut/doughnut.component";
import { EisenhowerMatrixStatementPositionDirective } from "./eisenhower-matrix/eisenhower-matrix-statement/eisenhower-matrix-statement-position.directive";
import { EisenhowerMatrixStatementComponent } from "./eisenhower-matrix/eisenhower-matrix-statement/eisenhower-matrix-statement.component";
import { EisenhowerMatrixCardsComponent } from "./eisenhower-matrix/matrix-cards/eisenhower-matrix-cards.component";
import { MatrixCardsStatementsFilterPipe } from "./eisenhower-matrix/matrix-cards/matrix-cards-statements-filter.pipe";
import { EisenhowerMatrixComponent } from "./eisenhower-matrix/matrix/eisenhower-matrix.component";
import { HelpIconComponent } from "./help-icon/help-icon.component";
import { HelpRolegameAboutRoundsDialogComponent } from "./help-icon/help-rolegame-about-rounds-dialog/help-rolegame-about-rounds-dialog.component";
import { HelpRolegameDescriptionDialogComponent } from "./help-icon/help-rolegame-description-dialog/help-rolegame-description-dialog.component";
import { HelpRolegameExpertDialogComponent } from "./help-icon/help-rolegame-expert-dialog/help-rolegame-expert-dialog.component";
import { HelpRolegameParticipantsDistributionSettingsDialogComponent } from "./help-icon/help-rolegame-participants-distribution-settinings-dialog/help-rolegame-participants-distribution-settinings-dialog.component";
import { HelpRolegameQuestionnaireDialogComponent } from "./help-icon/help-rolegame-questionnaire-dialog/help-rolegame-questionnaire-dialog.component";
import { HelpRolegameRestingsDialogComponent } from "./help-icon/help-rolegame-restings-dialog/help-rolegame-restings-dialog.component";
import { HelpRolegameSectorExpertDialogComponent } from "./help-icon/help-rolegame-sector-expert-dialog/help-rolegame-sector-expert-dialog.component";
import { HelpRolegameVariantDialogComponent } from "./help-icon/help-rolegame-variant-dialog/help-rolegame-variant-dialog.component";
import { HelpWhatsappDialogComponent } from "./help-icon/help-whatsapp-dialog/help-whatsapp-dialog.component";
import { IconsRegister } from "./icons-register";
import { InfoBlockComponent } from "./info-block/info-block.component";
import { CheckboxComponent } from "./inputs/checkbox/checkbox.component";
import { DatepickerComponent } from "./inputs/datepicker/datepicker.component";
import { ErasableInputComponent } from "./inputs/erasable-input/erasable-input.component";
import { InputComponent } from "./inputs/input/input.component";
import { NumberInputComponent } from "./inputs/number-input/number-input.component";
import { ParticipantInputComponent } from "./inputs/participant-input/participant-input.component";
import { ParticipantTextareaComponent } from "./inputs/participant-textarea/participant-textarea.component";
import { SearchFormComponent } from "./inputs/search-form/search-form.component";
import { SimpleTextEditorComponent } from "./inputs/simple-text-editor/simple-text-editor.component";
import { TextEditorModule } from "./inputs/text-editor/text-editor.module";
import { InternetOfflineInfoComponent } from "./internet-offline-info/internet-offline-info.component";
import { LegalInfoComponent } from "./legal-info/legal-info.component";
import { LoadingComponent } from "./loading/loading.component";
import { MaterialModule } from "./material.module";
import { NoContentComponent } from "./no-content/no-content.component";
import { PaginatorComponent } from "./paginator/paginator.component";
import { PipesModule } from "./pipes/pipes.module";
import { PostsWallComponent } from "./posts-wall/posts-wall.component";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { ProgressCircleComponent } from "./progress-circle/progress-circle.component";
import { MultiselectMatComponent } from "./select/multiselect-mat/multiselect-mat.component";
import { SelectComponent } from "./select/select/select.component";
import { VirtualSelectComponent } from "./select/virtual-select/virtual-select.component";
import { SidePanelButtonComponent } from "./side-panel-button/side-panel-button.component";
import { TagCloudComponent } from "./tag-cloud/tag-cloud.component";
import { TimelineModule } from "./timeline/timeline.module";
import { TimerModule } from "./timer/timer.module";
import { VideoPlayerModule } from "./video-player/video-player.module";
import { TellsySlideToggleComponent } from './tellsy-slide-toggle/tellsy-slide-toggle.component';

@NgModule({
  declarations: [
    InputComponent,
    ParticipantButtonComponent,
    ParticipantInputComponent,
    ParticipantTextareaComponent,
    ButtonComponent,
    BigButtonComponent,
    MultiselectMatComponent,
    IconButtonComponent,
    ParticipantIconButtonComponent,
    SelectComponent,
    VirtualSelectComponent,
    SendButtonComponent,
    NumberInputComponent,
    ErasableInputComponent,
    SimplyIconButtonComponent,
    TabsComponent,
    ActiveTabDirective,
    CheckboxComponent,
    DatepickerComponent,
    CardContainerComponent,
    HidingButtonComponent,
    FlowButtonComponent,
    LanguageButtonComponent,
    SubtitleContainerComponent,
    BigAddButtonComponent,
    CardWithActionsComponent,
    ProgressCircleComponent,
    LegalInfoComponent,
    InfoBlockComponent,
    SimpleTextEditorComponent,
    InternetOfflineInfoComponent,
    SidePanelButtonComponent,
    TagCloudComponent,
    SearchFormComponent,
    EisenhowerMatrixCardsComponent,
    EisenhowerMatrixComponent,
    MatrixCardsStatementsFilterPipe,
    ProgressBarComponent,
    EisenhowerMatrixStatementComponent,
    PostsWallComponent,
    HelpButtonComponent,
    EisenhowerMatrixStatementPositionDirective,
    CarouselComponent,
    DoughnutComponent,
    HelpIconComponent,
    HelpRolegameDescriptionDialogComponent,
    HelpRolegameRestingsDialogComponent,
    HelpWhatsappDialogComponent,
    HelpRolegameVariantDialogComponent,
    HelpRolegameParticipantsDistributionSettingsDialogComponent,
    HelpRolegameAboutRoundsDialogComponent,
    HelpRolegameExpertDialogComponent,
    HelpRolegameSectorExpertDialogComponent,
    HelpRolegameQuestionnaireDialogComponent,
    LoadingComponent,
    NoContentComponent,
    PaginatorComponent,
    QuestionsFormComponent,
    QuestionComponent,
    StatementComponent,
    AnswersFormComponent,
    AnswerComponent,
    QuestionsFormResultsComponent,
    ResultComponent,
    ChartComponent,
    SummaryResultComponent,
    SummaryTeamResultComponent,
    SpoilerComponent,
    ButtonToggleComponent,
    ButtonToggleComponent,
    TellsySlideToggleComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ColorPickerModule,
    TextEditorModule,
    TimelineModule,
    PipesModule,
    LetDirective,
    PushPipe,
    DirectivesModule,
    VideoPlayerModule,
    TimerModule,
    PdfViewerModule,
    CountUpModule,
    ScrollingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    A11yModule,
    FileDropzoneModule,
  ],
  exports: [
    InputComponent,
    ParticipantButtonComponent,
    ParticipantInputComponent,
    ParticipantTextareaComponent,
    ButtonComponent,
    BigButtonComponent,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CountUpModule,
    MultiselectMatComponent,
    IconButtonComponent,
    ParticipantIconButtonComponent,
    SendButtonComponent,
    NumberInputComponent,
    SelectComponent,
    VirtualSelectComponent,
    SimplyIconButtonComponent,
    ErasableInputComponent,
    CardContainerComponent,
    TabsComponent,
    ActiveTabDirective,
    CheckboxComponent,
    DatepickerComponent,
    HidingButtonComponent,
    FlowButtonComponent,
    CheckboxComponent,
    LanguageButtonComponent,
    TranslateModule,
    SubtitleContainerComponent,
    BigAddButtonComponent,
    CardWithActionsComponent,
    ProgressCircleComponent,
    LegalInfoComponent,
    InfoBlockComponent,
    SimpleTextEditorComponent,
    InternetOfflineInfoComponent,
    SidePanelButtonComponent,
    TagCloudComponent,
    ColorPickerModule,
    SearchFormComponent,
    TextEditorModule,
    TimelineModule,
    EisenhowerMatrixCardsComponent,
    EisenhowerMatrixComponent,
    MatrixCardsStatementsFilterPipe,
    ProgressBarComponent,
    PipesModule,
    EisenhowerMatrixStatementComponent,
    PostsWallComponent,
    HelpButtonComponent,
    CarouselComponent,
    LetDirective,
    PushPipe,
    EisenhowerMatrixStatementPositionDirective,
    DirectivesModule,
    VideoPlayerModule,
    TimerModule,
    DoughnutComponent,
    PdfViewerModule,
    HelpIconComponent,
    LoadingComponent,
    NoContentComponent,
    DragDropModule,
    PaginatorComponent,
    QuestionsFormComponent,
    AnswersFormComponent,
    QuestionsFormResultsComponent,
    ChartComponent,
    A11yModule,
    ScrollingModule,
    FileDropzoneModule,
    SpoilerComponent,
    ButtonToggleComponent,
    ButtonToggleComponent,
    TellsySlideToggleComponent,
  ],
})
export class ThemeModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    IconsRegister.addIcons(this.iconRegistry, this.domSanitizer);
  }
}
