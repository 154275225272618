export interface QuestionComment {
  id: string;
  activityId: string;
  participantId: string;
  participantIdentifier: string;
  participantIdentifierExtra: string;
  questionId: string;
  text: string;
  dateCreated: Date;
  dateEdited: Date;
}

export interface QuestionArgument extends QuestionComment {
  type: ArgumentType
}


export enum ArgumentType {
  PROS = "PROS",
  CONS = "CONS"
}

//#region DTO
export interface AddCommentDto {
  participantIdentifier: string;
  participantIdentifierExtra?: string;
  text: string;
}

export interface AddArgumentDto {
  text: string;
  participantIdentifier: string;
  participantIdentifierExtra?: string;
  type: ArgumentType.CONS
}

export interface UpdateCommentDto {
  text: string;
}
//#endregion
