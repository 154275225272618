import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-auth-input",
  templateUrl: "./auth-input.component.html",
  styleUrls: ["./auth-input.component.scss"],
})
export class AuthInputComponent implements OnInit {
  @Input() upperCase = false;
  @Input() spellcheck = true;
  @Input() dark = true;
  @Input() label: string;
  @Input() value: string | null;
  @Input() required = false;
  @Input() bold = false;
  @Input() hint: string | null;
  @Input() type:
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  @Input() disabled = false;
  @Output() keyEnter = new EventEmitter();
  @Output() keyDown = new EventEmitter();
  @Output() valueChange = new EventEmitter<string | null>();

  constructor() {}

  ngOnInit() {}

  valueChanges($event: string) {
    this.valueChange.emit($event.trim());
  }
}
