import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { DialogService } from "@tellsy/common/services/dialog.service";
import { truthyFilter } from "@tellsy/rxjs/operators";
import { verifyFileTypeAccept } from "@tellsy/theme/inputs/text-editor/utils";
import { DOC_ORIENTATION, NgxImageCompressService } from "ngx-image-compress";
import Quill from "quill";
import { timer } from "rxjs";

const Link = Quill.import("formats/link");

export interface TextEditorConfig {
  bold: boolean;
  italic: boolean;
  underline: boolean;
  strike: boolean;
  backgroundColor: boolean;
  backgroundColorList: string[];
  color: boolean;
  blockquote: boolean;
  bulletList: boolean;
  orderedList: boolean;
  indent: boolean;
  align: boolean;
  video: boolean;
  image: boolean;
  imageCompression: {
    orientation: DOC_ORIENTATION;
    ratio: number;
    quality: number;
    maxWidth: number;
    maxHeight: number;
  };
  link: boolean;
  size: string[]
}

export const defaultTextEditorConfig: TextEditorConfig = {
  bold: true,
  italic: true,
  underline: true,
  strike: true,
  backgroundColor: true,
  backgroundColorList: [
    "#000000",
    "#e60000",
    "#ff9900",
    "#ffff00",
    "#008a00",
    "#0066cc",
    "#9933ff",
    "#ffffff",
    "#facccc",
    "#ffebcc",
    "#ffffcc",
    "#cce8cc",
    "#cce0f5",
    "#ebd6ff",
    "#bbbbbb",
    "#f06666",
    "#ffc266",
    "#ffff66",
    "#66b966",
    "#66a3e0",
    "#c285ff",
    "#888888",
    "#a10000",
    "#b26b00",
    "#b2b200",
    "#006100",
    "#0047b2",
    "#6b24b2",
    "#444444",
    "#5c0000",
    "#663d00",
    "#666600",
    "#003700",
    "#002966",
    "#3d1466",
  ],
  color: true,
  blockquote: true,
  bulletList: true,
  orderedList: true,
  indent: true,
  align: true,
  video: true,
  image: true,
  imageCompression: {
    orientation: 0,
    ratio: 50,
    quality: 50,
    maxWidth: 3000,
    maxHeight: 3000,
  },
  link: true,
  size: ['10px', '12px', '14px', '16px', '18px', '20px', '24px']
};

class MyLink extends Link {
  static create(value: string) {
    const node: HTMLElement = super.create(value);
    value = this.sanitize(value);
    node.setAttribute("href", value);
    if (node.hasAttribute("target")) {
      if (
        value.startsWith("http://tellsy.ru") ||
        value.startsWith("https://tellsy.ru") ||
        value.startsWith("tellsy.ru") ||
        value.startsWith("http://beta.tellsy.ru") ||
        value.startsWith("https://beta.tellsy.ru") ||
        value.startsWith("beta.tellsy.ru")
      ) {
        node.removeAttribute("target");
      } else {
        node.setAttribute("target", "_blank");
      }
    }

    return node;
  }
}

@Component({
  selector: "tellsy-text-editor",
  templateUrl: "./text-editor.component.html",
  styleUrls: ["./text-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorComponent implements OnInit, ControlValueAccessor {
  @Input() config: TextEditorConfig = defaultTextEditorConfig;
  @Input() value: string;
  @Input() height: number;
  @Input() showToolbar: boolean;
  @Input() placeholder = "";
  @Input() label: string;

  @ViewChild("file")
  file: ElementRef<HTMLInputElement>;

  quill: Quill;
  focused = false;
  disabled = false;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private imageCompress: NgxImageCompressService,
  ) {
    Quill.register(MyLink, true);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px'];
    Quill.register(Size, true);

  }

  ngOnInit() {
    this.config.size = this.config.size.filter(size => !!size)
    this.config.size = this.config.size.sort((a: string, b: string) => parseInt(a) - parseInt(b))
  }

  onFocusChange(focused: boolean) {
    this.focused = focused;
  }

  onChange = (val: any) => {};

  onTouch = () => {};

  registerOnChange(fn: any): void {
    this.onChange = (value, ...args) => {
      this.value = value;
      fn(value, ...args);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: string): void {
    if (!value) {
      value = "";
    }

    if (value === this.value) {
      return;
    }

    value = value.replace(/&nbsp;/g, " ");
    this.value = value;
    this.onChange(value);
    this.cd.markForCheck();
  }

  onEditorCreated(quill: Quill): void {
    this.quill = quill;
    this.quill.clipboard.addMatcher(Node.TEXT_NODE, function (node, delta) {
      const regex = /https?:\/\/[^\s]+/g;
      if (typeof node.data !== "string") {
        return;
      }
      const matches = node.data.match(regex);

      if (matches && matches.length > 0) {
        const ops = [];
        let str = node.data;
        matches.forEach(function (match) {
          const split = str.split(match);
          const beforeLink = split.shift();
          ops.push({ insert: beforeLink });
          ops.push({ insert: match, attributes: { link: match } });
          str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }

      return delta;
    });
  }

  onImageFilesSelected(allFiles: FileList): void {
    const imageFiles: File[] = Array.from(allFiles).reduce(
      (result, file) =>
        verifyFileTypeAccept(file.type, "image/*") ? [...result, file] : result,
      [],
    );

    if (!imageFiles.length) {
      return;
    }

    let insertedImagesCount = 0;
    const cursorPosition = this.quill.getSelection(false)?.index ?? 0;
    imageFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        const image =
          typeof reader.result === "string"
            ? reader.result
            : Buffer.from(reader.result).toString();

        const compressedImage = this.shouldCompressImageFile(file)
          ? await this.imageCompress.compressFile(
              image,
              this.config.imageCompression.orientation,
              this.config.imageCompression.ratio,
              this.config.imageCompression.quality,
              this.config.imageCompression.maxWidth,
              this.config.imageCompression.maxHeight,
            )
          : image;

        this.quill.insertEmbed(
          cursorPosition + insertedImagesCount++,
          "image",
          compressedImage,
          "user",
        );

        // Move cursor after last inserted image
        if (insertedImagesCount === imageFiles.length) {
          this.quill.setSelection(
            cursorPosition + insertedImagesCount,
            0,
            "user",
          );
        }
      };
    });
  }

  onImageFilesDropped(files: FileList): void {
    this.onImageFilesSelected(files);
  }

  onAddVideoClick(): void {
    if (!this.quill) {
      return;
    }

    const cursorPosition = this.quill.getSelection(false)?.index ?? 0;

    this.dialogService
      .openTextInputDialog({
        title: "Вставить ссылку ",
        submitBtnText: "Сохранить",
        placeholder: "URL или код видеопотока",
      })
      .pipe(truthyFilter())
      .subscribe((link) => {
        const src = /src\=\"([^\s]*)\"\s/.exec(link); // get src url from iframe

        this.quill.insertEmbed(
          cursorPosition,
          "video",
          src ? src[1] : link,
          "user",
        );

        timer(1).subscribe(() =>
          this.quill.setSelection(this.value.length, 0, "user"),
        );
      });
  }

  private shouldCompressImageFile(file: File): boolean {
    if (!file) {
      return false;
    }

    const fileExtension = file.name.slice(
      (Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1,
    );
    const fileType = file.type;

    const excludedExtensions = ["gif", "apng", "svg"];
    const excludedFileTypes = ["image/gif", "image/apng", "image/svg+xml"];

    if (
      excludedFileTypes.includes(fileType) ||
      excludedExtensions.includes(fileExtension)
    ) {
      return false;
    }

    return true;
  }

  changeFontSize(fontSize: string) {
    const range = this.quill.getSelection();
    if (range) {
      this.quill.format('size', fontSize);
    }
  }
}
