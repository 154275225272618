import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";

@Component({
  selector: "app-auth-footer",
  templateUrl: "./auth-footer.component.html",
  styleUrls: ["./auth-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthFooterComponent implements OnInit {
  @Input() isParticipant: boolean;

  constructor() {}

  ngOnInit() {}
}
