import { createActionGroup, props } from "@ngrx/store";
import { Projector } from "../model";
import {
  SetProjectorActivityDto,
  SetProjectorFullscreenTimerDto,
} from "../model/dto/projector.dto.model";

export const ProjectorActions = createActionGroup({
  source: "Projector",
  events: {
    "Set Activity": props<{ dto: SetProjectorActivityDto }>(),
    "Set Timer Fullscreen": props<SetProjectorFullscreenTimerDto>(),
  },
});

export const ProjectorSuccessActions = createActionGroup({
  source: "Projector Success",
  events: {
    "Load": props<{ projector: Projector }>(),
    "Set Activity": props<{ dto: SetProjectorActivityDto }>(),
    "Set Timer Fullscreen": props<{ dto: SetProjectorFullscreenTimerDto }>(),
  },
});

export const ProjectorWebsocketActions = createActionGroup({
  source: "Projector ~ Websocket",
  events: {
    "Set Activity": props<SetProjectorActivityDto>(),
    "Set Timer Fullscreen": props<{
      showFullscreenTimer: boolean;
    }>(),
  },
});
