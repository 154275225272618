import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ArrayFromNumberPipeModule } from "./array-from-number/array-from-number.module";
import { EnumToArrayPipeModule } from "./enum-to-array/enum-to-array.module";
import { MathCeilPipeModule } from "./math-ceil/math-ceil.module";
import { PhoneNumberPipeModule } from "./phone-number-pipe/phone-number.module";
import { SafePipeModule } from "./safe-pipe/safe-pipe.module";
import { SliceByLengthModule } from "./slice-by-length/slice-by-length.module";

@NgModule({
  imports: [
    CommonModule,
    ArrayFromNumberPipeModule,
    EnumToArrayPipeModule,
    MathCeilPipeModule,
    PhoneNumberPipeModule,
    SafePipeModule,
    SliceByLengthModule,
  ],
  exports: [
    ArrayFromNumberPipeModule,
    EnumToArrayPipeModule,
    MathCeilPipeModule,
    PhoneNumberPipeModule,
    SafePipeModule,
    SliceByLengthModule,
  ],
})
export class PipesModule {}
