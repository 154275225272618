<div
  class="wrapper"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="40px"
>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 133">
    <g fill="none" fill-rule="nonzero">
      <g fill="#4D4D4D">
        <path
          d="M32.9 2.1C31.5.7 29.75 0 28 0c-1.75 0-3.5.7-4.9 2.1C21.7 3.5 21 5.25 21 7v63h14V7c0-1.75-.7-3.5-2.1-4.9zM200.9 2.1C199.5.7 197.75 0 196 0c-1.75 0-3.5.7-4.9 2.1-1.4 1.4-2.1 3.15-2.1 4.9v63h14V7c0-1.75-.7-3.5-2.1-4.9zM21 126h28v7H21zM175 126h28v7h-28z"
        />
      </g>
      <path
        fill="#CFCAED"
        d="M224 119c0 3.85-3.15 7-7 7H7c-3.85 0-7-3.15-7-7V77c0-3.85 3.15-7 7-7h210c3.85 0 7 3.15 7 7v42z"
      />
      <g fill="#4D4D4D">
        <path
          d="M21 87h7v7h-7zM35 87h7v7h-7zM49 87h7v7h-7zM63 87h7v7h-7zM21 101h7v7h-7zM35 101h7v7h-7zM49 101h7v7h-7zM63 101h7v7h-7z"
        />
      </g>
      <g fill="#FFF">
        <path d="M98 87h21v21H98zM126 87h21v21h-21zM154 87h21v21h-21z" />
      </g>
      <path
        fill="#4D4D4D"
        d="M182 87.097h21V108h-21zM126 55.742h-7c0-3.832-3.15-6.968-7-6.968s-7 3.136-7 6.968h-7c0-7.665 6.3-13.936 14-13.936s14 6.271 14 13.936z"
      />
      <path
        fill="#4D4D4D"
        d="M140 55.742h-7c0-11.497-9.45-20.903-21-20.903s-21 9.406-21 20.903h-7c0-15.33 12.6-27.871 28-27.871s28 12.542 28 27.87z"
      />
      <path
        fill="#9F9D9D"
        d="M154 55.742h-7c0-19.161-15.75-34.839-35-34.839S77 36.581 77 55.742h-7c0-22.994 18.9-41.807 42-41.807s42 18.813 42 41.807z"
      />
      <path
        fill="#9F9D9D"
        d="M168 55.742h-7c0-26.826-22.05-48.774-49-48.774S63 28.916 63 55.742h-7C56 25.084 81.2 0 112 0s56 25.084 56 55.742z"
      />
    </g>
  </svg>

  <div>
    <p class="regular">Похоже возникли проблемы со связью :(</p>
    <p class="regular">Пожалуйста, проверьте соединение</p>
  </div>
</div>
