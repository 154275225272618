import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import {
  CACHE_MECHANISM,
  CACHE_NAME,
  COOKIE_ATTRIBUTES,
  COOKIE_EXPIRY,
  TranslateCacheConfig,
  TranslateCacheSettings,
} from "./translate-cache.service";

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
})
export class TranslateCacheModule {
  static forRoot(
    config: TranslateCacheConfig,
  ): ModuleWithProviders<TranslateCacheModule> {
    return {
      ngModule: TranslateCacheModule,
      providers: [
        { provide: CACHE_NAME, useValue: config.cacheName },
        { provide: CACHE_MECHANISM, useValue: config.cacheMechanism },
        { provide: COOKIE_EXPIRY, useValue: config.cookieExpiry },
        { provide: COOKIE_ATTRIBUTES, useValue: config.cookieAttributes },
        TranslateCacheSettings,
        config.cacheService,
      ],
    };
  }
}
