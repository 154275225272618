import {
  Directive,
  ElementRef,
  Host,
  Input,
  OnChanges,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "button,a[tellsyTabsItem]",
})
export class ActiveTabDirective implements OnChanges {
  @Input() activeTab: boolean;

  constructor(
    private renderer: Renderer2,
    @Host() private el: ElementRef,
  ) {}

  ngOnChanges(): void {
    if (this.activeTab) {
      this.renderer.addClass(this.el.nativeElement, "active");
      this.renderer.addClass(this.el.nativeElement, "bold");
      this.renderer.removeClass(this.el.nativeElement, "regular");
    } else {
      this.renderer.removeClass(this.el.nativeElement, "active");
      this.renderer.removeClass(this.el.nativeElement, "bold");
      this.renderer.addClass(this.el.nativeElement, "regular");
    }
  }
}
