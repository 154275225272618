import { createActionGroup, emptyProps, props } from "@ngrx/store";
import {Activity} from "../model";
import {
  ActivateActivityDto,
  ChangeActivityForTeamsDto,
  ChangeActivityOrderDto,
  CreateActivityDto,
  DeleteActivityDto,
  DuplicateActivityDto,
  RenameActivityDto,
  SetTeamsForActivityDto,
} from "../model/dto/activity.dto.model";
import {SuggestionsAiDto} from "@app/store/ask-question/models/suggestionsAi.model";

export const ActivityActions = createActionGroup({
  source: "Activity",
  events: {
    "Create": props<CreateActivityDto>(),
    "Activate": props<ActivateActivityDto>(),
    "Deactivate": props<{ eventId: string }>(),
    "Activate Next": props<{ eventId: string }>(),
    "Activate Prev": props<{ eventId: string }>(),
    "Duplicate": props<DuplicateActivityDto>(),
    "Delete": props<DeleteActivityDto>(),
    "DeleteMulti": props<DeleteActivityDto>(),
    "Rename": props<RenameActivityDto>(),
    "Update": props<SuggestionsAiDto>(),
    "Set Order": props<ChangeActivityOrderDto>(),

    "Set Teams": props<SetTeamsForActivityDto>(),
    "Activate For Teams": props<ChangeActivityForTeamsDto>(),
    "Deactivate For Teams": props<ChangeActivityForTeamsDto>(),
  },
});

export const ActivitySuccessActions = createActionGroup({
  source: "Activity Success",
  events: {
    "Create": props<{ activity: Activity }>(),
    "Activate": props<ActivateActivityDto>(),
    "Deactivate": emptyProps(),
    "Activate Next": props<{ eventId: string; activity: Activity }>(),
    "Activate Prev": props<{ eventId: string; activity: Activity }>(),
    "Duplicate": props<{ activities: Activity[] }>(),
    "Delete": props<DeleteActivityDto>(),
    "DeleteMulti": props<DeleteActivityDto>(),
    "Rename": props<{ activityId: string; name: string }>(),
    "Update": props<SuggestionsAiDto>(),
    "Set Order": props<{ activities: Activity[] }>(),

    "Set Teams": props<SetTeamsForActivityDto>(),
    "Activate For Teams": props<ChangeActivityForTeamsDto>(),
    "Deactivate For Teams": props<ChangeActivityForTeamsDto>(),
  },
});

export const ActivityWebsocketActions = createActionGroup({
  source: "Activity ~ Websocket",
  events: {
    "Add": props<{ activity: Activity }>(),
    "Set Name": props<{ activityId: string; name: string }>(),
    "Set Active": props<{ activity: Activity }>(),
    "Set Activities": props<{ activities: Activity[] }>(),

    "Change For Team": props<{ activity: Activity }>(),
  },
});
