import { QuestionArgument, QuestionComment } from "./ask-question-comment.model";

export interface Question {
  id: string;
  activityId: string;
  participantId: string;
  participantIdentifier: string;
  participantIdentifierExtra: string;
  speakerId: string;
  teamId: string;
  text: string;
  dateCreated: Date;
  dateEdited: Date;
  anonymous: boolean;
  approved: boolean;
  answered: boolean;
  focused: boolean;
  voteLikesCount: number;
  voteDislikesCount: number;
  comments: QuestionComment[];
  arguments: QuestionArgument[];
  aiSuggestionId: string;
  prosCount: number;
  consCount: number
}

export interface QuestionHistoryItem {
  date: Date;
  text: string;
}

//#region DTO
export type AddQuestionDto = Pick<Question,
  | "participantId"
  | "participantIdentifier"
  | "participantIdentifierExtra"
  | "speakerId"
  | "teamId"
  | "text"
  | "anonymous"
  | "aiSuggestionId">;

export type SuggestionDto = Pick<Question,
  | "participantId"
  | "participantIdentifier"
  | "aiSuggestionId">;

export interface UpdateQuestionDto {
  text: string;
}

export interface AddVoteDto {
  type: VoteType;
}

export enum VoteType {
  LIKE = "LIKE",
  DISLIKE = "DISLIKE",
}

export interface ArgCountType {
  questionId: string,
  prosCount: number
  consCount: number
}

//#endregion
