import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ActivityListService {
  readonly checkboxIsVisible$ = new BehaviorSubject<boolean>(false);
  readonly #resetAllMarkedItems = new Subject<boolean>();
  readonly openCreateActivity = new BehaviorSubject<boolean>(false);
  readonly #shiftSelectedAsyncActivitySubject = new BehaviorSubject<boolean>(
    false,
  );

  constructor() {}

  get resetAllMarkedItems$() {
    return this.#resetAllMarkedItems.asObservable();
  }

  get shiftSelectedAsyncActivitySubject$() {
    return this.#shiftSelectedAsyncActivitySubject.asObservable();
  }

  resetAllMarkedItems() {
    this.#resetAllMarkedItems.next(null);
  }

  emitActivityDeleted() {
    this.#shiftSelectedAsyncActivitySubject.next(false);
  }

  emitActivityAdded() {
    this.#shiftSelectedAsyncActivitySubject.next(true);
  }

  showCheckbox() {
    this.checkboxIsVisible$.next(true);
  }

  hideCheckbox() {
    this.checkboxIsVisible$.next(false)
  }

  openActivity() {
    this.openCreateActivity.next(true)
  }
}
