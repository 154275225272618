import * as FileSaver from "file-saver";

const getRandomUUID = (): string => {
  // http://www.ietf.org/rfc/rfc4122.txt
  const s = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  // eslint-disable-next-line no-bitwise
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  const uuid = s.join("");
  return uuid;
};

const arrayFromNumber = (value: number): number[] => {
  const length = Math.abs(value);
  return Array.from({ length }, (_v, k) => (value >= 0 || k === 0 ? k : -k));
};

const downloadAsCsv = <T = any>(filename: string, rows: T[][]) => {
  const processRow = (row: T[]) =>
    row.reduce((rowResult, cell) => {
      let cellValue = cell?.toString() ?? "";
      if (cell instanceof Date) {
        cellValue = cell.toLocaleString();
      }
      let cellResult = cellValue.replace(/"/g, '""');
      if (cellResult.search(/("|,|\n)/g) >= 0) {
        cellResult = `"${cellResult}"`;
      }
      return (rowResult += cellResult += ";");
    }, "");

  const csvFileContent = rows.reduce(
    (result, row) => (result += processRow(row) + "\n"),
    "",
  );

  FileSaver.saveAs(
    new Blob(
      [
        new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
        csvFileContent,
      ],
      { type: "text/csv;charset=utf-8;" },
    ),
    `${filename}.csv`,
  );
};

const trackByIndex = (index: number): number => index;

export const log =
  (): MethodDecorator => (target: () => void, key: string, descriptor: any) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      console.debug(`${key} method, args: ${JSON.stringify(args)}`);
      return originalMethod.apply(this, args);
    };

    return descriptor;
  };

const stableSort = <T>(arr: T[], compare: (arg0: T, arg1: T) => number) =>
  arr
    .map((item, index) => ({ item, index }))
    .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
    .map(({ item }) => item);

export const moveItemInArray = <T>(
  arr: T[],
  fromIndex: number,
  toIndex: number,
): T[] => {
  if (toIndex < 0) {
    toIndex = 0;
  }

  if (fromIndex === toIndex) {
    return arr;
  }

  if (toIndex > arr.length - 1) {
    toIndex = arr.length - 1;
  }

  arr = [...arr];
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);

  return arr;
};

export const shuffleArray = <T = unknown>(array: T[]) => {
  const tmpArray = array.map((el) => ({ sort: Math.random(), value: el }));
  tmpArray.sort((a, b) => a.sort - b.sort);
  return tmpArray.map((el) => el.value);
};

const removeDuplicatesById = <T extends { id: string | number }>(
  collection: T[],
) =>
  collection.filter(
    (el, index) => collection.findIndex((el2) => el2.id === el.id) === index,
  );

const removeDuplicatesByKey = <T>(collection: T[], key = "id") =>
  collection.filter(
    (el, index) =>
      collection.findIndex((el2) => el2[key] === el[key]) === index,
  );

const removeDuplicatesInCollection = <T = unknown>(collection: T[]) =>
  collection.filter(
    (el, index) => collection.findIndex((el2) => el2 === el) === index,
  );

export const COMMON_UTILS = {
  getRandomUUID,
  arrayFromNumber,
  downloadAsCsv,
  trackByIndex,
  stableSort,
  moveItemInArray,
  shuffleArray,
  removeDuplicatesById,
  removeDuplicatesByKey,
  removeDuplicatesInCollection,
};
