import { createActionGroup, emptyProps, props } from "@ngrx/store";
import {
  EventSecurityConfig,
  SetAllowOnlyOneAuthAtATimeDto,
  SetAuthenticateOnLoginAttemptDto,
} from "../model";

export const SecurityConfigActions = createActionGroup({
  source: "SecurityConfig",
  events: {
    "load": props<{ eventId: string }>(),

    "Set Authenticate On Login Attempt":
      props<SetAuthenticateOnLoginAttemptDto>(),
    "Set Allow Only One Auth At A Time": props<SetAllowOnlyOneAuthAtATimeDto>(),
  },
});

export const SecurityConfigSuccessActions = createActionGroup({
  source: "SecurityConfig Success",
  events: {
    "clear Store": emptyProps(),

    "load": props<{ securityConfig: EventSecurityConfig }>(),

    "Set Authenticate On Login Attempt":
      props<SetAuthenticateOnLoginAttemptDto>(),
    "Set Allow Only One Auth At A Time": props<SetAllowOnlyOneAuthAtATimeDto>(),
  },
});

export const SecurityConfigWebsocketActions = createActionGroup({
  source: "SecurityConfig ~ Websocket",
  events: {
    "Set Event Code": props<{ eventCode: string }>(),
    "Set Number Of Participants": props<{ numberOfParticipants: number }>(),
  },
});
