import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { API } from "@app/configs/api.config";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoadImageService {
  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
  ) {}

  getFileUrl$(bucketId: string, fileName: string): Observable<SafeUrl> {
    return this.getSafeFileUrl$(API.files.getFilePath(bucketId, fileName));
  }

  getSafeFileUrl$(path: string): Observable<SafeUrl> {
    return this.httpClient.get(path, { responseType: "blob" }).pipe(
      map((res) => URL.createObjectURL(res)),
      map((url) => this.domSanitizer.bypassSecurityTrustUrl(url)),
    );
  }
}
