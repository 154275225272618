import { combineReducers } from "@ngrx/store";
import { activityReducer, ActivityState } from "./activity.reducer";
import { EventEventState, eventReducer } from "./event.reducer";
import { projectorReducer, ProjectorState } from "./projector.reducer";
import {
  randomizationReducer,
  RandomizationState,
} from "./randomization.reducer";
import { timerReducer, TimerState } from "./timer.reducer";
import { workspaceReducer, WorkspaceState } from "./workspace.reducer";
import { sessionLightReducer, SessionLightState } from "@app/store/event/reducers/sessionLight.reducer";

export const EVENT_STATE_ID = "event";

export interface EventState {
  event: EventEventState;
  activity: ActivityState;
  projector: ProjectorState;
  sessionLightReducer: SessionLightState;
  timer: TimerState;
  workspace: WorkspaceState;
  randomization: RandomizationState;
}

export const reducer = combineReducers<EventState>({
  event: eventReducer,
  activity: activityReducer,
  projector: projectorReducer,
  sessionLightReducer: sessionLightReducer,
  timer: timerReducer,
  workspace: workspaceReducer,
  randomization: randomizationReducer,
});
