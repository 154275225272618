import { EventTheme } from "@app/models/event-theme";
import { EventExpiredRequest, PageableEventsShortInfo } from "@app/models/event.models";
import { Event, EventSettingsDto } from "@app/store/event/model";
import { createAction, props, union } from "@ngrx/store";

const addEvent = createAction(
  "[Events] Add event",
  props<{ dto: EventSettingsDto }>()
);

const addEventSuccess = createAction(
  "[Events] Add event success",
  props<{ event: Event }>()
);

const changeEventSettings = createAction(
  "[Events] Change event settings",
  props<{ eventId: string; request: EventSettingsDto }>()
);

const changeEventSettingsSuccess = createAction(
  "[Events] Change event settings success",
  props<{ event: Event }>()
);

const changeEventTheme = createAction(
  "[Events] Change event theme",
  props<{ eventId: string; theme: Omit<EventTheme, "logo"> }>()
);

const changeEventThemeSuccess = createAction(
  "[Events] Change event theme success",
  props<{ event: Event }>()
);

const changeEventThemeLogo = createAction(
  "[Events] Change event theme logo",
  props<{ eventId: string; logo: File }>()
);

const changeEventThemeLogoSuccess = createAction(
  "[Events] Change event theme logo success",
  props<{ event: Event }>()
);

const deleteEventThemeLogo = createAction(
  "[Events] Delete event theme logo",
  props<{ eventId: string }>()
);

const deleteEventThemeLogoSuccess = createAction(
  "[Events] Delete event theme logo success",
  props<{ event: Event }>()
);

const duplicateEvent = createAction(
  "[Events] Duplicate Event",
  props<{ newEvent: Event }>()
);

const duplicateActivity = createAction(
  "[Events] Duplicate Activity",
  props<{ eventId: string; activityId: string; targetEventIds: string[] }>()
);

const saveInStoreDuplicateActivitySuccess = createAction(
  "[Events] Save in store duplicateActivitySuccess",
  props<{ duplicateActivitySuccess: boolean }>()
);

const setExpire = createAction(
  "[Events] Set Expire",
  props<{ event: Event; expireRequest: EventExpiredRequest }>()
);

const setExpireSuccess = createAction(
  "[Events] Set Expire success",
  props<{ event: Event; expireRequest: EventExpiredRequest }>()
);

const deleteEvent = createAction(
  "[Events] Delete event",
  props<{ event: Event }>()
);

const deleteEventSuccess = createAction(
  "[Events] Delete event success",
  props<{ event: Event }>()
);

const loadPageableEvents = createAction(
  "[Events] Load Pageable Events",
  props<{
    expired: boolean;
    pageNumber: number;
    pageSize: number;
  }>()
);

const loadPageableEventsSuccess = createAction(
  "[Events] Load Pageable Events success",
  props<{ info: PageableEventsShortInfo; expired: boolean }>()
);

const loadPageableSearchEvents = createAction(
  "[Events] Load Pageable search Events",
  props<{
    expired: boolean;
    pageNumber: number;
    pageSize: number;
    search: string;
    showEventsOnlyForCurrentModerator: boolean;
  }>()
);

const loadPageableSearchEventsSuccess = createAction(
  "[Events] Load Pageable search Events success",
  props<{ info: PageableEventsShortInfo }>()
);

const saveInStoreLoading = createAction(
  "[Events] Save in store Loading",
  props<{ loading: boolean }>()
);

const saveInStoreSearchEventsPageNumber = createAction(
  "[Events] Set search events page number",
  props<{ pageNumber: number }>()
);

const saveStateSearchParams = createAction(
  "[Events] Set search events search params",
  props<{
    isCurrent: boolean;
    amountOfEventsToLoad: number;
    searchValue: string;
    showEventsOnlyForCurrentModerator: boolean;
  }>()
);

export const EventsActions = {
  addEvent,
  addEventSuccess,
  changeEventSettings,
  changeEventSettingsSuccess,
  changeEventTheme,
  changeEventThemeSuccess,
  changeEventThemeLogo,
  changeEventThemeLogoSuccess,
  deleteEventThemeLogo,
  deleteEventThemeLogoSuccess,
  duplicateEvent,
  duplicateActivity,
  saveInStoreDuplicateActivitySuccess,
  setExpire,
  setExpireSuccess,
  deleteEvent,
  deleteEventSuccess,
  loadPageableEvents,
  loadPageableEventsSuccess,
  loadPageableSearchEvents,
  loadPageableSearchEventsSuccess,
  saveInStoreLoading,
  saveInStoreSearchEventsPageNumber,
  saveStateSearchParams,
};

const all = union(EventsActions);

export type EventsActionsTypes = typeof all;
