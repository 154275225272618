import { createReducer, on } from "@ngrx/store";
import { SecurityConfigSuccessActions } from "../actions";
import { EventSecurityConfig } from "../model";

export const AUTH_STATE_ID = "auth";

export interface AuthState {
  eventSecurityConfig: EventSecurityConfig | null;
}

export const initialState: AuthState = {
  eventSecurityConfig: null,
};

export const reducer = createReducer<AuthState>(
  initialState,

  on(
    SecurityConfigSuccessActions.load,
    (state, { securityConfig: eventSecurityConfig }): AuthState => ({
      ...state,
      eventSecurityConfig,
    }),
  ),
  on(
    SecurityConfigSuccessActions.setAllowOnlyOneAuthAtATime,
    (state, { allowOnlyOneAuthAtATime }): AuthState => ({
      ...state,
      eventSecurityConfig: {
        ...state.eventSecurityConfig,
        allowOnlyOneAuthAtATime,
      },
    }),
  ),
);
