import { HttpClient } from "@angular/common/http";
import { Injectable, ValueProvider } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class TermsTextService {
  constructor(private http: HttpClient) {}

  getAgreementToProcessingPersonalData(): Observable<string> {
    return this.http.get(
      "assets/politics/AgreementToProcessingPersonalData.html",
      {
        responseType: "text",
      },
    );
  }

  getPersonalDataPolicy(): Observable<string> {
    return this.http.get("assets/politics/PersonalDataPolicy.html", {
      responseType: "text",
    });
  }

  getUserAgreement(): Observable<string> {
    return this.http.get("assets/politics/UserAgreement.html", {
      responseType: "text",
    });
  }
}

const TermsTextServiceMock = {};

export const TermsTextServiceMockProvider: ValueProvider = {
  provide: TermsTextService,
  useValue: TermsTextServiceMock,
};
