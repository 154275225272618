import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ChooseRandomVariantModule } from "@shared/choose-random-variant/choose-random-variant.module";
import { ShowRandomizationDialogComponent } from "@shared/show-randomization/show-randomization-dialog.component";
import { DialogService } from "@tellsy/common/services/dialog.service";
import { ThemeModule } from "@tellsy/theme";

@NgModule({
  declarations: [ShowRandomizationDialogComponent],
  imports: [CommonModule, ThemeModule, ChooseRandomVariantModule],
  providers: [DialogService],
  exports: [ShowRandomizationDialogComponent],
})
export class ShowRandomizationDialogModule {}
