import {
  EventSuccessActions,
  WorkspaceActions,
  WorkspaceErrorActions,
  WorkspaceSuccessActions,
  WorkspaceWebsocketActions,
} from "@app/store/event/actions";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { EventDocument, EventSchedule } from "../model";

const adapter = createEntityAdapter<EventDocument>({
  selectId: (d) => d.id,
  sortComparer: (a, b) => a.orderIndex - b.orderIndex,
});

export interface WorkspaceState extends EntityState<EventDocument> {
  documentsProtected: boolean;
  documentsHidden: boolean;
  schedule: EventSchedule;
  loading: boolean;
}

const initialState: WorkspaceState = adapter.getInitialState({
  documentsProtected: null,
  documentsHidden: null,
  schedule: null,
  loading: null,
});

export const workspaceReducer = createReducer(
  initialState,

  on(EventSuccessActions.clearState, (): WorkspaceState => initialState),

  on(EventSuccessActions.loadEvent, (state, { dto }) => ({
    ...adapter.upsertMany(dto.workspace.documents, state),
    documentsProtected: dto.workspace.documentsProtected,
    documentsHidden: dto.workspace.documentsHidden,
    schedule: dto.workspace.schedule,
  })),

  on(WorkspaceSuccessActions.load, (state, { workspace }) => ({
    ...adapter.upsertMany(workspace.documents, state),
    documentsProtected: workspace.documentsProtected,
    documentsHidden: workspace.documentsHidden,
    schedule: workspace.schedule,
  })),

  on(
    WorkspaceActions.addDocument,
    (state): WorkspaceState => ({ ...state, loading: true }),
  ),
  on(
    WorkspaceErrorActions.addDocument,
    (state): WorkspaceState => ({ ...state, loading: false }),
  ),
  on(
    WorkspaceSuccessActions.addDocument,
    (state, { document }): WorkspaceState =>
      adapter.addOne(document, { ...state, loading: false }),
  ),
  on(
    WorkspaceSuccessActions.deleteDocument,
    (state, { documentId }): WorkspaceState =>
      adapter.removeOne(documentId, state),
  ),
  on(
    WorkspaceSuccessActions.renameDocument,
    (state, { documentId, fileName }): WorkspaceState =>
      adapter.updateOne(
        {
          id: documentId,
          changes: { fileName },
        },
        state,
      ),
  ),
  on(
    WorkspaceSuccessActions.replaceDocument,
    (state, { documentId, document }): WorkspaceState =>
      adapter.addOne(document, adapter.removeOne(documentId, state)),
  ),
  on(
    WorkspaceSuccessActions.setDocumentHidden,
    (state, { documentId, isHidden }): WorkspaceState =>
      adapter.updateOne(
        {
          id: documentId,
          changes: { isHidden },
        },
        state,
      ),
  ),
  on(
    WorkspaceSuccessActions.setAllDocumentsProtected,
    (state, { documentsProtected }): WorkspaceState => ({
      ...state,
      documentsProtected,
    }),
  ),
  on(
    WorkspaceSuccessActions.setAllDocumentsHidden,
    (state, { documentsHidden }): WorkspaceState => ({
      ...state,
      documentsHidden,
    }),
  ),
  on(
    WorkspaceSuccessActions.setDocumentsOrders,
    (state, { documentsIds, documentsOrders }): WorkspaceState =>
      adapter.map(
        (doc) => ({
          ...doc,
          orderIndex:
            documentsOrders[documentsIds.findIndex((el) => el === doc.id)],
        }),
        state,
      ),
  ),

  on(
    WorkspaceActions.setSchedule,
    (state): WorkspaceState => ({
      ...state,
      loading: true,
    }),
  ),

  on(
    WorkspaceErrorActions.setSchedule,
    (state): WorkspaceState => ({
      ...state,
      loading: false,
    }),
  ),

  on(
    WorkspaceSuccessActions.setSchedule,
    (state, { schedule }): WorkspaceState => ({
      ...state,
      schedule,
      loading: false,
    }),
  ),
  on(
    WorkspaceSuccessActions.deleteSchedule,
    (state): WorkspaceState => ({
      ...state,
      schedule: null,
    }),
  ),
  on(
    WorkspaceSuccessActions.setScheduleHidden,
    (state, { isHidden }): WorkspaceState => ({
      ...state,
      schedule: {
        ...state.schedule,
        isHidden,
      },
    }),
  ),

  // Websocket
  on(
    WorkspaceWebsocketActions.addDocument,
    (state, { document }): WorkspaceState => adapter.addOne(document, state),
  ),
  on(
    WorkspaceWebsocketActions.deleteDocument,
    (state, { documentId }): WorkspaceState =>
      adapter.removeOne(documentId, state),
  ),
  on(
    WorkspaceWebsocketActions.renameDocument,
    (state, { documentId, fileName }): WorkspaceState =>
      adapter.updateOne(
        {
          id: documentId,
          changes: { fileName },
        },
        state,
      ),
  ),
  on(
    WorkspaceWebsocketActions.replaceDocument,
    (state, { documentId, document }): WorkspaceState =>
      adapter.addOne(document, adapter.removeOne(documentId, state)),
  ),
  on(
    WorkspaceWebsocketActions.setDocumentHidden,
    (state, { documentId, isHidden }): WorkspaceState =>
      adapter.updateOne(
        {
          id: documentId,
          changes: { isHidden },
        },
        state,
      ),
  ),
  on(
    WorkspaceWebsocketActions.setAllDocumentsProtected,
    (state, { documentsProtected }): WorkspaceState => ({
      ...state,
      documentsProtected,
    }),
  ),
  on(
    WorkspaceWebsocketActions.setAllDocumentsHidden,
    (state, { documentsHidden }): WorkspaceState => ({
      ...state,
      documentsHidden,
    }),
  ),
  on(
    WorkspaceWebsocketActions.setDocumentsOrders,
    (state, { documentsIds, documentsOrders }): WorkspaceState =>
      adapter.map(
        (doc) => ({
          ...doc,
          orderIndex:
            documentsOrders[documentsIds.findIndex((el) => el === doc.id)],
        }),
        state,
      ),
  ),

  on(
    WorkspaceWebsocketActions.setSchedule,
    (state, { schedule }): WorkspaceState => ({
      ...state,
      schedule: schedule?.fileName ? schedule : null,
    }),
  ),
  on(
    WorkspaceWebsocketActions.deleteSchedule,
    (state): WorkspaceState => ({
      ...state,
      schedule: null,
    }),
  ),
  on(
    WorkspaceWebsocketActions.setScheduleHidden,
    (state, { isHidden }): WorkspaceState => ({
      ...state,
      schedule: {
        ...state.schedule,
        isHidden,
      },
    }),
  ),
);

export const {
  selectAll: selectAllDocuments,
  selectEntities: selectAllDocumentsEntities,
} = adapter.getSelectors();
