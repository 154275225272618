import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Component({
  selector: "tellsy-number-input",
  templateUrl: "./number-input.component.html",
  styleUrls: ["./number-input.component.scss"],
})
export class NumberInputComponent implements OnInit, ControlValueAccessor {
  @Input() min = -Infinity;
  @Input() max = Infinity;
  @Input() width = 50;
  @Input() height = 36;
  @Input() hideArrows = true;
  @Input() selectTextOnClick = true;
  @Input() labelPosition: "left" | "right" = "left";
  @Input() placeholder: string;
  @Input() initialValue: number;

  @Output() submitted = new EventEmitter<number>();
  @Output() changed = new EventEmitter<number>();
  @Output() focus = new EventEmitter<void>();
  @Output() blur = new EventEmitter<void>();

  @ViewChild("input") input: ElementRef<HTMLInputElement>;

  value: number;
  disabled: boolean;
  error: boolean;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.initialValue) {
      this.writeValue(this.initialValue)
    }
  }

  onInput(value: number | any): void {
    const val = parseInt(value, 10);
    this.error = val < this.min || val > this.max;
    this.writeValue(val);
  }

  onClick(el: HTMLInputElement | any): void {
    this.selectAll(el);
  }

  writeValue(value: number): void {
    if (value === this.value) {
      return;
    }

    if (isNaN(value)) {
      value = isFinite(this.min) ? this.min : 0;
    }

    this.value = value;
    this.onChange(value);
    this.changed.emit(value)

    if (this.input) {
      this.input.nativeElement.value = String(value);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  private selectAll(el: HTMLInputElement | any): void {
    return this.selectTextOnClick && el.select();
  }

  private onChange: (value: number) => void = () => {};

  private onTouched = () => {};
}
