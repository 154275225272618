import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AuthEffects, SecurityConfigEffects } from "./effects";
import { AUTH_STATE_ID, reducer } from "./reducers";
import { AuthService } from "./services/auth.service";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AUTH_STATE_ID, reducer),
    EffectsModule.forFeature([AuthEffects, SecurityConfigEffects]),
  ],
  providers: [AuthService],
})
export class AuthStoreModule {}
