import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { EVENT_STATE_ID, reducer } from "./reducers";
import {
  ActivityEffects,
  EventEffects,
  ProjectorEffects,
  TimerEffects,
  WorkspaceEffects,
} from "./effects";
import { EventService } from "./services/event.service";
import { EventStompService } from "@app/store/event/services/event-stomp.service";
import { EventStompFacade } from "@app/store/event/facades/event.stomp.facade";
import { SessionLightEffects } from "@app/store/event/effects/sessionLight.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(EVENT_STATE_ID, reducer),
    EffectsModule.forFeature([
      ActivityEffects,
      EventEffects,
      ProjectorEffects,
      TimerEffects,
      SessionLightEffects,
      WorkspaceEffects,
    ]),
  ],
  providers: [EventService, EventStompService, EventStompFacade],
})
export class EventStoreModule {}
