import { Injectable } from "@angular/core";
import { UIRole } from "@app/store/auth/model";
import { ServiceName } from "@tellsy/common/services/stomp.model";
import { StompService } from "@tellsy/common/services/stomp.service";
import {
  ArgumentMessage, ArgumentCountedMessage,
  ChangeActivityModeMessage,
  CommentAddedMessage,
  CommentUpdatedMessage, QuestionAISuggestionsChangedMessage,
  QuestionsUpdatesMessage,
  SetActiveSpeakerMessage,
  SetQuestionAnsweredMessage,
  SetQuestionApprovedMessage,
  SetQuestionFocusedMessage,
  SettingsChangedMessage,
  SpeakerAddedMessage,
  SpeakerDeletedMessage,
  SpeakerFocusedParticipantChangedMessage,
  SpeakerNameChangedMessage,
  SpeakerReadonlyChangedMessage,
  SpeakersOrderChangedMessage
} from "../models";

@Injectable({ providedIn: "root" })
export class AskQuestionStompService {
  private readonly ASK_QUESTION_APP_NAME: ServiceName = "ask-question-service";

  constructor(private stompService: StompService) {}

  // activity
  onChangeActivityMode$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<ChangeActivityModeMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: ChangeActivityModeMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onSettingsChanged$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SettingsChangedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: SettingsChangedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  // speakers
  onSpeakerAdded$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SpeakerAddedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: SpeakerAddedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onSpeakerDeleted$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SpeakerDeletedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: SpeakerDeletedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onArgumentDeleted$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<ArgumentMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: ArgumentMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onArgumentCounted$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<ArgumentCountedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: ArgumentCountedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onSetActiveSpeaker$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SetActiveSpeakerMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: SetActiveSpeakerMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onSpeakersOrderChange$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SpeakersOrderChangedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: SpeakersOrderChangedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onSpeakerNameChanged$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SpeakerNameChangedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: SpeakerNameChangedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onSpeakerReadonlyChanged$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SpeakerReadonlyChangedMessage>(
      {
        app: this.ASK_QUESTION_APP_NAME,
        messageType: SpeakerReadonlyChangedMessage.eventType,
        activityId,
        eventId,
        role,
      },
    );
  }

  onSpeakerFocusedParticipantChanged$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SpeakerFocusedParticipantChangedMessage>(
      {
        app: this.ASK_QUESTION_APP_NAME,
        messageType: SpeakerFocusedParticipantChangedMessage.eventType,
        activityId,
        eventId,
        role,
      },
    );
  }

  // questions
  onQuestionsUpdates$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<QuestionsUpdatesMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: QuestionsUpdatesMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onSetQuestionAnswered$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SetQuestionAnsweredMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: SetQuestionAnsweredMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onSetQuestionFocused$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SetQuestionFocusedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: SetQuestionFocusedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onSetQuestionApproved$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<SetQuestionApprovedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: SetQuestionApprovedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onCommentAdded$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<CommentAddedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: CommentAddedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onCommentUpdated$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<CommentUpdatedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: CommentUpdatedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onCommentDeleted$({
                      activityId,
                      eventId,
                      role,
                    }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<CommentUpdatedMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: CommentUpdatedMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onArgumentAdded$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<ArgumentMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: ArgumentMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onArgumentUpdated$({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<ArgumentMessage>({
      app: this.ASK_QUESTION_APP_NAME,
      messageType: ArgumentMessage.eventType,
      activityId,
      eventId,
      role,
    });
  }

  onQuestionAISuggestionUpdated({
    activityId,
    eventId,
    role,
  }: {
    activityId: string;
    eventId: string;
    role: UIRole;
  }) {
    return this.stompService.getActivityMessages$<QuestionAISuggestionsChangedMessage>(
      {
        app: this.ASK_QUESTION_APP_NAME,
        messageType: QuestionAISuggestionsChangedMessage.eventType,
        activityId,
        eventId,
        role,
      },
    );
  }
}
