import { Participant } from "@app/models/participant.model";
import { Activity } from "@app/store/event/model";
import { selectActivitiesEntities } from "@app/store/event/selectors";
import { TeamsState, TEAMS_STATE_ID } from "@app/store/teams/teams.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

const selectTeamsState = createFeatureSelector<TeamsState>(TEAMS_STATE_ID);

const selectAllTeamsUnsorted = createSelector(
  selectTeamsState,
  (state) => state?.teams,
);

const selectAllTeams = createSelector(selectAllTeamsUnsorted, (teams) =>
  teams?.length
    ? [...teams]?.sort((a, b) => {
        if (parseInt(a.teamName, 10) && parseInt(b.teamName, 10)) {
          return parseInt(a.teamName, 10) - parseInt(b.teamName, 10);
        }
        return a.teamName.localeCompare(b.teamName);
      })
    : null,
);

const selectTeam = createSelector(
  selectTeamsState,
  (state, props: { teamId: string }) =>
    state.teams?.find((team) => team.id === props.teamId),
);

const selectTeamsFromSelectedActivity = (activityId: string) =>
  createSelector(
    selectActivitiesEntities,
    selectAllTeams,
    (activitiesEntities, allTeams) => {
      if (!activitiesEntities || !activityId || !allTeams?.length) {
        return null;
      }

      const selectedActivity = activitiesEntities[activityId];

      return allTeams
        .filter((team) => selectedActivity?.teamIds?.includes(team.id))
        .sort((a, b) => a.teamName.localeCompare(b.teamName));
    },
  );

const selectTeamsFromActivity = (activity: Activity) =>
  createSelector(selectAllTeams, (teams) =>
    activity && teams?.length > 0
      ? teams
          .filter((team) => activity.teamIds.includes(team.id))
          .sort((a, b) => (a.teamName >= b.teamName ? 1 : -1))
      : null,
  );

const selectIsCaptain = (participant: Participant) =>
  createSelector(selectAllTeams, (teams) => {
    if (!teams) {
      return null;
    }

    if (participant) {
      return teams.some((team) => team.captainId === participant.participantId);
    }

    if (participant) {
      return teams.some(
        (team) =>
          team.id === participant.team?.id &&
          team.captainId === participant.participantId,
      );
    }

    return false;
  });

export const TeamsSelectors = {
  selectAllTeams,
  selectTeam,
  selectTeamsFromSelectedActivity,
  selectTeamsFromActivity,
  selectIsCaptain,
};
