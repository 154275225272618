import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

export type SelectedFileType = File | FileList;
@Directive({
  selector: "[tellsySelectFiles]",
})
export class SelectFileDirective implements OnInit, OnChanges {
  @Input() tellsySelectFiles: string;
  @Input() multiple = true;
  @Input() disabled = false;

  @Output() filesSelected: EventEmitter<SelectedFileType> = new EventEmitter();

  private input: HTMLInputElement;

  constructor(private renderer: Renderer2) {}

  @HostListener("click") onClick() {
    if (this.disabled) {
      return;
    }
    this.input.value = null;

    this.input.click();
  }

  ngOnInit(): void {
    this.input = this.renderer.createElement("input");
    this.input.type = "file";
    this.input.hidden = true;
    this.setAcceptAttribute(this.tellsySelectFiles);
    this.setMultipleAttribute(this.multiple);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.input) {
      return;
    }

    if (changes[`tellsySelectFiles`]) {
      this.setAcceptAttribute(this.tellsySelectFiles);
    }

    if (changes[`multiple`]) {
      this.setMultipleAttribute(this.multiple);
    }
  }

  private setAcceptAttribute(attributes: string): void {
    if (!this.input) {
      return;
    }
    this.input.accept = attributes;
  }

  private onFilesSelected(selected: SelectedFileType) {
    this.filesSelected.emit(selected);
  }

  private setMultipleAttribute(multiple: boolean): void {
    if (!this.input) {
      return;
    }
    if (multiple === true) {
      this.input.setAttribute("multiple", "");
      this.input.onchange = () => this.onFilesSelected(this.input.files);
    } else {
      this.input.removeAttribute("multiple");
      this.input.onchange = () => this.onFilesSelected(this.input.files[0]);
    }
  }
}
