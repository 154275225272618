import { Component, Input, OnInit } from "@angular/core";
import { LanguagesService } from "@tellsy/common/services/languages.service";

@Component({
  selector: "tellsy-language-button",
  templateUrl: "./language-button.component.html",
  styleUrls: ["./language-button.component.scss"],
})
export class LanguageButtonComponent implements OnInit {
  @Input() isForParticipant = false;
  @Input() theme: "default" | "outline" = "default";

  constructor(public languageService: LanguagesService) {}

  ngOnInit() {}

  public changeLanguage(): void {
    this.languageService.changeLanguage();
  }

  public get label(): string {
    return this.languageService.currentLanguageLabel;
  }
}
