import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import {
  EventSuccessActions,
  TimerActions,
  TimerWebsocketActions,
} from "../actions";
import { Timer, TimerDto } from "../model";

export interface TimerState extends EntityState<Timer> {}

const convertTimerDtoToTimer = (dto: TimerDto): Timer => ({
  ...dto,
  loadTimerTimestamp: new Date().getTime(),
});

const adapter = createEntityAdapter<Timer>({
  selectId: (a) => a.eventId,
});

export const timerReducer = createReducer<TimerState>(
  adapter.getInitialState([]),

  on(
    EventSuccessActions.clearState,
    (): TimerState => adapter.getInitialState([]),
  ),
  on(EventSuccessActions.loadEvent, (state, { dto }): TimerState => {
    const timers = [{ ...dto.timer, eventId: dto.id }];
    return adapter.setAll(
      timers?.map((timerDto) => convertTimerDtoToTimer(timerDto)),
      state,
    );
  }),
  on(
    TimerActions.loadSuccess,
    (state, { timers }): TimerState =>
      adapter.setAll(
        timers?.map((timerDto) => convertTimerDtoToTimer(timerDto)),
        state,
      ),
  ),
  on(
    TimerActions.startSuccess,
    (state, { timers }): TimerState =>
      adapter.setMany(
        timers?.map((timerDto) => convertTimerDtoToTimer(timerDto)),
        state,
      ),
  ),
  on(
    TimerActions.stopSuccess,
    (state, { timers }): TimerState =>
      adapter.setMany(
        timers?.map((timerDto) => convertTimerDtoToTimer(timerDto)),
        state,
      ),
  ),
  on(
    TimerActions.setCanBeNegativeSuccess,
    (state, { timers }): TimerState =>
      adapter.setMany(
        timers?.map((timerDto) => convertTimerDtoToTimer(timerDto)),
        state,
      ),
  ),

  // Timer ~ Websocket
  on(
    TimerWebsocketActions.start,
    (state, { eventId }): TimerState =>
      adapter.updateOne({ id: eventId, changes: { paused: false } }, state),
  ),
  on(
    TimerWebsocketActions.stop,
    (state, { eventId }): TimerState =>
      adapter.updateOne({ id: eventId, changes: { paused: true } }, state),
  ),
  on(
    TimerWebsocketActions.setCanBeNegative,
    (state, { eventId, canBeNegative }): TimerState =>
      adapter.updateOne({ id: eventId, changes: { canBeNegative } }, state),
  ),
);

export const timerSelectors = adapter.getSelectors();
