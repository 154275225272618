import { createActionGroup, emptyProps, props } from "@ngrx/store";
import {
  DocumentDto,
  DocumentIdDto,
  EventDocument,
  EventSchedule,
  RenameDocumentDto,
  ReplaceDocumentDto,
  SetAllDocumentsHiddenDto,
  SetAllDocumentsProtectedDto,
  SetDocumentHiddenDto,
  SetDocumentProtectedDto,
  SetDocumentsOrderDto,
  SetScheduleDto,
  SetScheduleIsHiddenDto,
  Workspace,
} from "../model";

export const WorkspaceActions = createActionGroup({
  source: "Workspace",
  events: {
    "Add Document": props<DocumentDto>(),
    "Delete Document": props<DocumentIdDto>(),
    "Rename Document": props<RenameDocumentDto>(),
    "Replace Document": props<ReplaceDocumentDto>(),
    "Set Document Protected": props<SetDocumentProtectedDto>(),
    "Set Document Hidden": props<SetDocumentHiddenDto>(),
    "Set All Documents Protected": props<SetAllDocumentsProtectedDto>(),
    "Set All Documents Hidden": props<SetAllDocumentsHiddenDto>(),
    "Set Documents Orders": props<SetDocumentsOrderDto>(),

    "Set Schedule": props<SetScheduleDto>(),
    "Delete Schedule": props<{ eventId: string }>(),
    "Set Schedule Hidden": props<SetScheduleIsHiddenDto>(),
  },
});

export const WorkspaceErrorActions = createActionGroup({
  source: "Workspace Error",
  events: {
    "Add Document": props<{ error: string }>(),
    "Set Schedule": props<{ error: string }>(),
  },
});

export const WorkspaceSuccessActions = createActionGroup({
  source: "Workspace Success",
  events: {
    "Load": props<{ workspace: Workspace }>(),

    "Add Document": props<{ document: EventDocument }>(),
    "Delete Document": props<DocumentIdDto>(),
    "Rename Document": props<RenameDocumentDto>(),
    "Replace Document": props<{
      documentId: string;
      document: EventDocument;
    }>(),
    "Set Document Protected": props<SetDocumentProtectedDto>(),
    "Set Document Hidden": props<SetDocumentHiddenDto>(),
    "Set All Documents Protected": props<SetAllDocumentsProtectedDto>(),
    "Set All Documents Hidden": props<SetAllDocumentsHiddenDto>(),
    "Set Documents Orders": props<SetDocumentsOrderDto>(),

    "Set Schedule": props<{ schedule: EventSchedule }>(),
    "Delete Schedule": emptyProps(),
    "Set Schedule Hidden": props<SetScheduleIsHiddenDto>(),
  },
});

export const WorkspaceWebsocketActions = createActionGroup({
  source: "Workspace ~ Websocket",
  events: {
    "Add Document": props<{ document: EventDocument }>(),
    "Delete Document": props<DocumentIdDto>(),
    "Rename Document": props<RenameDocumentDto>(),
    "Replace Document": props<{
      documentId: string;
      document: EventDocument;
    }>(),
    "Set Document Protected": props<SetDocumentProtectedDto>(),
    "Set Document Hidden": props<SetDocumentHiddenDto>(),
    "Set All Documents Protected": props<SetAllDocumentsProtectedDto>(),
    "Set All Documents Hidden": props<SetAllDocumentsHiddenDto>(),
    "Set Documents Orders": props<SetDocumentsOrderDto>(),

    "Set Schedule": props<{ schedule: EventSchedule }>(),
    "Delete Schedule": emptyProps(),
    "Set Schedule Hidden": props<SetScheduleIsHiddenDto>(),
  },
});
