<mat-dialog-content>
  <button class="close-button" mat-icon-button mat-dialog-close>
    <mat-icon class="close-icon">close</mat-icon>
  </button>

  <div class="content regular">
    <mat-icon class="info-icon">info</mat-icon>
    <p>{{ data.message }}</p>
  </div>
</mat-dialog-content>
