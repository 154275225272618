import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DirectivesModule } from "@tellsy/theme/directives/directives.module";
import { TextEditorComponent } from "@tellsy/theme/inputs/text-editor/text-editor.component";
import { MaterialModule } from "@tellsy/theme/material.module";
import { NgxImageCompressService } from "ngx-image-compress";
import { QuillModule } from "ngx-quill";

@NgModule({
  declarations: [TextEditorComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    QuillModule.forRoot({
      modules: {
        clipboard: {
          matchVisual: false,
        },
      },
    }),
    DirectivesModule,
  ],
  exports: [TextEditorComponent],
  providers: [NgxImageCompressService],
})
export class TextEditorModule {}
