import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { ThemePalette } from "@angular/material/core";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnInit {
  @Input() diameter = 50;
  @Input() height = "100%";
  @Input() loadingText: string;
  @Input() color: ThemePalette = "primary";
  @Input() embedded = false;

  @HostBinding("class.embedded") get embeddedClass() {
    return this.embedded;
  }

  constructor() {}

  ngOnInit() {}
}
