import { Role } from "@app/store/auth/model";
import { Action } from "@ngrx/store";

export enum LogoutActionTypes {
  sendLogoutRequest = "[LOGOUT] Send Logout Request",
  logoutSuccess = "[LOGOUT] Logout Success",
}

export class SendLogoutRequest implements Action {
  readonly type = LogoutActionTypes.sendLogoutRequest;

  constructor(
    public payload: {
      removeCredentials: boolean;
      role: Role;
      eventCode: string;
    },
  ) {}
}

export class LogoutSuccess implements Action {
  readonly type = LogoutActionTypes.logoutSuccess;

  constructor() {}
}

export type LogoutTypes = SendLogoutRequest | LogoutSuccess;
