import { Team } from "@app/models/participant.model";
import { createReducer, on } from "@ngrx/store";
import { TeamsActions, TeamsActionTypes } from "./teams.actions";

export const TEAMS_STATE_ID = "teams";

export interface TeamsState {
  teams: Team[];
}

const initialState: TeamsState = {
  teams: null,
};

const reducer = createReducer<TeamsState>(
  initialState,

  on(
    TeamsActions.setTeams,
    (state, { teams, safeLink }): TeamsState => ({
      ...state,
      teams: safeLink ? teams : [...teams],
    }),
  ),

  on(TeamsActions.setTeam, (state, { team }): TeamsState => {
    if (state.teams.some((t) => t.id === team.id)) {
      return {
        ...state,
        teams: state.teams.map((t) =>
          t.id === team.id
            ? {
                teamName: team.teamName,
                captainId: team.captainId,
                captainIdentifier: team.captainIdentifier,
              }
            : t,
        ),
      };
    }

    return {
      ...state,
      teams: [...state.teams, team],
    };
  }),

  on(
    TeamsActions.changeTeamCaptain,
    (state, { teamId, captainId, captainIdentifier }): TeamsState => ({
      ...state,
      teams: [
        ...state.teams.map((t) =>
          t.id === teamId
            ? {
                captainId,
                captainIdentifier,
              }
            : t,
        ),
      ],
    }),
  ),
);

export const teamsReducer = (
  state: TeamsState | undefined,
  action: TeamsActionTypes,
) => reducer(state, action);
