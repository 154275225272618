import { Injectable } from "@angular/core";
import { EventFacade } from "@app/store/event/facades/event.facade";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap, tap } from "rxjs/operators";
import { ActivityActions, ActivitySuccessActions } from "../actions";
import { EventService } from "../services/event.service";
import {AskQuestionService} from "@app/store/ask-question/services";

@Injectable()
export class ActivityEffects {
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.create),
      switchMap(({ eventId, activityType, previousActivityId }) =>
        this.eventService.createActivity({
          eventId,
          activityType,
          previousActivityId,
        }),
      ),
      map((activity) => ActivitySuccessActions.create({ activity })),
    ),
  );
  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.delete),
      switchMap(({ eventId, activityId }) =>
        this.eventService.deleteActivity({ eventId, activityId }),
      ),
      map(({ eventId, activityId }) =>
        ActivitySuccessActions.delete({ eventId, activityId }),
      ),
    ),
  );

  deleteMulti$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.deleteMulti),
      switchMap(({eventId, activityId}) =>
        this.eventService.deleteMultiActivity({eventId, activityId}),
      ),
      map(({eventId, activityId}) =>
        ActivitySuccessActions.deleteMulti({eventId, activityId}),
     ),
    ),
  );

  duplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.duplicate),
      switchMap(({ eventId, activityId, eventIds }) =>
        this.eventService.duplicateAcivity({ eventId, activityId, eventIds }),
      ),
      map((activities) => ActivitySuccessActions.duplicate({ activities })),
    ),
  );
  rename$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.rename),
      switchMap((action) => this.eventService.renameActivity(action)),
      map((action) => ActivitySuccessActions.rename(action)),
    ),
  );

    updateAi$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivityActions.update),
            switchMap(({ activityId, speakerId, aiData, }) => this.askQuestionService.updateAi(activityId, speakerId, {aiData})),
            map(({ activityId, speakerId, }) => ActivitySuccessActions.update({activityId, speakerId,})),
        ),
    );

  setOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.setOrder),
      switchMap((action) => this.eventService.setActivityOrder(action)),
      map((action) => ActivitySuccessActions.setOrder(action)),
    ),
  );
  activate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.activate),
      switchMap(({ eventId, activityId }) =>
        this.eventService.activateActivity({ eventId, activityId }),
      ),
      map(({ eventId, activityId }) =>
        ActivitySuccessActions.activate({ eventId, activityId }),
      ),
    ),
  );
  deactivate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.deactivate),
      switchMap(({ eventId }) => this.eventService.deactivateActivity(eventId)),
      map(() => ActivitySuccessActions.deactivate()),
    ),
  );

  activateNext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.activateNext),
      switchMap(({ eventId }) =>
        this.eventService
          .activateNextActivity(eventId)
          .pipe(
            map(({ activity }) =>
              ActivitySuccessActions.activateNext({ eventId, activity }),
            ),
          ),
      ),
    ),
  );

  activateNextSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivitySuccessActions.activateNext),
        tap(({ eventId, activity }) => {
          this.eventFacade.navigateToEventActivity({
            role: "moderator",
            eventId,
            activityData: {
              activityType: activity.type,
              activityId: activity.activityId,
            },
          });
        }),
      ),
    { dispatch: false },
  );

  activatePrev$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.activatePrev),
      switchMap(({ eventId }) =>
        this.eventService
          .activatePrevActivity(eventId)
          .pipe(
            map(({ activity }) =>
              ActivitySuccessActions.activatePrev({ eventId, activity }),
            ),
          ),
      ),
    ),
  );

  activatePrevSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActivitySuccessActions.activatePrev),
        tap(({ eventId, activity }) => {
          this.eventFacade.navigateToEventActivity({
            role: "moderator",
            eventId,
            activityData: {
              activityType: activity.type,
              activityId: activity.activityId,
            },
          });
        }),
      ),
    { dispatch: false },
  );

  setTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.setTeams),
      switchMap(({ eventId, activityId, teamIds }) =>
        this.eventService.setTeamsForActivity({ eventId, activityId, teamIds }),
      ),
      map(({ eventId, activityId, teamIds }) =>
        ActivitySuccessActions.setTeams({ eventId, activityId, teamIds }),
      ),
    ),
  );
  activateForTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.activateForTeams),
      switchMap((action) =>
        this.eventService.activateActivitiesForTeams(action),
      ),
      map(({ eventId, teamActivities }) =>
        ActivitySuccessActions.activateForTeams({ eventId, teamActivities }),
      ),
    ),
  );
  deactivateForTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActivityActions.deactivateForTeams),
      switchMap(({ eventId, teamActivities }) =>
        this.eventService.deactivateActivitiesForTeams({
          eventId,
          teamActivities,
        }),
      ),
      map(({ eventId, teamActivities }) =>
        ActivitySuccessActions.deactivateForTeams({ eventId, teamActivities }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private eventService: EventService,
    private askQuestionService: AskQuestionService,
    private eventFacade: EventFacade,
  ) {}
}
