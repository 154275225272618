<button
  mat-stroked-button
  [class.admin]="!isForParticipant"
  [class.participant]="isForParticipant"
  [class.default]="theme === 'default'"
  [class.outline]="theme === 'outline'"
  (click)="changeLanguage()"
>
  {{ label }}
</button>
