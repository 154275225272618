import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DateFormatPipe } from "@tellsy/common/pipes/date-format.pipe";
import { PluralTranslatePipe } from "@tellsy/common/pipes/plural-translate.pipe";

@NgModule({
  declarations: [DateFormatPipe, PluralTranslatePipe],
  exports: [DateFormatPipe, PluralTranslatePipe],
  imports: [CommonModule],
})
export class TellsyCommonModule {}
