export interface User {
  principal: {
    id: string;
  };
  authenticated: boolean;
  name: string;
  authorities: Authority[];
  moderatorName?: string;
  eventId?: string;
}

export interface Authority {
  authority: UserRole;
}

export enum UserRole {
  "ROLE_PARTICIPANT" = "ROLE_PARTICIPANT",
  "ROLE_MODERATOR" = "ROLE_MODERATOR",
  "ROLE_MODERATORPRO" = "ROLE_MODERATORPRO",
  "ROLE_ADMIN" = "ROLE_ADMIN",
  "ROLE_SUPERADMIN" = "ROLE_SUPERADMIN",
}

export type Role = "participant" | "moderator";

export type UIRole = Role | "projector";
