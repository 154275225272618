<div class="component-wrapper">
  <div class="circle-wrapper top-right-corner">
    <div class="circle"></div>
  </div>
  <div
    class="wrapper"
    fxLayout="column"
    fxLayoutAlign="center"
    fxLayoutGap.gt-xs="10px"
  >
    <form
      [class.disabled]="!goodLink"
      class="top-container"
      fxFlex.gt-xs="50%"
      fxFlex.xs="70%"
      fxLayout="column"
      fxLayoutAlign="end center"
      (ngSubmit)="setPassword()"
      [formGroup]="form"
    >
      <ng-container *ngrxLet="login$ as login">
        <div class="language-button-wrapper" fxLayoutAlign="end">
          <tellsy-language-button></tellsy-language-button>
        </div>

        <div
          class="logo-wrapper"
          fxFlex.xs="20%"
          fxFlex.gt-xs="30%"
          fxLayoutAlign="center center"
        >
          <img
            class="logo"
            src="/assets/img/tellsy logo white.svg"
            alt="tellsy.ru"
          />
        </div>

        <p class="header" *ngIf="!!login">
          {{ "shared.setPasswordHeader" | translate: { regName: login } }}
        </p>
        <p class="header" *ngIf="!login">
          {{ "shared.alreadyUsed" | translate }}
        </p>

        <app-auth-input
          type="text"
          [value]="login"
          [dark]="false"
          [bold]="true"
          [spellcheck]="false"
          [hidden]="true"
        >
        </app-auth-input>
        <mat-form-field>
          <input
            type="password"
            matInput
            [placeholder]="
              'auth.moderator.input.password.placeholder' | translate
            "
            formControlName="password"
            required
            autocomplete="new-password"
          />
          <mat-error *ngIf="form.hasError('required', 'password')">
            {{ "shared.enterNewPassword" | translate }} </mat-error
          ><mat-error *ngIf="form.hasError('minlength', 'password')">
            {{ "shared.tooShort" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input
            type="password"
            matInput
            [placeholder]="'shared.confirmPassword' | translate"
            formControlName="confirmPassword"
            required
            autocomplete="new-password"
          />
          <mat-error *ngIf="form.hasError('notSame', 'confirmPassword')">
            {{ "shared.notSame" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-error *ngIf="errorMessage">
          {{ errorMessage }}
        </mat-error>

        <tellsy-button
          class="set-password-btn"
          type="submit"
          [width]="265"
          [large]="false"
          [loading]="pending"
          [disabled]="form.invalid || !goodLink"
        >
          {{ "moderator.demo.enter" | translate }}
        </tellsy-button>
      </ng-container>
    </form>
  </div>
  <div class="circle-wrapper bottom-left-corner">
    <div class="circle"></div>
  </div>
</div>
