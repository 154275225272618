import { ParticipantMessageType } from "@app/models/participant.model";
import { AskQuestionMessageType } from "@app/store/ask-question/models/ask-question-stomp.model";
import { BrainstormMessageType } from "@app/store/brainstorm/models/brainstorm-stomp.model";
import { ClassificationMessageType } from "@app/store/classification/models";
import { EventMessageType } from "@app/store/event/model";
import { RoleGameMessageType } from "@app/store/role-game/models/role-game-models";
import { SurveyMessageType } from "@app/store/survey/models/survey.model";
import { TagCloudMessageType } from "@app/store/tagCloud/model/tag-cloud-stomp.model";
import { StubMessageType } from "@app/store/stub/models";
import { FillVacanciesMessageType } from "@app/store/fill-vacancy/model";
import { QuestionArgument } from "@app/store/ask-question/models";

export class Message {
  type: MessageType;
  app: ServiceName;
}

export class ActivityMessage extends Message {
  activityId: string;
}

export class ParticipantMessage extends Message {
  participantId: string;
  deleted?: boolean;
  argument?: QuestionArgument
}

export type MessageType =
  | EventMessageType
  | SurveyMessageType
  | ParticipantMessageType
  | AskQuestionMessageType
  | TagCloudMessageType
  | BrainstormMessageType
  | RoleGameMessageType
  | ClassificationMessageType
  | StubMessageType
  | FillVacanciesMessageType;

export type ServiceName =
  | "event-service"
  | "participant-service"
  | "survey-service"
  | "ask-question-service"
  | "tag-cloud-service"
  | "brainstorm-service"
  | "role-play-game"
  | "mini-activities-service"
  | "auth-service"
  | "classification-service"
  | "fill-vacancies-service";
