<ng-container *ngIf="label && label.length > 0">
  <span class="label bold">
    {{ label }}
  </span>
</ng-container>

<quill-editor
  class="quill"
  tellsyDropzone
  [disabled]="disabled"
  [style.height.px]="height"
  [class.focus]="focused"
  [sanitize]="false"
  [placeholder]="placeholder"
  [ngModel]="value"
  (ngModelChange)="writeValue($event)"
  (onEditorCreated)="onEditorCreated($event)"
  (onBlur)="onFocusChange(false)"
  (onFocus)="onFocusChange(true)"
  (dropped)="onImageFilesDropped($event)"
>
  <div quill-editor-toolbar fxLayout="row" fxLayoutAlign="space-between center">
    <div
      *ngIf="config.bold || config.italic || config.underline || config.strike"
      fxLayout="row"
    >
      <button
        *ngIf="config.bold"
        class="ql-bold"
        type="button"
        matTooltip="Полужирный"
        matTooltipPosition="above"
      ></button>
      <button
        *ngIf="config.italic"
        class="ql-italic"
        type="button"
        matTooltip="Курсив"
        matTooltipPosition="above"
      ></button>
      <button
        *ngIf="config.underline"
        class="ql-underline"
        type="button"
        matTooltip="Подчеркивание"
        matTooltipPosition="above"
      ></button>
      <button
        *ngIf="config.strike"
        class="ql-strike"
        type="button"
        matTooltip="Зачеркнутый"
        matTooltipPosition="above"
      ></button>
    </div>
    <div *ngIf="config.size" fxLayout="row">
      <select class="ql-size" (change)="changeFontSize($event.target.value)">
        <option *ngFor="let value of config?.size" [value]="value" [selected]="value === '16px'">{{ value}}</option>
      </select>
    </div>
    <div *ngIf="config.color || config.backgroundColor" fxLayout="row">
      <div matTooltip="Цвет текста" matTooltipPosition="above">
        <select class="ql-color" *ngIf="config.color">
          <option
            *ngFor="let value of config?.backgroundColorList"
            [value]="value"
          ></option>
        </select>
      </div>
      <div matTooltip="Цвет заливки  текста" matTooltipPosition="above">
        <select class="ql-background" *ngIf="config.backgroundColor">
          <option
            *ngFor="let value of config?.backgroundColorList"
            [value]="value"
          ></option>
        </select>
      </div>
    </div>
    <div
      *ngIf="
        config.blockquote ||
        config.bulletList ||
        config.orderedList ||
        config.indent
      "
      fxLayout="row"
    >
      <button
        class="ql-blockquote"
        type="button"
        matTooltip="Цитата"
        matTooltipPosition="above"
      ></button>

      <button
        *ngIf="config.bulletList"
        class="ql-list"
        type="button"
        matTooltip="Маркированный список"
        matTooltipPosition="above"
        value="bullet"
      ></button>

      <button
        *ngIf="config.orderedList"
        class="ql-list"
        type="button"
        matTooltip="Нумерованный список"
        matTooltipPosition="above"
        value="ordered"
      ></button>
      <ng-container *ngIf="config.indent">
        <button
          class="ql-indent"
          type="button"
          matTooltip="Увеличить отступ"
          matTooltipPosition="above"
          value="+1"
        ></button>
        <button
          class="ql-indent"
          type="button"
          matTooltip="Уменьшить отступ"
          matTooltipPosition="above"
          value="-1"
        ></button>
      </ng-container>
    </div>
    <div *ngIf="config.align" fxLayout="row">
      <button
        class="ql-align"
        type="button"
        matTooltip="Выравнивание по левому краю"
        matTooltipPosition="above"
        value=""
      ></button>
      <button
        class="ql-align"
        type="button"
        matTooltip="Выравнивание по центру"
        matTooltipPosition="above"
        value="center"
      ></button>
      <button
        class="ql-align"
        type="button"
        matTooltip="Выравнивание по правому краю"
        matTooltipPosition="above"
        value="right"
      ></button>
      <button
        class="ql-align"
        type="button"
        matTooltip="Выравнивание по ширине"
        matTooltipPosition="above"
        value="justify"
      ></button>
    </div>
    <div *ngIf="config.link || config.video || config.image" fxLayout="row">
      <!--link-->
      <button
        *ngIf="config.link"
        class="toolbar-btn ql-link"
        type="button"
        mat-icon-button
        matTooltip="Вставить ссылку"
        matTooltipPosition="above"
      >
        <mat-icon svgIcon="link"></mat-icon>
      </button>

      <!--image-->
      <button
        *ngIf="config.image"
        class="toolbar-btn"
        type="button"
        mat-icon-button
        matTooltip="Вставить картинку"
        matTooltipPosition="above"
        tellsySelectFiles="image/*"
        [multiple]="true"
        (filesSelected)="onImageFilesSelected($event)"
      >
        <mat-icon svgIcon="image" class=""></mat-icon>
      </button>

      <!--video-->
      <button
        *ngIf="config.video"
        class="toolbar-btn"
        type="button"
        mat-icon-button
        matTooltip="Вставить видео"
        matTooltipPosition="above"
        (click)="onAddVideoClick()"
      >
        <mat-icon svgIcon="video"></mat-icon>
      </button>
    </div>
  </div>
</quill-editor>
