import { Injectable } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DialogService } from "@tellsy/common/services/dialog.service";
import { NotificationsService, NotificationType } from "@tellsy/notifications";
import { MessageDialogComponent } from "@tellsy/theme/dialogs/message-dialog/message-dialog.component";

@Injectable({
  providedIn: "root",
})
export class NotificatorService {
  private dialogRef: MatDialogRef<any>;
  constructor(
    private dialogService: DialogService,
    private notifications: NotificationsService,
  ) {}

  showMessage(message: string) {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.dialogService.openComponentDialog(
      MessageDialogComponent,
      {
        minWidth: "300px",
        data: { message },
      },
    );
  }

  // TODO: add options instead of parameters
  showToastMessage(
    title: string,
    messageContent?: string,
    panelClass?: NotificationType,
    msToShow?: number,
  ) {
    const notificationOptions = {
      timeOut: msToShow ?? 3000,
      showProgressBar: true,
      theClass: "notification",
    };
    this.notifications.create(
      title,
      messageContent,
      panelClass,
      notificationOptions,
    );
  }
}
