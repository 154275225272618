export interface Webinar {
  urlChat: string;
  urlBroadcast: string;
  mode: WebinarMode;
  active: boolean;
  isSixteenToNineRatio: boolean;
  playerSettings: WebinarPlayerSettings;
  viewOption: WebinarViewOption;
}

export enum WebinarMode {
  IFRAME = "IFRAME",
  PLAYER = "PLAYER",
}

export interface WebinarPlayerSettings {
  autoPlay: boolean;
}

export enum WebinarViewOption {
  BIGLEFT = "BIGLEFT",
  BIGRIGHT = "BIGRIGHT",
}
