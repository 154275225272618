import { Params, RouterStateSnapshot } from "@angular/router";
import { ActivityType } from "@app/store/event/model";
import { RouterStateSerializer } from "@ngrx/router-store";

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  activityType: ActivityType;
}

export class CustomRouterSerializer
  implements RouterStateSerializer<RouterStateUrl>
{
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;
    let params: any = {};
    let queryParams = {};
    let activityType: ActivityType;
    while (route.firstChild) {
      route = route.firstChild;
      params = Object.assign({}, params, route.params);
      queryParams = Object.assign({}, queryParams, route.queryParams);
      if (params.activityId && !activityType) {
        activityType = route.url[0].path.toUpperCase() as ActivityType;
      }
    }
    const url = routerState.url;
    return { url, params, queryParams, activityType };
  }
}
