import { createReducer, on } from "@ngrx/store";
import { ParticipantAuthState } from "../auth.models";
import { ParticipantAuthActions, ParticipantAuthActionsTypes } from "./participant-auth.actions";

export const initialState: ParticipantAuthState = {
  eventCodeErrorType: null,
  usernameFieldName: null,
  usernameErrorType: null,
  eventCode: null,
  eventName: null,
  subEventId: null,
  username: null,
  pending: false,
  navigatedByLink: false,
  navigatedByLinkWithLogin: false
};

export function participantAuthReducer(
  state: ParticipantAuthState | undefined,
  action: ParticipantAuthActionsTypes
) {
  return reducer(state, action);
}

const reducer = createReducer<ParticipantAuthState>(
  initialState,

  on(
    ParticipantAuthActions.saveInStoreUsernameErrorType,
    (state, action): ParticipantAuthState => ({
      ...state,
      usernameErrorType: action.errorType
    })
  ),

  on(
    ParticipantAuthActions.saveInStoreEventCodeErrorType,
    (state, action): ParticipantAuthState => ({
      ...state,
      eventCodeErrorType: action.errorType
    })
  ),

  on(
    ParticipantAuthActions.saveInStoreUsernameFieldName,
    (state, action): ParticipantAuthState => ({
      ...state,
      usernameFieldName: action.fieldName
    })
  ),

  on(
    ParticipantAuthActions.saveInStorePending,
    (state, action): ParticipantAuthState => ({
      ...state,
      pending: action.pending
    })
  ),

  on(
    ParticipantAuthActions.saveInStoreFieldValues,
    (state, action): ParticipantAuthState => ({
      ...state,
      eventCode: action.eventCode ?? state.eventCode,
      username: action.username ?? state.username,
      subEventId: action.subEventId ?? state.subEventId,
      navigatedByLink: action.navigatedByLink ?? state.navigatedByLink,
      navigatedByLinkWithLogin:
        action.navigatedByLinkWithLogin ?? state.navigatedByLinkWithLogin
    })
  ),

  on(
    ParticipantAuthActions.saveInStoreEventName,
    (state, action): ParticipantAuthState => ({
      ...state,
      eventName: action.eventName ?? state.eventName
    })
  )
);
