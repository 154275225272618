export class Pageable {
  page: number;
  size: number;
  sortParam: string;
  sortOrder: "asc" | "desc";

  constructor(options?: Partial<Pageable>) {
    this.page = options?.page || 0;
    this.size = options?.size || 20;
    this.sortParam = options?.sortParam || "id";
    this.sortOrder = options?.sortOrder || "asc";
  }

  static changeOrder(pageable: Pageable): Pageable {
    pageable.sortOrder = pageable.sortOrder === "asc" ? "desc" : "asc";
    return pageable;
  }
}

interface Sort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface PageableResponse<T> {
  content: T[];
  pageable: {
    sort: Sort;
    pageNumber: number;
    pageSize: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  first: boolean;
  last: boolean;
  totalElements: number;
  totalPages: number;
  sort: Sort;
  // eslint-disable-next-line id-blacklist
  number: number;
  numberOfElements: number;
  size: number;
  empty: boolean;
}

export class PageableQuery {
  static create(pageable?: Partial<Pageable>): Record<string, string | number> {
    const { page, size, sortParam, sortOrder } = new Pageable(pageable);
    return {
      page,
      size,
      sort: sortParam + "," + sortOrder,
    };
  }

  static nextPage(
    pageable?: Partial<Pageable>,
    lastPageNumber = null as number,
  ): Record<string, string | number> {
    const { page, size, sortParam, sortOrder } = new Pageable(pageable);
    return {
      page:
        lastPageNumber !== null
          ? page < lastPageNumber
            ? page + 1
            : lastPageNumber
          : page + 1,
      size,
      sort: sortParam + "," + sortOrder,
    };
  }

  static prevPage(
    pageable?: Partial<Pageable>,
  ): Record<string, string | number> {
    const { page, size, sortParam, sortOrder } = new Pageable(pageable);
    return {
      page: page > 0 ? page - 1 : 0,
      size,
      sort: sortParam + "," + sortOrder,
    };
  }

  static changeOrder(pageable: Pageable): Record<string, string | number> {
    const { page, size, sortParam, sortOrder } = pageable;
    return {
      page,
      size,
      sort: sortParam + "," + (sortOrder === "asc" ? "desc" : "asc"),
    };
  }
}
