import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import { SelectOption } from "@tellsy/theme/select/select/select.component";

export interface SelectDialogData<T extends string | number = string> {
  title: string;
  options: SelectOption<T>[];
  nullOptionLabel: string;
  selectedValue: any;
  btnText: string;
}

@Component({
  selector: "app-select-dialog",
  templateUrl: "./select-dialog.component.html",
  styleUrls: ["./select-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SelectDialogData,
    private componentMatDialogRef: MatDialogRef<SelectDialogComponent>,
  ) {}

  ngOnInit() {}

  close() {
    this.componentMatDialogRef.close(this.data.selectedValue);
  }
}
