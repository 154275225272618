import { createAction, props, union } from "@ngrx/store";

const setUser = createAction(
  "[User] Set User",
  props<{ role: string; id: string; name: string }>(),
);

export const UserActions = {
  setUser,
};

const all = union(UserActions);

export type UserActionsTypes = typeof all;
