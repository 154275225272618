import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import { NotificatorService } from "@tellsy/common/services/notificator.service";
import { NotificationType } from "@tellsy/notifications";
import { map, switchMap, tap } from "rxjs";
import { TimerActions } from "../actions";
import { EventService } from "../services/event.service";

@Injectable()
export class TimerEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimerActions.load),
      switchMap(({ eventId }) => this.eventService.loadTimer(eventId)),
      map((timers) => TimerActions.loadSuccess({ timers })),
    ),
  );

  start$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimerActions.start),
      switchMap(({ eventIds, seconds }) =>
        this.eventService.startTimer(eventIds, seconds),
      ),
      map((timers) => TimerActions.startSuccess({ timers })),
      tap(({ timers }) => {
        if (timers.length > 1) {
          this.notificatorService.showToastMessage(
            "",
            this.translate.instant(
              "moderator.event.topPanel.timer.bulkActions.success",
            ),
            NotificationType.Success,
          );
        }
      }),
    ),
  );

  stop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimerActions.stop),
      switchMap(({ eventIds }) => this.eventService.stopTimer(eventIds)),

      map((timers) => TimerActions.stopSuccess({ timers })),
      tap(({ timers }) => {
        if (timers.length > 1) {
          this.notificatorService.showToastMessage(
            "",
            this.translate.instant(
              "moderator.event.topPanel.timer.bulkActions.success",
            ),
            NotificationType.Success,
          );
        }
      }),
    ),
  );

  setCanBeNegative$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimerActions.setCanBeNegative),
      switchMap(({ eventIds, canBeNegative }) =>
        this.eventService.setTimerCanBeNegative(eventIds, canBeNegative),
      ),
      map((timers) => TimerActions.setCanBeNegativeSuccess({ timers })),
      tap(({ timers }) => {
        if (timers.length > 1) {
          this.notificatorService.showToastMessage(
            "",
            this.translate.instant(
              "moderator.event.topPanel.timer.bulkActions.success",
            ),
            NotificationType.Success,
          );
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private eventService: EventService,
    private notificatorService: NotificatorService,
    private translate: TranslateService,
  ) {}
}
