export class Activity {
  activityId: string;
  previousActivityId: string;
  active = false;
  type: ActivityType;
  name: string;
  order: number;
  teamActivity = false;
  teamIds: string[] = [];
  activeForTeams: string[] = [];
}

export enum ActivityType {
  STUB = "STUB",
  ROLE_GAME = "ROLE_GAME",
  ASK_QUESTION = "ASK_QUESTION",
  TAG_CLOUD = "TAG_CLOUD",
  FILL_VACANCIES = "FILL_VACANCIES",
  SURVEY = "SURVEY",
  TEST = "TEST",
  QUIZ = "QUIZ",
  CHOOSE_CAPTAIN = "CHOOSE_CAPTAIN",
  GATHER_OPINIONS = "GATHER_OPINIONS",
  DINNER = "DINNER",
  WHATSAPP = "WHATSAPP",
  WELCOME = "WELCOME",
  INTERMISSION = "INTERMISSION",
  CHAT = "CHAT",
  CUSTOM_HTML = "CUSTOM_HTML",
  BRAINSTORM = "BRAINSTORM",
  CLASSIFICATION = "CLASSIFICATION",
}

export const ACTIVTY_GROUP = {
  COLLECT_CONTENT: [
    ActivityType.SURVEY,
    ActivityType.ASK_QUESTION,
    ActivityType.GATHER_OPINIONS,
    ActivityType.CHAT,
    ActivityType.TAG_CLOUD,
  ],
  FACILITATING: [
    ActivityType.FILL_VACANCIES,
    ActivityType.STUB,
    ActivityType.WHATSAPP,
    ActivityType.CHOOSE_CAPTAIN,
    ActivityType.CLASSIFICATION,
    ActivityType.TEST,
  ],
  STUB: [
    ActivityType.WELCOME,
    ActivityType.INTERMISSION,
    ActivityType.DINNER,
    ActivityType.CUSTOM_HTML,
  ],
  GAME: [ActivityType.ROLE_GAME, ActivityType.BRAINSTORM, ActivityType.QUIZ],
};
