import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DirectivesModule } from "@tellsy/theme/directives/directives.module";
import { FileDropzoneComponent } from "@tellsy/theme/file-dropzone/file-dropzone.component";

@NgModule({
  declarations: [FileDropzoneComponent],
  exports: [FileDropzoneComponent],
  imports: [CommonModule, DirectivesModule],
})
export class FileDropzoneModule {}
